import { useCallback } from 'react';
import { useSetAtom } from 'jotai';

import defaultValue from './defaultValue';
import favoriteModalStateAtom from './favoriteModalStateAtom';

const useResetFavoriteModalState = () => {
  const setFavoriteModalState = useSetAtom(favoriteModalStateAtom);

  const resetFavoriteModalState = useCallback(() => {
    setFavoriteModalState(defaultValue);
  }, [setFavoriteModalState]);

  return resetFavoriteModalState;
};

export default useResetFavoriteModalState;
