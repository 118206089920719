import { useSetAtom } from 'jotai';

import userLocationLoadingAtom from './userLocationLoadingAtom';

const useSetUserLocationLoading = () => {
  const setUserLocationLoading = useSetAtom(userLocationLoadingAtom);

  return setUserLocationLoading;
};

export default useSetUserLocationLoading;
