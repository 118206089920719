import React from 'react';
import PropTypes from 'prop-types';

import JBImage from 'components/essentials/JBImage';
import Link from 'components/consumer/Link';

const SubcategoryCard = ({ image, label, to }) => (
  <Link
    className="bg-gray-light7 justify-between items-center rounded-sm flex p-2 pl-4 no-underline border-gray-light7 border-solid border-2 transition-[border] duration-200 ease-[ease] max-sm:min-width-[220px] md:min-width-[220px] lg:p-4 lg:pl-5 focus:outline-0 hover:border-gray-light4"
    to={to}
  >
    <span
      className="text-xs font-bold text-gray no-underline lg:text-sm"
      to={to}
    >
      {label}
    </span>

    {!!image && (
      <JBImage
        src={image}
        className="h-9 object-contain w-12 lg:h-12 lg:w-16"
        trim="color"
        trimPad={20}
        alt=""
        width={68}
      />
    )}
  </Link>
);

SubcategoryCard.propTypes = {
  image: PropTypes.string,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default SubcategoryCard;
