/* eslint-disable no-unused-expressions */
import { useKey } from 'react-use';

import useOnEsc from 'hooks/useOnEsc';
import getFirstFocusableElement from '../helpers/getFirstFocusableElement';
import getLastFocusableElement from '../helpers/getLastFocusableElement';
import isActiveElement from '../helpers/isActiveElement';
import {
  isEnterKey,
  isSpacebarKey,
  isTabAndNotShiftKey,
  isTabAndShiftKey,
} from '../helpers/keys';

const useKeyboardControlForDropdown = ({
  close,
  getPopupContainerElement,
  isOpen,
  isParentPopup = false,
  open,
  toggleRef,
}) => {
  // If user presses enter or spacebar while on the toggle element, open the
  // popup and focus on the first focusable element
  useKey(
    key => isSpacebarKey(key) || isEnterKey(key),
    event => {
      const toggleElement = toggleRef?.current;
      if (!toggleElement) return;

      if (isActiveElement(toggleElement)) {
        open?.();

        // Delay triggering of focus on first element, because we don't want
        // this event to retrigger for nested dropdowns such as subcategory
        // menus in the desktop navbar menu
        setTimeout(() => {
          const firstFocusableElement = getFirstFocusableElement(
            getPopupContainerElement?.()
          );

          if (firstFocusableElement) {
            // Only preventDefault() if focusable elements exist within the
            // popup container, otherwise allow the default action such as link
            // or button to occur
            event.preventDefault();
          }

          firstFocusableElement?.focus();
        }, 100);
      }
    },
    { event: 'keydown' }
  );

  // If user presses shift-tab while on the first focusable element within
  // popupContainer element, close the popup and focus on the toggle element
  useKey(
    isTabAndShiftKey,
    event => {
      const toggleElement = toggleRef?.current;
      if (!toggleElement) return;

      const firstFocusableElement = getFirstFocusableElement(
        getPopupContainerElement?.()
      );

      if (isActiveElement(firstFocusableElement)) {
        event.preventDefault();

        // If the hook is being called from a parent popup, but the last
        // element is within a nested popup, return early to leave the
        // handling to the nested popup
        if (
          isParentPopup &&
          firstFocusableElement?.closest('[data-nested-popup]')
        ) {
          return;
        }

        close?.();
        toggleElement?.focus();
      }
    },
    { event: 'keydown' }
  );

  // If user presses tab while on the first focusable element within
  // popupContainer element, close the popup and focus on the next element in
  // the toggleContainer element
  useKey(
    isTabAndNotShiftKey,
    event => {
      const toggleElement = toggleRef?.current;
      if (!toggleElement) return;

      const lastFocusableElement = getLastFocusableElement(
        getPopupContainerElement?.()
      );

      if (isActiveElement(lastFocusableElement)) {
        event.preventDefault();

        // If the hook is being called from a parent popup, but the last
        // element is within a nested popup, return early to leave the
        // handling to the nested popup
        if (
          isParentPopup &&
          lastFocusableElement?.closest('[data-nested-popup]')
        ) {
          return;
        }

        close?.();
        toggleElement?.focus();
      }
    },
    { event: 'keydown' }
  );

  // If user presses Escape key while on the popup is open, close the popup and
  // focus on the toggle element
  useOnEsc(!!toggleRef?.current && isOpen, () => {
    close?.();
    toggleRef?.current?.focus();
  });
};

export default useKeyboardControlForDropdown;
