import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FixedWidthScroller = ({
  children,
  className = '',
  overflowDisabled = false,
  scrollBarHidden = false,
}) => (
  <div
    className={classNames(
      overflowDisabled
        ? 'block'
        : "items-stretch flex -ml-5 -mr-5 overflow-x-auto pb-[1.2rem] pl-5 [&_>_*]:flex-1 after:[content:''] after:[border-right:20px_solid_transparent] after:w-0 after:md:[border-right:3.333vw_solid_transparent] after:min-[1501px]:[border-right:calc((100vw_-_1400px)_/2)__solid_transparent] md:ml-[-3.333vw] md:mr-[-3.333vw] md:pl-[3.333vw] min-[1501px]:ml-[calc((-100vw_-_-1400px)_/2)] min-[1501px]:mr-[calc((-100vw_-_-1400px)_/2)] min-[1501px]:pl-[calc((100vw_-_1400px)_/2)]",
      scrollBarHidden
        ? '[&::-webkit-scrollbar]:hidden [scrollbar-width:none] [-ms-overflow-style:none]'
        : '',
      className
    )}
  >
    {children}
  </div>
);

FixedWidthScroller.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  overflowDisabled: PropTypes.bool,
  scrollBarHidden: PropTypes.bool,
};

export default FixedWidthScroller;
