/* eslint-disable jsx-a11y/no-static-element-interactions */
// The parent element below captures clicks that bubble from nested buttons and
// links, so we can close the navbar when the user clicks on a link or button
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import NavModal from 'components/nav/shared/NavModal';
import useIsTitleOrSectionHovered from 'hooks/useIsTitleOrSectionHovered';
import useIsPDP from 'hooks/useIsPDP';
import Prefetcher from 'components/essentials/Prefetcher';
import getCategoryPhotosToPreload from 'components/nav/shared/data/getCategoryPhotosToPreload';
import categoryPhotoImgixProps from 'components/nav/DesktopHeader/components/DesktopNavCategorySectionImage/categoryPhotoImgixProps';
import isEventWithinLinkOrButton from 'commons/isEventWithinLinkOrButton';
import CartModal from 'components/nav/shared/CartModal';
import BrowserOnly from 'components/consumer/BrowserOnly';
import DesktopTopNav from './components/DesktopTopNav';
import DesktopPrimaryNav from './components/DesktopPrimaryNav';
import DesktopNavCategoryModal from './components/DesktopNavCategoryModal';
import DesktopNavShopByMenu from './components/DesktopNavShopByMenu';

const photosToPreload = getCategoryPhotosToPreload();
const photosToPreloadWithImgixConfig = photosToPreload.map(url => ({
  url,
  imgixProps: categoryPhotoImgixProps,
}));

const DesktopHeader = () => {
  const {
    resetIsTitleOrSectionHovered,
    selectedItem,
    setIsTitleHovered,
    setIsSectionHovered,
  } = useIsTitleOrSectionHovered();

  const showStaticHeader = useIsPDP();
  const [isOpen, setIsOpen] = useState(false);
  const [isShopByLinkHovered, setIsShopByLinkHovered] = useState(false);
  const [isShopByMenuHovered, setIsShopByMenuHovered] = useState(false);
  const isShopByOpen = isShopByLinkHovered || isShopByMenuHovered;

  useEffect(() => {
    setIsOpen(isShopByOpen || !!selectedItem);
  }, [selectedItem, isShopByOpen]);

  return (
    <>
      <NavModal isOpen={isOpen}>
        <div className="bg-white relative z-[1003] max-[1023px]:hidden DesktopHeader__section--top-section">
          <DesktopTopNav
            isShopByOpen={isShopByOpen}
            setIsShopByLinkHovered={setIsShopByLinkHovered}
          />
          <DesktopNavShopByMenu
            isOpen={isShopByOpen}
            closeNavbar={() => {
              setIsShopByMenuHovered(false);
            }}
            setIsShopByMenuHovered={setIsShopByMenuHovered}
          />
        </div>

        <div
          className={classNames('bg-white z-[1002] max-[1023px]:hidden top-0', {
            sticky: !showStaticHeader,
            relative: showStaticHeader,
          })}
          onClick={event => {
            // If the user clicks on a link or button within, we want to
            // close the navbar
            if (isEventWithinLinkOrButton(event)) {
              resetIsTitleOrSectionHovered();
            }
          }}
        >
          <div className="min-h-[55px]">
            <DesktopPrimaryNav
              selectedCategoryId={selectedItem}
              setIsLinkHovered={setIsTitleHovered}
            />

            <Prefetcher onRenderAssets={photosToPreloadWithImgixConfig}>
              <BrowserOnly>
                <DesktopNavCategoryModal
                  categoryId={selectedItem}
                  setIsSectionHovered={setIsSectionHovered}
                  isOpen={!!selectedItem}
                />
              </BrowserOnly>
            </Prefetcher>
          </div>
        </div>
      </NavModal>

      <CartModal />
    </>
  );
};

export default DesktopHeader;
