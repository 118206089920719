import React from 'react';
import PropTypes from 'prop-types';

const MobileNavbarSection = ({ children }) => (
  <div className="[&+&]:[border-top:1px_solid_#f1f1f1] [&+&]:mt-2.5 [&+&]:pt-3.5">
    {children}
  </div>
);

MobileNavbarSection.propTypes = {
  children: PropTypes.node,
};

export default MobileNavbarSection;
