import desktopStaticNavItems from './desktopStaticNavItems';

const getPhotosForCategory = (allPhotos, subcategory) => {
  // In DesktopNavCategorySectionUI, if a subcategory contains nested items or
  // customContent, show that on on desktop instead so we should not preload
  // the image
  if (subcategory.items || subcategory.customContent) {
    return allPhotos;
  }

  if (subcategory.image) {
    return [...allPhotos, subcategory.image];
  }

  return allPhotos;
};

const getCategoryPhotosToPreload = () => {
  const photos = desktopStaticNavItems.reduce((allPhotos, category) => {
    let updatedPhotos = allPhotos;

    if (category.image) {
      updatedPhotos = [...updatedPhotos, category.image];
    }

    if (category.items) {
      updatedPhotos = [
        ...updatedPhotos,
        ...category.items.reduce(getPhotosForCategory, []),
      ];
    }

    return updatedPhotos;
  }, []);

  return photos;
};

export default getCategoryPhotosToPreload;
