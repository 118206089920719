import { useCallback, useEffect, useState } from 'react';
import { useEvent } from 'react-use';
import getSlugFromProductUrl from 'commons/getSlugFromProductUrl';
import getPath from 'commons/getPath';

const useQuickViewModalState = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [slug, setSlug] = useState(null);
  const [path, setPath] = useState(null);
  const [productVariation, setProductVariation] = useState(null);

  const onLookbookQuickViewClick = useCallback(event => {
    // All Lookbooks messages are json encoded and will need to be parsed
    try {
      const message = JSON.parse(event?.data);
      const { product } = message;

      if (message.action === 'quickView') {
        const updatedSlug = product?.url
          ? getSlugFromProductUrl(product.url)
          : null;
        const updatedPath = getPath(product?.url) || null;
        const updatedProductVariation = (product?.variantId || '').replace(
          product.id,
          ''
        );

        if (updatedSlug) {
          setSlug(updatedSlug);
          setPath(updatedPath);
          setIsOpen(true);
          setProductVariation(updatedProductVariation);
        }
      }
    } catch {
      // Ignore JSON parse errors, this is an indication that the message was
      // not sent by Lookbooks
    }
  }, []);

  useEvent('message', onLookbookQuickViewClick);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setSlug(null);
      setPath(null);
    }
  }, [isOpen]);

  return {
    isOpen,
    onClose,
    path,
    slug,
    productVariation,
  };
};

export default useQuickViewModalState;
