import React from 'react';

import JBImage from 'components/essentials/JBImage';
import IconArrowLeft from 'components/icons/iconArrowLeft';
import SmallIconArrowLeft from 'components/icons/iconSimpleArrowLeft';
import SidebarModal from 'components/consumer/SidebarModal';
import useIsTradeUserSidebarOpen from 'global-state/isTradeUserSidebarOpen/useIsTradeUserSidebarOpen';
import useCloseTradeUserSidebar from 'global-state/isTradeUserSidebarOpen/useCloseTradeUserSidebar';
import withBrowserOnly from 'components/hoc/withBrowserOnly';
import useScreen from 'hooks/useScreen';

const TradeUserSidebar = () => {
  const { isMobile } = useScreen();

  const isTradeUserSidebarOpen = useIsTradeUserSidebarOpen();
  const closeTradeUserSidebar = useCloseTradeUserSidebar();

  const IconArrow = isMobile ? SmallIconArrowLeft : IconArrowLeft;

  return (
    <SidebarModal
      isOpen={isTradeUserSidebarOpen}
      onClose={closeTradeUserSidebar}
      mobileIsFullHeight
      desktopNoPadding
    >
      <div className="flex pt-[4.5rem] px-8 relative items-center pb-4 max-[767px]:p-4 max-[767px]:justify-center [&_svg]:mr-3 [&_svg]:max-[767px]:left-4 [&_svg]:max-[767px]:absolute">
        <IconArrow
          width={20}
          onClick={closeTradeUserSidebar}
          className="cursor-pointer"
        />
        <div className="text-base font-bold">Trade Member Discount</div>
      </div>

      <div className="px-8 pb-8">
        <div className="pt-12 [border-top:1px_solid_#e5e5e5] max-[767px]:pl-4 max-[767px]:pr-4">
          <div className="text-xl font-bold">
            Your Trade Member discount has been applied across the website:
          </div>
          <br />
          <ul className="ml-4">
            <li>
              <div className="text-lg font-bold">
                Up to 50% off upholstered products
              </div>
            </li>
            <li>
              <div className="text-lg font-bold">
                Up to 40% off casegoods & outdoors
              </div>
            </li>
            <li>
              <div className="text-lg font-bold">Up to 30% off decor</div>
            </li>
          </ul>
          <div className="text-center text-base font-bold">
            Thanks for being a part of our Trade program! Feel free to reach out
            if you have any questions or need some help.
          </div>
        </div>

        <div className="mt-4 pt-4 [border-top:1px_solid_#f1f1f1] max-[767px]:m-4">
          <JBImage
            width={380}
            src="https://joybird2.imgix.net/user-uploads-staging/JB-TradeTeam-Module-transparent.png"
            alt="Trade Team"
          />
          <div className="text-base font-bold text-center">
            <a className="text-gray" href="mailto:trade@joybird.com">
              trade@joybird.com
            </a>
            <span>&nbsp; &#8226; &nbsp; </span>
            <a className="text-gray" href="tel:18552084414">
              855-208-4414
            </a>
          </div>
        </div>
      </div>
    </SidebarModal>
  );
};

export default withBrowserOnly(TradeUserSidebar);
