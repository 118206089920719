import { useCallback } from 'react';
import { useSetAtom } from 'jotai';

import isTradeUserSidebarOpenAtom from './isTradeUserSidebarOpenAtom';

const useCloseTradeUserSidebar = () => {
  const setIsTradeUserSidebarOpen = useSetAtom(isTradeUserSidebarOpenAtom);

  const closeTradeUserSidebar = useCallback(() => {
    setIsTradeUserSidebarOpen(false);
  }, [setIsTradeUserSidebarOpen]);

  return closeTradeUserSidebar;
};

export default useCloseTradeUserSidebar;
