import React from 'react';
import PropTypes from 'prop-types';

import Cta from './components/Cta';

const CtaLink = React.forwardRef(
  ({ children, styling = 'link', ...otherProps }, ref) => (
    <Cta {...otherProps} ref={ref} styling={styling}>
      {children}
    </Cta>
  )
);

CtaLink.propTypes = {
  children: PropTypes.node.isRequired,
  styling: PropTypes.string,
};

export default CtaLink;
