import React from 'react';

import Link from 'components/consumer/Link';
import BannerTextSection from 'components/consumer/image-with-text-banner2/BannerTextSection';
import RatioContainer from 'components/consumer/RatioContainer';

const DesktopNavClearance = () => (
  <Link
    to="/clearance/"
    className="hover:[outline-style:auto] hover:outline-2 hover:outline-blackOverlay"
  >
    <div className="DesktopNavClearance">
      <RatioContainer
        className="DesktopNavClearance__fixed-aspect-ratio"
        ratio={423 / 635}
      >
        <BannerTextSection
          config={{
            textContent: {
              icon: null,
              heroFancyText: 'Clearance',
              heroFancyTextColor: '#036A78',
              body: null,
              title:
                'Shop our range of clearance products, available up to 80% off',
              backgroundImage:
                'https://jbimages.imgix.net/JB0901-Banners-Homepage-Hero-Desktop-End-of-Summer-Sale-V2-Background-454c1f82-ff26-43f0-bd97-29bf93c1a95b.jpg',
            },
            colors: { textHex: '#2e2e2e', backgroundHex: 'transparent' },
            ctaColors: { textHex: 'white', backgroundHex: '#424242' },
          }}
          fullWidth
        />
      </RatioContainer>
    </div>
  </Link>
);

export default DesktopNavClearance;
