import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';

import useDesktopNavItems from 'components/nav/shared/data/useDesktopNavItems';
import DesktopNavCategorySection from '../DesktopNavCategorySection';

const DesktopNavCategoryModal = ({
  categoryId: selectedCategoryId,
  isOpen,
  setIsSectionHovered,
}) => {
  const categoryNavItems = useDesktopNavItems();
  const categoryIds = categoryNavItems.map(category => category.id);

  return (
    <div
      className={classNames('bg-white w-full', {
        'pointer-events-auto': isOpen,
        'pointer-events-none': !isOpen,
      })}
      onMouseEnter={() => {
        setIsSectionHovered(true);
      }}
      onMouseLeave={() => {
        setIsSectionHovered(false);
      }}
    >
      <div className="DesktopNavCategoryModal bg-white relative w-full">
        <div
          className={classNames(
            'h-[300px] bg-white absolute left-0 top-0 right-0 min-h-[508px] pt-11 pointer-events-none duration-500 ease-in delay-100',
            {
              '!opacity-100 transition-none': isOpen,
              'opacity-0 transition-[opacity]': !isOpen,
            }
          )}
          key="background"
          aria-hidden
        />

        {categoryIds.map(categoryId => (
          <Transition
            key={categoryId}
            className={classNames({
              'pointer-events-none': !isOpen,
            })}
            show={categoryId === selectedCategoryId && isOpen}
            enter="transition-opacity duration-200 ease-in"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200 ease-in delay-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <DesktopNavCategorySection
              isOpen={categoryId === selectedCategoryId && isOpen}
              categoryId={categoryId}
            />
          </Transition>
        ))}
      </div>
    </div>
  );
};

DesktopNavCategoryModal.propTypes = {
  categoryId: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsSectionHovered: PropTypes.func,
};

export default DesktopNavCategoryModal;
