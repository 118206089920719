import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CtaButton from 'components/essentials/Cta/CtaButton';
import NotificationBannerSkeleton from './NotificationBannerSkeleton';

const WelcomeBack = ({ firstName, wishListProduct, onClose }) => (
  <>
    <NotificationBannerSkeleton
      title={`Welcome to Joybird ${firstName}!`}
      body={
        <span>
          Start a wishlist, checkout faster and track your orders, what more
          could you ask for?
        </span>
      }
      onClose={onClose}
    />
    {wishListProduct ? (
      <NotificationBannerSkeleton
        modifier="second"
        title={`Welcome to Joybird ${firstName}!`}
        body={
          <>
            <span>
              {wishListProduct
                ? `The ${wishListProduct || 'product'} has been`
                : 'We missed you!'}{' '}
            </span>
            <span className="[&_button]:p-0 [&_button]:ml-[5px]">
              <Link
                to={wishListProduct ? '/wishlist/' : '/customer/dashboard/'}
              >
                <CtaButton
                  styling="link"
                  color="brand"
                  size="sm"
                  hasArrow
                  block
                  noLinkPadding
                >
                  {wishListProduct
                    ? 'saved to your wishlist'
                    : 'Go to your dashboard'}
                </CtaButton>
              </Link>
            </span>
          </>
        }
        onClose={onClose}
      />
    ) : null}
  </>
);

WelcomeBack.propTypes = {
  firstName: PropTypes.string,
  wishListProduct: PropTypes.string,
  onClose: PropTypes.func,
};

export default WelcomeBack;
