import { useEffect, useState } from 'react';

// TODO: Replace this with a better way to import without default
// eslint-disable-next-line import/no-named-default
import { default as isOnPDP } from 'commons/isPDP';

const useIsPDP = () => {
  const [isPDP, setIsPDP] = useState(false);

  useEffect(() => {
    // Due to progressive page loading, the PDP elements may not exist in DOM
    // immediately but we want to know as soon as possible
    const checkIfPDP = () => {
      setIsPDP(isOnPDP());
    };

    checkIfPDP();

    const timesToCheck = [100, 200, 500, 1000, 2000, 5000, 10000];

    timesToCheck.forEach(time => {
      setTimeout(checkIfPDP, time);
    });
  }, []);

  return isPDP;
};

export default useIsPDP;
