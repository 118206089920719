import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BrowserOnly from 'components/consumer/BrowserOnly';

const NavModal = ({ children, isOpen = false }) => (
  <>
    <BrowserOnly>
      <div
        className={classNames(
          'bg-blackOverlay bottom-0 left-0 pointer-events-none fixed right-0 top-0 z-[1002] transition-opacity duration-500 ease-[ease]',
          {
            'opacity-0': !isOpen,
            'opacity-100': isOpen,
          }
        )}
      />
    </BrowserOnly>

    {children}
  </>
);

NavModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};

export default NavModal;
