import React from 'react';
import PropTypes from 'prop-types';

import FloatingModal from 'components/consumer/FloatingModal';
import Link from 'components/consumer/Link';
import withBrowserOnly from 'components/hoc/withBrowserOnly';

const ChatUnavailableModal = ({ isOpen = false, onClose }) => (
  <FloatingModal
    isOpen={isOpen}
    onClose={onClose}
    title="We're currently offline"
  >
    You’ve reached us outside our normal business hours. Leave us a message{' '}
    <Link
      to="/contact-us/"
      className="text-brand underline hover:text-brand-darker hover:outline-none focus:text-brand-darker focus:outline-none"
    >
      here
    </Link>{' '}
    and we’ll get back to you as soon as possible.
  </FloatingModal>
);

ChatUnavailableModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withBrowserOnly(ChatUnavailableModal);
