import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/consumer/Link';

const DesktopNavShopByLink = ({ children, onClick, to }) => (
  <Link
    to={to}
    className="text-base font-bold text-gray flex leading-[24px] pb-0 pt-0 no-underline w-full hover:text-gray hover:outline-0 hover:transition-[opacity_.3s,transform_.3s] hover:underline border-0"
    onClick={onClick}
  >
    {children}
  </Link>
);

DesktopNavShopByLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onClick: PropTypes.func,
  to: PropTypes.string,
};

export default DesktopNavShopByLink;
