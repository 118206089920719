import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/consumer/Link';

const LinkOrButton = React.forwardRef((props, ref) => {
  if (props.to) {
    return <Link {...props} ref={ref} />;
  }

  return <button type="button" {...props} ref={ref} />;
});

LinkOrButton.propTypes = {
  to: PropTypes.string,
};

export default LinkOrButton;
