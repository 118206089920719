import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import useProductBasicDetails from 'data-hooks/useProductBasicDetails';
import useProductAttributes from 'data-hooks/useProductAttributes';
import withBrowserOnly from 'components/hoc/withBrowserOnly';
import QuickViewModalUI from './QuickViewModalUI';
import QuickViewModalEvents from './QuickViewModalEvents';
import useProductDimensions from './hooks/useProductDimensions';
import usePDPUrl from './hooks/usePDPUrl';
import PDPContext from '../PDP/state/context/PDPContext';
import usePDPCreateContextValue from '../PDP/hooks/usePDPCreateContextValue';

const QuickViewModal = ({ isOpen, onClose, path, slug, variantSku = '' }) => {
  const isModalOpen = isOpen && !!slug;
  const skip = !isModalOpen;

  const { data } = useProductBasicDetails(
    null,
    slug,
    {},
    {
      skip,
    }
  );

  const dimensions = useProductDimensions(null, slug, skip);
  const { name = '' } = data ?? {};
  const { id: productId = '' } = data ?? {};

  const {
    data: { description: headline, additional_attributes },
  } = useProductAttributes(null, slug, skip);

  const description = additional_attributes?.showpiece_desc;

  const pathWithQuery = usePDPUrl({
    path,
    skip,
    slug,
  });

  const history = useHistory();

  const onMoreMaterialsClick =
    path && history
      ? () => {
          onClose();
          history.push(path);
        }
      : undefined;

  const pdpContextValue = usePDPCreateContextValue(
    {
      isQuickView: true,
      pdpVariantSkuOverride: variantSku,
      slug,
    },
    {
      skip,
    }
  );
  const selectedOptionsVariantSku =
    pdpContextValue?.productOptions.data?.selectedOptionsVariantSku;
  const variantSkuFlashSale = !!pdpContextValue?.productOptions?.data?.stock
    ?.variantSkuFlashSale?.[selectedOptionsVariantSku];
  return (
    <PDPContext.Provider value={pdpContextValue}>
      <QuickViewModalUI
        description={headline || description}
        dimensions={dimensions}
        isOpen={isOpen}
        isOutOfStock={!!pdpContextValue?.stockAvailability?.data?.isOutOfStock}
        name={name}
        onClose={onClose}
        onMoreMaterialsClick={onMoreMaterialsClick}
        path={pathWithQuery}
        productId={productId}
        slug={slug}
      />
      {isModalOpen === true && (
        <QuickViewModalEvents
          productId={productId}
          product={data}
          slug={slug}
          selectedOptionsVariantSku={selectedOptionsVariantSku}
          variantSkuFlashSale={variantSkuFlashSale}
        />
      )}
    </PDPContext.Provider>
  );
};

QuickViewModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  path: PropTypes.string,
  slug: PropTypes.string,
  variantSku: PropTypes.string,
};

export default withBrowserOnly(QuickViewModal);
