import { useState } from 'react';
import { useDebounce, useWindowSize } from 'react-use';

// This hook automatically generates a new unique key each time that the window
// is resized, to force the component to re-mount and re-render. This will
// update the size of the carousel to mach the new window size.
const useCarouselKey = () => {
  const { width, height } = useWindowSize();
  const [debouncedWidth, setDebouncedWidth] = useState(0);
  const [debouncedHeight, setDebouncedHeight] = useState(0);

  useDebounce(
    () => {
      setDebouncedWidth(width);
      setDebouncedHeight(height);
    },
    100,
    [width, height]
  );

  return `${debouncedWidth}x${debouncedHeight}`;
};

export default useCarouselKey;
