import React from 'react';
import PropTypes from 'prop-types';

import JBImage from 'components/essentials/JBImage';
import Link from 'components/consumer/Link';
import classNames from 'classnames';
import categoryPhotoImgixProps from './categoryPhotoImgixProps';

const DesktopNavCategorySectionImage = ({
  description,
  descriptionTextColor = 'dark',
  image,
  label,
  to,
}) => {
  if (image) {
    return (
      <Link
        className="flex relative w-full"
        to={to}
        aria-hidden="true"
        tabIndex="-1"
      >
        <JBImage
          aria-label={label}
          className="w-full"
          src={image}
          {...categoryPhotoImgixProps}
        />

        {!!description && descriptionTextColor === 'light' && (
          <div className="[background:linear-gradient(rgba(0,0,0,.25)_,transparent_75%)] bottom-0 h-full left-0 absolute right-0 top-0 w-full" />
        )}

        {!!description && (
          <div
            className={classNames(
              'text-xl font-bold text-gray left-0 p-6 absolute right-0 top-0 w-full',
              {
                'text-brand-lightest': descriptionTextColor === 'light',
              }
            )}
          >
            {description}
          </div>
        )}
      </Link>
    );
  }

  return null;
};

DesktopNavCategorySectionImage.propTypes = {
  description: PropTypes.string,
  descriptionTextColor: PropTypes.oneOf(['light', 'dark']),
  image: PropTypes.string,
  label: PropTypes.string,
  to: PropTypes.string,
};

export default DesktopNavCategorySectionImage;
