import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SubcategoryCardGrid = ({ children, desktopColumnCount = '2' }) => (
  <div
    className={classNames('grid auto-rows-[1fr] gap-2 lg:grid-cols-[1fr_1fr]', {
      'lg:grid-cols-[1fr_1fr]': desktopColumnCount === '2',
      'lg:grid-cols-[1fr_1fr_1fr]': desktopColumnCount === '3',
    })}
  >
    {children}
  </div>
);

SubcategoryCardGrid.propTypes = {
  children: PropTypes.node,
  desktopColumnCount: PropTypes.oneOf(['2', '3']),
};

export default SubcategoryCardGrid;
