import React from 'react';
import PropTypes from 'prop-types';
import JBImage from 'components/essentials/JBImage';

import Link from 'components/consumer/Link';
import DesktopNavShopByListContainer from 'components/nav/DesktopHeader/components/DesktopNavShopByListContainer';
import DesktopNavCategoryList from 'components/nav/DesktopHeader/components/DesktopNavShopByList';
import DesktopNavLink from 'components/nav/DesktopHeader/components/DesktopNavShopByLink';
import QuickshipIcon from 'components/icons/iconQuickship';

const DesktopNavShopByMenuItems = ({ closeNavbar }) => (
  <>
    <DesktopNavShopByListContainer isImageButtonColumn>
      <Link
        to="/collections/"
        className='border-2 border-solid border-transparent flex flex-col h-2/5 justify-center mx-2 my-4 relative focus-visible:border-brand after:[content:\"\"] after:h-full after:left-0 after:absolute after:top-0 after:w-full [&_a]:text-gray [&_a]:overflow-hidden'
        onClick={closeNavbar}
      >
        <JBImage
          className="h-full max-h-[259px] object-cover object-center w-full [&.--enlarged]:[object-position:50%_65%] focus:opacity-75 hover:opacity-75"
          src="https://joybird2.imgix.net/public_img/Shop-By-Collections.jpg?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&trim=color&trim-pad=20&cs=srgb"
          width={500}
          trim="color"
        />
        <div className="text-black text-lg font-bold mb-2 mt-2 absolute text-center w-full z-[2] flex flex-col content-center">
          Collections
        </div>
      </Link>

      <Link
        to="/new-arrivals/?sortBy=newest"
        onClick={closeNavbar}
        className='border-2 border-solid border-transparent flex flex-col h-2/5 justify-center mx-2 my-4 relative focus-visible:border-brand after:[content:\"\"] after:h-full after:left-0 after:absolute after:top-0 after:w-full [&_a]:text-gray [&_a]:overflow-hidden'
      >
        <JBImage
          className="h-full max-h-[259px] object-cover object-center w-full [&.--enlarged]:[object-position:50%_65%] focus:opacity-75 hover:opacity-75"
          src="https://joybird2.imgix.net/public_img/Shop-By-New-Arrivals.jpg?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&trim=color&trim-pad=20&cs=srgb"
          width={500}
          height={375}
        />

        <div className="text-black text-lg font-bold mb-2 mt-2 absolute text-center w-full z-[2] flex flex-col content-center">
          New Arrivals
        </div>
      </Link>
    </DesktopNavShopByListContainer>

    <DesktopNavShopByListContainer isImageButtonColumn>
      <Link
        to="/swatches/"
        className='border-2 border-solid border-transparent flex flex-col h-2/5 justify-center mx-2 my-4 relative focus-visible:border-brand after:[content:\"\"] after:h-full after:left-0 after:absolute after:top-0 after:w-full [&_a]:text-gray [&_a]:overflow-hidden'
        onClick={closeNavbar}
      >
        <JBImage
          className="h-full max-h-[259px] object-cover object-center w-full [&.--enlarged]:[object-position:50%_65%] focus:opacity-75 hover:opacity-75"
          src="https://joybird2.imgix.net/public_img/Shop-By-Color.jpg?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&trim=color&trim-pad=20&cs=srgb"
          width={500}
        />

        <div className="text-black text-lg font-bold mb-2 mt-2 absolute text-center w-full z-[2] flex flex-col content-center">
          Meet our Fabrics
        </div>
      </Link>

      <Link
        to="/instock/"
        className='border-2 border-solid border-transparent flex flex-col h-2/5 justify-center mx-2 my-4 relative focus-visible:border-brand after:[content:\"\"] after:h-full after:left-0 after:absolute after:top-0 after:w-full [&_a]:text-gray [&_a]:overflow-hidden'
        onClick={closeNavbar}
      >
        <JBImage
          className="h-full max-h-[259px] object-cover object-center w-full [&.--enlarged]:[object-position:50%_65%] focus:opacity-75 hover:opacity-75"
          src="https://joybird2.imgix.net/public_img/Shop-By-Quick-Ship.jpg?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&trim=color&trim-pad=20&cs=srgb"
          width={500}
        />

        <div className="text-black text-lg font-bold mb-2 mt-2 absolute text-center w-full z-[2] flex flex-col content-center">
          <QuickshipIcon
            width={30}
            className="h-5 mt-0 mx-auto mb-[5px] w-[30px]"
          />
          <span>Ready to Ship</span>
        </div>
      </Link>
    </DesktopNavShopByListContainer>

    <DesktopNavShopByListContainer>
      <DesktopNavCategoryList isShopBy>
        <li>
          <span className="text-lg font-bold [border-bottom:1px_solid_#f1f1f1] text-gray block mt-0 mr-0 mb-2 ml-2 pb-2 w-[70%] min-[1200px]:w-full">
            By Style
          </span>

          <ul className="text-gray text-[14px] font-bold tracking-[-.1px] leading-none list-none m-0 pl-2">
            <li>
              <DesktopNavLink
                to="/small-space-furniture/"
                onClick={closeNavbar}
              >
                Small Space Furniture
              </DesktopNavLink>
            </li>

            <li>
              <DesktopNavLink
                to="/mid-century-furniture/"
                onClick={closeNavbar}
              >
                Mid Century Modern Furniture
              </DesktopNavLink>
            </li>

            <li>
              <DesktopNavLink to="/apartment-furniture/" onClick={closeNavbar}>
                Apartment Furniture
              </DesktopNavLink>
            </li>
          </ul>
        </li>
      </DesktopNavCategoryList>
    </DesktopNavShopByListContainer>

    <DesktopNavShopByListContainer>
      <DesktopNavCategoryList isShopBy>
        <li>
          <span className="text-lg font-bold [border-bottom:1px_solid_#f1f1f1] text-gray block mt-0 mr-0 mb-2 ml-2 pb-2 w-[70%] min-[1200px]:w-full">
            By Room
          </span>

          <ul className="text-gray text-[14px] font-bold tracking-[-.1px] leading-none list-none m-0 pl-2">
            <li>
              <DesktopNavLink
                to="/living-room-furniture/"
                onClick={closeNavbar}
              >
                Living Room Furniture
              </DesktopNavLink>
            </li>

            <li>
              <DesktopNavLink to="/bedroom/" onClick={closeNavbar}>
                Bedroom Furniture
              </DesktopNavLink>
            </li>

            <li>
              <DesktopNavLink
                to="/dining-room-furniture/"
                onClick={closeNavbar}
              >
                Dining Room Furniture
              </DesktopNavLink>
            </li>

            <li>
              <DesktopNavLink to="/outdoor-furniture/" onClick={closeNavbar}>
                Outdoor Furniture
              </DesktopNavLink>
            </li>
          </ul>
        </li>
      </DesktopNavCategoryList>
    </DesktopNavShopByListContainer>
  </>
);

DesktopNavShopByMenuItems.propTypes = {
  closeNavbar: PropTypes.func,
};

export default DesktopNavShopByMenuItems;
