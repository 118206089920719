// This function is useful to detect if the user clicks on a link nested within
// an ephemeral modal, e.g. the mobile navbar, because we would then we want to
// close the modal
const isEventWithinLink = event => {
  const element = event?.target;
  const domElementName = element?.tagName.toLowerCase();
  const isLink = domElementName === 'a';
  const isWithinLink = !!element?.closest?.('a');
  const isEventWithin = isLink || isWithinLink;

  return isEventWithin;
};

export default isEventWithinLink;
