import { useCallback } from 'react';
import { useSetAtom } from 'jotai';

import isLoginSidebarOpenAtom from './isLoginSidebarOpenAtom';

const useToggleIsLoginSidebarOpen = () => {
  const setIsLoginSidebarOpen = useSetAtom(isLoginSidebarOpenAtom);

  const toggleIsLoginSidebarOpen = useCallback(() => {
    setIsLoginSidebarOpen(isLoginSidebarOpen => !isLoginSidebarOpen);
  }, [setIsLoginSidebarOpen]);

  return toggleIsLoginSidebarOpen;
};

export default useToggleIsLoginSidebarOpen;
