'use client';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useInterval } from 'react-use';

import useRandomId from 'hooks/useRandomId';

const INITIAL_VALUES = {
  rand1: 0.2835021876878694,
  rand2: 0.5262141565116238,
  rand3: 0.1028784310624038,
};

const generateValues = () => ({
  rand1: Math.random(),
  rand2: Math.random(),
  rand3: Math.random(),
});

const GenericLoader = ({ inline }) => {
  const randomId = useRandomId('generic_loader');
  const [values, setValues] = useState(INITIAL_VALUES);

  useInterval(() => {
    setValues(generateValues());
  }, 250);

  return (
    <div
      className={classNames({
        'absolute top-1/2 left-1/2 -mt-[50px] -ml-[50px] scale-[2.5]': !inline,
      })}
    >
      <div style={{ height: 100, margin: '0 auto', width: 100 }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 500"
          width="500"
          height="500"
          preserveAspectRatio="xMidYMid meet"
          style={{
            width: '100%',
            height: '100%',
            transform: 'translate3d(0px, 0px, 0px)',
            contentVisibility: 'visible',
          }}
        >
          <defs>
            <clipPath id={`${randomId}_13`}>
              <rect width="500" height="500" x="0" y="0" />
            </clipPath>
            <filter id={`${randomId}_19`}>
              <feColorMatrix
                type="matrix"
                colorInterpolationFilters="sRGB"
                values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"
              />
              <feColorMatrix
                type="matrix"
                colorInterpolationFilters="sRGB"
                values={`0 0 0 0 ${values.rand1} 0 0 0 0 ${values.rand2} 0 0 0 0 ${values.rand3} 0 0 0 1 0`}
              />
            </filter>
            <filter id={`${randomId}_22`}>
              <feColorMatrix
                type="matrix"
                colorInterpolationFilters="sRGB"
                values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"
              />
              <feColorMatrix
                type="matrix"
                colorInterpolationFilters="sRGB"
                values={`0 0 0 0 ${values.rand1} 0 0 0 0 ${values.rand2} 0 0 0 0 ${values.rand3} 0 0 0 1 0`}
              />
            </filter>
          </defs>
          <g clipPath={`url(#${randomId}_13)`}>
            <g
              filter={`url(#${randomId}_22)`}
              transform="matrix(2,0,0,2,218,238)"
              opacity="1"
              style={{ display: 'block' }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                <path
                  fill="rgb(179,116,131)"
                  fillOpacity="1"
                  d=" M30.636999130249023,-0.26600000262260437 C22.416000366210938,13.291999816894531 6.066999912261963,11.680000305175781 -16.034000396728516,-1.7079999446868896 C-21.55900001525879,-5.053999900817871 -26.149999618530273,-1.0540000200271606 -27.5310001373291,3.7790000438690186 C-30.636999130249023,-9.475000381469727 -23.031999588012695,-13.291999816894531 -14.98799991607666,-8.633000373840332 C3.421999931335449,2.0320000648498535 17.393999099731445,13.269000053405762 30.636999130249023,-0.26600000262260437 C30.636999130249023,-0.26600000262260437 30.636999130249023,-0.26600000262260437 30.636999130249023,-0.26600000262260437z"
                />
                <path
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  fillOpacity="0"
                  strokeMiterlimit="4"
                  stroke="rgb(255,255,255)"
                  strokeOpacity="1"
                  strokeWidth="0"
                  d=" M30.636999130249023,-0.26600000262260437 C22.416000366210938,13.291999816894531 6.066999912261963,11.680000305175781 -16.034000396728516,-1.7079999446868896 C-21.55900001525879,-5.053999900817871 -26.149999618530273,-1.0540000200271606 -27.5310001373291,3.7790000438690186 C-30.636999130249023,-9.475000381469727 -23.031999588012695,-13.291999816894531 -14.98799991607666,-8.633000373840332 C3.421999931335449,2.0320000648498535 17.393999099731445,13.269000053405762 30.636999130249023,-0.26600000262260437 C30.636999130249023,-0.26600000262260437 30.636999130249023,-0.26600000262260437 30.636999130249023,-0.26600000262260437z"
                />
              </g>
            </g>
            <g
              filter={`url(#${randomId}_19)`}
              transform="matrix(2,0,0,2,250,250)"
              opacity="1"
              style={{ display: 'block' }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                <path
                  fill="rgb(179,116,131)"
                  fillOpacity="1"
                  d=" M4.650000095367432,16.735000610351562 C26.315000534057617,13.904999732971191 33.04199981689453,-4.571000099182129 38.65399932861328,-25.59600067138672 C38.65399932861328,-25.59600067138672 44.96500015258789,-26.871999740600586 44.96500015258789,-26.871999740600586 C44.96500015258789,-26.871999740600586 39.34199905395508,-28.201000213623047 39.34199905395508,-28.201000213623047 C36.10100173950195,-31.507999420166016 32.79600143432617,-32.09299850463867 29.93000030517578,-31.01099967956543 C26.8799991607666,-29.858999252319336 24.715999603271484,-27.030000686645508 23.260000228881836,-24.222999572753906 C17.797000885009766,-13.6899995803833 18.500999450683594,3.0820000171661377 2.062999963760376,5.349999904632568 C-9.293999671936035,6.914999961853027 -20.64900016784668,1.621999979019165 -32.52199935913086,-5.626999855041504 C-37.08100128173828,-8.41100025177002 -44.96500015258789,-1.8200000524520874 -37.90599822998047,3.9000000953674316 C-28.701000213623047,11.362000465393066 -15.430000305175781,17.79400062561035 0.5479999780654907,17.08099937438965 C0.5479999780654907,17.08099937438965 0.5619999766349792,17.079999923706055 0.5619999766349792,17.079999923706055 C0.9399999976158142,18.29599952697754 2.058000087738037,22.54800033569336 0.75,26.816999435424805 C-4.8420000076293945,29.46299934387207 -19.11199951171875,29.43199920654297 -21.266000747680664,32.09400177001953 C-21.266000747680664,32.09400177001953 26.36400032043457,32.09400177001953 26.36400032043457,32.09400177001953 C24.209999084472656,29.43199920654297 9.939000129699707,29.46299934387207 4.3470001220703125,26.816999435424805 C2.7980000972747803,21.766000747680664 4.650000095367432,16.735000610351562 4.650000095367432,16.735000610351562 C4.650000095367432,16.735000610351562 4.650000095367432,16.735000610351562 4.650000095367432,16.735000610351562z"
                />
                <path
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  fillOpacity="0"
                  strokeMiterlimit="4"
                  stroke="rgb(255,255,255)"
                  strokeOpacity="1"
                  strokeWidth="0"
                  d=" M4.650000095367432,16.735000610351562 C26.315000534057617,13.904999732971191 33.04199981689453,-4.571000099182129 38.65399932861328,-25.59600067138672 C38.65399932861328,-25.59600067138672 44.96500015258789,-26.871999740600586 44.96500015258789,-26.871999740600586 C44.96500015258789,-26.871999740600586 39.34199905395508,-28.201000213623047 39.34199905395508,-28.201000213623047 C36.10100173950195,-31.507999420166016 32.79600143432617,-32.09299850463867 29.93000030517578,-31.01099967956543 C26.8799991607666,-29.858999252319336 24.715999603271484,-27.030000686645508 23.260000228881836,-24.222999572753906 C17.797000885009766,-13.6899995803833 18.500999450683594,3.0820000171661377 2.062999963760376,5.349999904632568 C-9.293999671936035,6.914999961853027 -20.64900016784668,1.621999979019165 -32.52199935913086,-5.626999855041504 C-37.08100128173828,-8.41100025177002 -44.96500015258789,-1.8200000524520874 -37.90599822998047,3.9000000953674316 C-28.701000213623047,11.362000465393066 -15.430000305175781,17.79400062561035 0.5479999780654907,17.08099937438965 C0.5479999780654907,17.08099937438965 0.5619999766349792,17.079999923706055 0.5619999766349792,17.079999923706055 C0.9399999976158142,18.29599952697754 2.058000087738037,22.54800033569336 0.75,26.816999435424805 C-4.8420000076293945,29.46299934387207 -19.11199951171875,29.43199920654297 -21.266000747680664,32.09400177001953 C-21.266000747680664,32.09400177001953 26.36400032043457,32.09400177001953 26.36400032043457,32.09400177001953 C24.209999084472656,29.43199920654297 9.939000129699707,29.46299934387207 4.3470001220703125,26.816999435424805 C2.7980000972747803,21.766000747680664 4.650000095367432,16.735000610351562 4.650000095367432,16.735000610351562 C4.650000095367432,16.735000610351562 4.650000095367432,16.735000610351562 4.650000095367432,16.735000610351562z"
                />
              </g>
            </g>
            <g
              transform="matrix(2,0,0,2,315.75,194.25)"
              opacity="1"
              style={{ display: 'block' }}
            >
              <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                <path
                  fill="rgb(255,255,255)"
                  fillOpacity="1"
                  d=" M0.0010000000474974513,-1.1820000410079956 C0.6520000100135803,-1.1820000410079956 1.1820000410079956,-0.6520000100135803 1.1820000410079956,0.0010000000474974513 C1.1820000410079956,0.6539999842643738 0.6520000100135803,1.1820000410079956 0.0010000000474974513,1.1820000410079956 C-0.6520000100135803,1.1820000410079956 -1.1820000410079956,0.6539999842643738 -1.1820000410079956,0.0010000000474974513 C-1.1820000410079956,-0.6520000100135803 -0.6520000100135803,-1.1820000410079956 0.0010000000474974513,-1.1820000410079956z"
                />
                <path
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  fillOpacity="0"
                  strokeMiterlimit="4"
                  stroke="rgb(255,255,255)"
                  strokeOpacity="1"
                  strokeWidth="0"
                  d=" M0.0010000000474974513,-1.1820000410079956 C0.6520000100135803,-1.1820000410079956 1.1820000410079956,-0.6520000100135803 1.1820000410079956,0.0010000000474974513 C1.1820000410079956,0.6539999842643738 0.6520000100135803,1.1820000410079956 0.0010000000474974513,1.1820000410079956 C-0.6520000100135803,1.1820000410079956 -1.1820000410079956,0.6539999842643738 -1.1820000410079956,0.0010000000474974513 C-1.1820000410079956,-0.6520000100135803 -0.6520000100135803,-1.1820000410079956 0.0010000000474974513,-1.1820000410079956z"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

GenericLoader.propTypes = {
  inline: PropTypes.bool,
};

export default GenericLoader;
