import useSWR from 'swr';
import { useEffect } from 'react';

import { logError } from 'commons/logger';
import getEnvValue from 'commons/getEnvValue';
import useIsBrowser from 'hooks/useIsBrowser';
import fetcher from 'commons/fetcher';

// This hook gets the user's location data from the CloudFront API using the
// SWR library to provide caching and prevent excessive API calls.

const apiURL =
  process.env.NEXT_PUBLIC_USER_LOCATION_CF_API ??
  getEnvValue('REACT_APP_USER_LOCATION_CF_API');

const useCloudfrontLocationAPI = ({ skip = false }) => {
  // We should only run this hook in the browser, because the API relies on the
  // user's IP address to get the correct location data.

  const isBrowser = useIsBrowser();

  const { data: location, error, isLoading } = useSWR(
    isBrowser && !skip ? apiURL : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      shouldRetryOnError: false,
    }
  );

  useEffect(() => {
    if (error) {
      logError(new Error('useCloudfrontLocationAPI', { cause: error }));
    }
  }, [error]);

  return { isLoading, location, error };
};

export default useCloudfrontLocationAPI;
