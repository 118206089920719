import React from 'react';
import PropTypes from 'prop-types';

const DesktopNavShopByList = ({ children }) => (
  <ul className="min-h-[350px] pt-6 pr-8 pb-6 pl-[3.333vw] font-bold tracking-[-.1px] text-[14px] text-gray list-none leading-[2.3]">
    {children}
  </ul>
);

DesktopNavShopByList.propTypes = {
  children: PropTypes.node,
};

export default DesktopNavShopByList;
