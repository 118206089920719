import React, { useEffect, useState } from 'react';
import getRandomId from 'commons/getRandomId';
import RunHook from './RunHook';

// eslint-disable-next-line import/no-mutable-exports
let getHookResult = () => {};

// To support rendering of hooks outside of React components, this component
// needs to be rendered just once within the component tree.
const HookRunner = () => {
  const [hooksToRun, setHooksToRun] = useState([]);

  const addHook = hookToRun => {
    setHooksToRun(currentHooksToRun => [...currentHooksToRun, hookToRun]);
  };

  const removeHook = id => {
    setHooksToRun(currentHooksToRun =>
      currentHooksToRun.filter(hook => hook.id !== id)
    );
  };

  useEffect(() => {
    getHookResult = ({ hookArguments, isResultReady, hook }) =>
      new Promise((resolve, reject) => {
        const id = getRandomId();

        addHook({
          hookArguments,
          id,
          isResultReady,
          hook,
          resolve: async result => {
            const clonedResult = result
              ? JSON.parse(JSON.stringify(result))
              : undefined;
            removeHook(id);
            resolve(clonedResult);
          },
          reject: async error => {
            const clonedError = error
              ? JSON.parse(JSON.stringify(error))
              : undefined;
            removeHook(id);
            reject(clonedError);
          },
        });
      });
  }, []);

  return (
    <>
      {hooksToRun.map(
        ({ hook, hookArguments, id, isResultReady, reject, resolve }) => (
          <RunHook
            hook={hook}
            hookArguments={hookArguments}
            isResultReady={isResultReady}
            key={id}
            reject={reject}
            resolve={resolve}
          />
        )
      )}
    </>
  );
};

export { getHookResult };
export default HookRunner;
