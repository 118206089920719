import React from 'react';
import PropTypes from 'prop-types';

import VisuallyHidden from 'components/consumer/VisuallyHidden';
import IconClose from 'components/icons/iconClose';
import { colorWhite } from 'styles/layout/colors';

const MobileNavbarHiddenCloseButton = ({ onClick }) => (
  <button
    className="[background:none] border-0 rounded-[3px] opacity-0 p-2 pointer-events-none focus-visible:opacity-100 focus-visible:outline-white"
    onClick={onClick}
    type="button"
  >
    <IconClose
      className="MobileNavbarHiddenCloseButton__icon"
      color={colorWhite}
      height="16"
      width="16"
    />
    <VisuallyHidden>Close</VisuallyHidden>
  </button>
);

MobileNavbarHiddenCloseButton.propTypes = {
  onClick: PropTypes.func,
};

export default MobileNavbarHiddenCloseButton;
