import React from 'react';
import { PropTypes } from 'prop-types';
import ProductCardStaticPlaceholder from 'components/consumer/ProductCardStaticPlaceholder';

const GridLoading = ({ cardsCount = 6 }) => (
  <>
    {[...Array(cardsCount)].map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className="ProductCardContainer" key={index}>
        <ProductCardStaticPlaceholder />
      </div>
    ))}
  </>
);

GridLoading.propTypes = {
  cardsCount: PropTypes.number,
};

export default GridLoading;
