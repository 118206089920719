import React from 'react';
import PropTypes from 'prop-types';

import CtaLink from 'components/essentials/Cta/CtaLink';
import useIsTouchDevice from 'hooks/useIsTouchDevice';
import notificationBannerHelper from './utils';
import NotificationBannerSkeleton from './NotificationBannerSkeleton';

const FabricFavoritedBanner = ({ fabricName, isUserLoggedIn, onClose }) => {
  const isTouchDevice = useIsTouchDevice();

  return (
    <NotificationBannerSkeleton
      modifier="top"
      title={`${fabricName} has been added to your favorites.`}
      body={
        <div className="text-sm flex flex-col items-start">
          <div className="mt-4">
            Keep track of your favorite fabrics all in one place. Simply double
            click the swatch to add or remove from your favorites list.
          </div>
          {!isUserLoggedIn && (
            <div className="mt-4">
              <CtaLink
                to={`/sign-in/?redirect=${window.location.pathname}`}
                styling="link-style"
                color="brand"
                onClick={() => notificationBannerHelper.hideMsg()}
                textClassName="!inline underline"
              >
                Sign in
              </CtaLink>
              &nbsp;to your account to make sure your favorites are saved.
            </div>
          )}
        </div>
      }
      onClose={onClose}
      keepOpenOnMouseEnter={!isTouchDevice}
    />
  );
};

FabricFavoritedBanner.propTypes = {
  onClose: PropTypes.func,
  fabricName: PropTypes.string,
  isUserLoggedIn: PropTypes.bool,
};

export default FabricFavoritedBanner;
