// Note: ideally we should always be able to determine which page we are on by
// using standard React features, e.g. passing down props. This is not always
// straightforward due to the complex dynamic routing used in Consumer, so
// instead we use an "escape hatch" approach here by checking for the existence
// of PDP DOM elements.
const isPDP = () => {
  const desktopElement = document.querySelector('[data-xc="PDPDesktop"]');
  const mobileElement = document.querySelector('[data-xc="PDPMobile"]');
  return !!desktopElement || !!mobileElement;
};

export default isPDP;
