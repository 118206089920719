import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import avo from 'analytics/avo';
import SSRSafeElement from 'commons/SSRSafeElement';
import Link from 'components/consumer/Link';
import StatusLabel from 'components/consumer/StatusLabel';
import IconArrowRightSimple from 'components/icons/iconArrowRightSimple';
import useKeyboardControlForDropdown from 'hooks/useKeyboardControlForDropdown';
import useUserCurrentPageType from 'hooks/useUserCurrentPageType';

const DesktopNavLinkGroupItem = ({
  categoryId = '',
  containerRef,
  hasNestedContent = false,
  id,
  isNew,
  label,
  selectedItem,
  setIsTitleHovered,
  url,
}) => {
  const toggleRef = useRef(null);
  const isSelected = id === selectedItem;
  const currentPage = useUserCurrentPageType();

  const open = () => {
    setIsTitleHovered(id, true);
  };

  const close = () => {
    setIsTitleHovered(id, false);
  };

  useKeyboardControlForDropdown({
    isOpen: isSelected,
    // Since the popup container is in a separate component, while not ideal it
    // is simplest to access this DOM element using vanilla JS
    getPopupContainerElement: () =>
      document.getElementById(`${categoryId}-${id}`),
    toggleContainerRef: containerRef,
    toggleRef,
    open,
    close,
  });

  return (
    <li className="list-none">
      <Link
        to={url}
        // remove when room refresh/visualizer AB test is done
        onClick={
          label === 'Room Visualizer' || label === 'Room Refresh'
            ? () => {
                avo.ctaClicked({
                  text: label,
                  targetUrl: url,
                  cta: 'Room Refresh',
                  location: currentPage,
                  component: 'Navbar',
                });
              }
            : undefined
        }
        className={classNames(
          'text-sm font-bold items-center flex p-2.5 relative no-underline [transition:color_125ms_ease-in] xl:text-base xl:font-bold',
          'focus-visible:outline-offset-[-4px] focus-visible:!text-brand',
          'focus:!text-brand focus:!no-underline',
          'hover:!text-brand hover:!no-underline',
          {
            '!text-gray': !isSelected,
            '!text-brand !no-underline': isSelected,
          }
        )}
        onMouseEnter={open}
        onMouseLeave={close}
        ref={toggleRef}
      >
        <span>{label}</span>

        {!!isNew && <StatusLabel className="ml-2">New</StatusLabel>}

        {!!hasNestedContent && (
          <IconArrowRightSimple
            className={classNames(
              'ml-2 relative',
              'group-hover/DesktopNavLinkGroupItem__link:opacity-100 group-hover/DesktopNavLinkGroupItem__link:transform-[translateX(0)] group-hover/DesktopNavLinkGroupItem__link:[transition:all_125ms_ease-out]',
              'group-focus/DesktopNavLinkGroupItem__link:opacity-100 group-focus/DesktopNavLinkGroupItem__link:transform-[translateX(0)] group-focus/DesktopNavLinkGroupItem__link:[transition:all_125ms_ease-out]',
              {
                'opacity-0 transform-[translateX(10px)] [transition:none]': !isSelected,
                'opacity-100 transform-[translateX(0)] [transition:all_125ms_ease-out]': isSelected,
              }
            )}
            width={6}
            height={10}
            color="currentColor"
          />
        )}
      </Link>
    </li>
  );
};

DesktopNavLinkGroupItem.propTypes = {
  categoryId: PropTypes.string,
  containerRef: PropTypes.shape({
    current: PropTypes.instanceOf(SSRSafeElement),
  }),
  hasNestedContent: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  label: PropTypes.string.isRequired,
  selectedItem: PropTypes.string,
  setIsTitleHovered: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default DesktopNavLinkGroupItem;
