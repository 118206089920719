const getCityAndCountryFromAddress = address => {
  if (!address) {
    return {};
  }

  const [city, country] = address.split(', ');

  return { city, country };
};

export default getCityAndCountryFromAddress;
