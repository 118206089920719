import React from 'react';
import PropTypes from 'prop-types';

import useDesktopNavItems from 'components/nav/shared/data/useDesktopNavItems';

import DesktopNavCategoriesUI from './DesktopNavCategoriesUI';

const DesktopNavCategories = ({ selectedCategoryId, setIsLinkHovered }) => {
  const categoryNavItems = useDesktopNavItems();

  return (
    <DesktopNavCategoriesUI
      items={categoryNavItems}
      selectedCategoryId={selectedCategoryId}
      setIsLinkHovered={setIsLinkHovered}
    />
  );
};

DesktopNavCategories.propTypes = {
  selectedCategoryId: PropTypes.string,
  setIsLinkHovered: PropTypes.func,
};

export default DesktopNavCategories;
