import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ProgressLoaderDot = ({ inline = false, grey = false }) => (
  <span
    className={classNames(
      'w-[6px] h-[6px] m-1 rounded inline-block align-middle bg-brand animate-progress-loader-dots first:[animation-delay:-0.3s] [&:nth-child(2)]:[animation-delay:-0.15s]',
      {
        'ml-[2px]': inline,
        'bg-gray': grey,
      }
    )}
  />
);

ProgressLoaderDot.propTypes = {
  grey: PropTypes.bool,
  inline: PropTypes.bool,
};

const ProgressLoaderDots = ({
  className = '',
  grey = false,
  inline = false,
}) => (
  <div
    className={classNames(
      'mx-auto my-0 whitespace-nowrap top-1/2',
      {
        'absolute left-1/2 -translate-x-1/2 -translate-y-1/2 text-center': !inline,
        'relative transform-none left-0': inline,
      },
      className
    )}
  >
    <ProgressLoaderDot inline={inline} grey={grey} />
    <ProgressLoaderDot inline={inline} grey={grey} />
    <ProgressLoaderDot inline={inline} grey={grey} />
  </div>
);

ProgressLoaderDots.propTypes = {
  className: PropTypes.string,
  grey: PropTypes.bool,
  inline: PropTypes.bool,
};

export default ProgressLoaderDots;
