import React from 'react';
import PropTypes from 'prop-types';

const ArrowLeft = ({ style, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
    style={{ transform: 'rotate(360deg)', ...style }}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 32 32"
    {...props}
  >
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="M10 6L2 16l8 10M2 16h28" />
    </g>
  </svg>
);

ArrowLeft.propTypes = {
  style: PropTypes.objectOf(PropTypes.object),
};

export default ArrowLeft;
