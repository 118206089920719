import React from 'react';
import PropTypes from 'prop-types';
import useRandomId from 'hooks/useRandomId';

import { colorGrayLight1 } from 'styles/layout/colors';

const IconMapMarker2 = ({ color = colorGrayLight1, height, width = 18 }) => {
  const titleId = useRandomId('IconMapMarker2__title');
  return (
    <svg
      aria-labelledby={titleId}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      height={height || 22}
      width={width}
    >
      <path
        fill={color}
        d="M21,7.8c-0.2-2-1.1-3.8-2.6-5.3C16.6,0.8,14.3,0,12,0C9.7,0,7.4,0.8,5.6,2.5C4.2,3.9,3.3,5.8,3,7.8
	c-0.2,2,0.3,4,1.4,5.6c0,0,0,0,0,0c0,0,0,0,0,0l7,9.7c0.1,0.2,0.3,0.3,0.5,0.3c0.2,0,0.4-0.1,0.5-0.3l7-9.7c0,0,0,0,0,0c0,0,0,0,0,0
	C20.7,11.8,21.2,9.8,21,7.8z M12,12.7c-2.1,0-3.8-1.7-3.8-3.8S9.9,5.2,12,5.2s3.8,1.7,3.8,3.8S14.1,12.7,12,12.7z"
      />
    </svg>
  );
};

IconMapMarker2.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default IconMapMarker2;
