import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FullWidthContainer from 'components/consumer/FullWidthContainer';
import DesktopNavShopByMenuItems from 'components/nav/DesktopHeader/components/DesktopNavShopByMenuItems';

const DesktopNavShopByMenu = ({
  closeNavbar,
  isOpen,
  setIsShopByMenuHovered,
}) => (
  <div
    hidden={isOpen ? undefined : true}
    className="relative"
    onMouseEnter={() => {
      setIsShopByMenuHovered(true);
    }}
    onMouseLeave={() => {
      setIsShopByMenuHovered(false);
    }}
  >
    <div
      className={classNames(
        'bg-white left-0 absolute right-0 top-0 transition-[opacity_.2s_ease-in] delay-200 w-full',
        {
          'opacity-0 pointer-events-none': !isOpen,
          'opacity-100 pointer-events-auto transition-[opacity_.2s_ease-in]': isOpen,
        }
      )}
      data-xc="DesktopNavShopByMenu"
    >
      <FullWidthContainer
        className={classNames(
          'flex justify-around pt-6 pr-0 pb-2 pl-8 transition-[opacity_.2s_ease-in] delay-200 w-full z-[1002]',
          {
            'transition-none': isOpen,
          }
        )}
      >
        <DesktopNavShopByMenuItems closeNavbar={closeNavbar} />
      </FullWidthContainer>
    </div>
  </div>
);

DesktopNavShopByMenu.propTypes = {
  closeNavbar: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsShopByMenuHovered: PropTypes.func,
};

export default DesktopNavShopByMenu;
