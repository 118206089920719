import { useMemo, useState } from 'react';
import { debounce } from 'lodash';

const INITIAL_STATE = {
  currentHoveredTitle: null,
  mostRecentHoveredTitle: null,
  isAnySectionHovered: false,
};

const useIsTitleOrSectionHovered = () => {
  // To support updating multiple parts of the data within a single render, we
  // need to use a shared state object for this data
  const [state, setState] = useState(INITIAL_STATE);

  const {
    currentHoveredTitle,
    mostRecentHoveredTitle,
    isAnySectionHovered,
  } = state;

  const setIsTitleHovered = useMemo(
    () =>
      debounce(
        (id, isHovered) => {
          if (isHovered) {
            setState(prevState => ({
              ...prevState,
              currentHoveredTitle: id,
              mostRecentHoveredTitle: id,
            }));
          } else {
            setState(prevState => ({
              ...prevState,
              currentHoveredTitle: null,
            }));
          }
        },
        150,
        {
          leading: false,
          trailing: true,
        }
      ),
    []
  );

  const setIsSectionHovered = isHovered => {
    if (isHovered) {
      setState(prevState => ({
        ...prevState,
        isAnySectionHovered: true,
      }));
    } else {
      setTimeout(() => {
        setState(prevState => ({
          ...prevState,
          isAnySectionHovered: false,
        }));
      }, 200);
    }
  };

  const selectedItem =
    currentHoveredTitle || isAnySectionHovered ? mostRecentHoveredTitle : null;

  const resetIsTitleOrSectionHovered = () => {
    setState(INITIAL_STATE);
  };

  return {
    resetIsTitleOrSectionHovered,
    selectedItem,
    setIsTitleHovered,
    setIsSectionHovered,
  };
};

export default useIsTitleOrSectionHovered;
