import React from 'react';
import PropTypes from 'prop-types';
import NotificationBannerSkeleton from './NotificationBannerSkeleton';

const GiftCard = ({ onClose }) => (
  <NotificationBannerSkeleton
    title="Your gift card was successfully added!"
    body={<span>We automatically credit your next purchase at checkout.</span>}
    onClose={onClose}
  />
);

GiftCard.propTypes = {
  onClose: PropTypes.func,
};

export default GiftCard;
