import { useAtomValue } from 'jotai';

import isLoginSidebarOpenAtom from './isLoginSidebarOpenAtom';

const useIsLoginSidebarOpen = () => {
  const isLoginSidebarOpenValue = useAtomValue(isLoginSidebarOpenAtom);
  return isLoginSidebarOpenValue;
};

export default useIsLoginSidebarOpen;
