import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CtaButton from 'components/essentials/Cta/CtaButton';
import { Link } from 'react-router-dom';
import NotificationBannerSkeleton from './NotificationBannerSkeleton';

const WelcomeBack = ({ firstName, wishListProduct, onClose }) => (
  <NotificationBannerSkeleton
    title={`Welcome back, ${firstName}`}
    body={
      <>
        <span>
          {wishListProduct
            ? `The ${wishListProduct || 'product'} has been`
            : 'We missed you!'}{' '}
        </span>
        <span className="[&_button]:p-0 [&_button]:ml-[5px]">
          <Link to={wishListProduct ? '/wishlist/' : '/customer/dashboard/'}>
            <CtaButton
              styling="link"
              color="brand"
              size="sm"
              hasArrow
              block
              noLinkPadding
            >
              {wishListProduct
                ? 'saved to your wishlist'
                : 'Go to your dashboard'}
            </CtaButton>
          </Link>
        </span>
      </>
    }
    onClose={onClose}
  />
);

WelcomeBack.propTypes = {
  firstName: PropTypes.string,
  wishListProduct: PropTypes.string,
  onClose: PropTypes.func,
};

export default WelcomeBack;
