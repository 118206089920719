import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import DesktopNavCategoryItem from '../DesktopNavCategoryItem';

const DesktopNavCategoriesUI = ({
  items = [],
  selectedCategoryId,
  setIsLinkHovered,
}) => {
  const containerRef = useRef(null);

  return (
    <ul
      className="items-center flex justify-between m-0 p-0"
      ref={containerRef}
    >
      {items.map(({ color, id, label, url }) => (
        <DesktopNavCategoryItem
          color={color}
          containerRef={containerRef}
          id={id}
          isOpen={id === selectedCategoryId}
          key={id}
          label={label}
          setIsLinkHovered={setIsLinkHovered}
          url={url}
        />
      ))}
    </ul>
  );
};

DesktopNavCategoriesUI.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  selectedCategoryId: PropTypes.string,
  setIsLinkHovered: PropTypes.func,
};

export default DesktopNavCategoriesUI;
