import { useEffect, useMemo } from 'react';

import useZipCodeFromUser from 'global-state/zipCodeFromUser/useZipCodeFromUser';
import useIsZipCodeServiceable from 'hooks/useIsZipCodeServiceable';
import useUserLocationFromZipcode from 'hooks/useUserLocationFromZipcode';
import useWarehouseIdForZipCode from 'hooks/useWarehouseIdForZipCode';
import useSetIsZipCodeFromUserServiceable from 'global-state/isZipCodeFromUserServiceable/useSetIsZipCodeFromUserServiceable';
import useSetZipCodeFromUserError from 'global-state/zipCodeFromUserError/useSetZipCodeFromUserError';

// This hook is used to get the user's location from the zip code that they have
// entered into one of the zip code fields in the Consumer app. The values are
// returned in a standard format to allow the useRefreshUserLocation hook to
// return the best available location for the user.
const useLocationForUserZipCode = () => {
  // Load zip code from user, from Jotai global state. This is the zip code that
  // the user has entered into one of the zip code fields in the app.
  const zipCodeFromUser = useZipCodeFromUser();

  // Validate if the user-specified zip code is servicable
  const {
    isZipCodeServiceable,
    nonServiceableZipCodeError,
    loading: isZipCodeServiceableLoading,
  } = useIsZipCodeServiceable(zipCodeFromUser);

  const setZipCodeFromUserError = useSetZipCodeFromUserError();

  // Update the global zipCodeFromUserError state
  useEffect(() => {
    setZipCodeFromUserError(nonServiceableZipCodeError ?? null);
  }, [nonServiceableZipCodeError, setZipCodeFromUserError]);

  const setIsZipCodeFromUserServiceable = useSetIsZipCodeFromUserServiceable();

  // Update the global isZipCodeFromUserServiceable state
  useEffect(() => {
    if (!isZipCodeServiceableLoading) {
      setIsZipCodeFromUserServiceable(isZipCodeServiceable);
    }
  }, [
    isZipCodeServiceable,
    isZipCodeServiceableLoading,
    setIsZipCodeFromUserServiceable,
  ]);

  // If zipcode is valid, get location data for this zipcode from API
  const {
    location: locationForUserZipCode,
    errorMessage: locationError,
    loading: isLocationLoading,
  } = useUserLocationFromZipcode(
    isZipCodeServiceable ? zipCodeFromUser : undefined
  );

  const {
    error: warehouseIdError,
    loading: isWarehouseIdLoading,
    warehouseId,
  } = useWarehouseIdForZipCode(
    isZipCodeServiceable ? zipCodeFromUser : undefined
  );

  // If user has specified a zip code that Joybird ships to, use that zip code
  const shouldUseLocation =
    !!zipCodeFromUser &&
    isZipCodeServiceable &&
    !locationError &&
    !warehouseIdError;

  const location = useMemo(() => {
    if (!shouldUseLocation) {
      return undefined;
    }

    const { city, country, state, stateShortName, zip } =
      locationForUserZipCode ?? {};

    return {
      city,
      country,
      state,
      stateShortName,
      zip,
    };
  }, [locationForUserZipCode, shouldUseLocation]);

  return {
    isLoading: zipCodeFromUser
      ? isLocationLoading || isWarehouseIdLoading
      : false,
    location: shouldUseLocation ? location : undefined,
    shouldUseLocation,
    warehouseId: shouldUseLocation ? warehouseId : undefined,
  };
};

export default useLocationForUserZipCode;
