import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import loadable from '@loadable/component';

import MobileNavbarLink from 'components/nav/MobileNavbarModal/components/MobileNavbarLink';
import IconArrow from 'components/icons/iconArrowRightSimple';
import useRandomId from 'hooks/useRandomId';

const AnimateHeight = loadable(() => import('react-animate-height'));
const MobileNavbarExpandableCategory = ({
  items = [],
  categoryLabel,
  categoryId,
  color,
  openCategoryId,
  setOpenCategoryId,
}) => {
  const isOpen = openCategoryId === categoryId;
  const elementId = useRandomId(`mobilenavbar-category-${categoryId}-`);

  return (
    <div className="MobileNavbarExpandableCategory">
      <button
        className="text-lg items-center [background:none] border-0 !text-gray flex justify-between px-5 py-3 text-left no-underline w-full hover:underline focus-visible:rounded-[3px] focus-visible:outline-offset-[-4px]"
        type="button"
        onClick={() => {
          if (isOpen) {
            setOpenCategoryId(null);
          } else {
            setOpenCategoryId(categoryId);
          }
        }}
        aria-label={`Toggle ${categoryLabel} category`}
        aria-controls={elementId}
        aria-expanded={isOpen}
      >
        <span
          className={classNames('MobileNavbarExpandableCategory__button-text', {
            'text-red': color === 'red',
          })}
        >
          {categoryLabel}
        </span>
        <IconArrow
          className={classNames('transition-[transform_.2s_ease]', {
            'rotate-90': isOpen,
          })}
          width="8"
          height="15"
        />
      </button>

      <AnimateHeight duration={200} height={isOpen ? 'auto' : 0} id={elementId}>
        <div className="MobileNavbarExpandableCategory__items">
          {items.map(({ id, label, to }) => (
            <MobileNavbarLink key={id} to={to} isNested>
              {label}
            </MobileNavbarLink>
          ))}
        </div>
      </AnimateHeight>
    </div>
  );
};

MobileNavbarExpandableCategory.propTypes = {
  color: PropTypes.oneOf(['gray', 'red']),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.node,
      to: PropTypes.string,
    })
  ),
  categoryId: PropTypes.string.isRequired,
  categoryLabel: PropTypes.string.isRequired,
  openCategoryId: PropTypes.string,
  setOpenCategoryId: PropTypes.func,
};

export default MobileNavbarExpandableCategory;
