import { useMemo } from 'react';

import useSuspenseQuery from 'commons/useSuspenseQuery';
import useIsBrowser from 'hooks/useIsBrowser';
import { GET_LOCATION_FROM_COORDS } from 'mocks/queries';
import getCityAndCountryFromAddress from './getCityAndCountryFromAddress';

const DEFAULT_VALUE = {
  location: undefined,
  error: undefined,
  isLoading: false,
};

const useUserLocationFromCoords = ({
  latitude: latitudeString,
  longitude: longitudeString,
} = {}) => {
  const isBrowser = useIsBrowser();

  const latitude = latitudeString
    ? parseFloat(parseFloat(latitudeString).toFixed(4))
    : undefined;
  const longitude = longitudeString
    ? parseFloat(parseFloat(longitudeString).toFixed(4))
    : undefined;

  const variables =
    !!latitude && !!longitude ? { latitude, longitude } : undefined;

  const { loading, data, error } = useSuspenseQuery(GET_LOCATION_FROM_COORDS, {
    variables,
    skip: !variables,
  });

  const isLoading = loading && !data;

  const locationResponse = data?.getLocationFromCoords;

  const location = useMemo(() => {
    if (!locationResponse) {
      return undefined;
    }

    const { address, state, stateShortName, zip } = locationResponse;

    const { city, country } = getCityAndCountryFromAddress(address);

    return {
      city,
      country,
      state,
      stateShortName,
      zip,
    };
  }, [locationResponse]);

  if (!isBrowser) {
    return DEFAULT_VALUE;
  }

  return {
    location,
    error,
    isLoading,
  };
};

export default useUserLocationFromCoords;
