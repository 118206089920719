import React from 'react';
import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import ProductGriditem from './ProductGridItem';
import GridLoading from './GridLoading';

const ProductsGrid = ({
  productsData,
  loading,
  maxCardsCount = 0,
  layout = '',
}) => {

  const placeholderCardsCount =
    maxCardsCount > productsData.length
      ? maxCardsCount - productsData.length
      : 0;

  if (loading) {
    return <GridLoading cardsCount={placeholderCardsCount} />;
  }
  return (
    <>
      {productsData.map((productItem, idx) => (
        <ProductGriditem
          key={`products-grid-item-${kebabCase(productItem.name)}`}
          productItem={productItem}
          idx={idx}
          layout={layout}
        />
      ))}
    </>
  );
};

ProductsGrid.propTypes = {
  productsData: PropTypes.arrayOf(
    PropTypes.shape({
      campaign: PropTypes.string,
      enableMobileCarousel: PropTypes.bool,
      filterStateKey: PropTypes.string,
      hidden: PropTypes.bool,
      id: PropTypes.number.isRequired,
      initialOptionId: PropTypes.string,
      isClearance: PropTypes.bool,
      layout: PropTypes.oneOf([
        'full',
        'mini',
        'minimal',
        'minimal-with-attributes',
        'minimal-with-showroom',
      ]),
      moreVariantsCount: PropTypes.number,
      name: PropTypes.string,
      onDisplayInShowroomSlug: PropTypes.string,
      path: PropTypes.string,
      position: PropTypes.number,
      productFamily: PropTypes.string,
      productLabel: PropTypes.string,
      productType: PropTypes.string,
      quickship: PropTypes.bool,
      secondaryImage: PropTypes.string,
      secondaryImageCropData: PropTypes.object,
      slug: PropTypes.string,
      variantsToShow: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          image: PropTypes.string,
          isOutOfStock: PropTypes.bool,
          label: PropTypes.string,
          minPrice: PropTypes.number,
          originalPrice: PropTypes.number,
          path: PropTypes.string.isRequired,
          price: PropTypes.number.isRequired,
          quickship: PropTypes.bool,
          sku: PropTypes.string,
        })
      ),
      variantsTotal: PropTypes.number,
    })
  ),
  loading: PropTypes.bool,
  layout: PropTypes.oneOf([
    'full',
    'mini',
    'minimal',
    'minimal-with-attributes',
    'minimal-with-showroom',
  ]),
  maxCardsCount: PropTypes.number,
};

export default ProductsGrid;
