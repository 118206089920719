import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import isServer from 'commons/isServer';
import { createPortal } from 'react-dom';

import withBrowserOnly from 'components/hoc/withBrowserOnly';
import Login from './login';
import SignUp from './signup';
import GiftCard from './GiftCard';
import BagSaved from './BagSaved';
import PasswordReset from './PasswordReset';
import ForgotPassword from './forgotPassword';
import TradeQuotesBanner from './TradeQuotesBanner';
import LivestreamNotification from './LivestreamNotification';
import UserOrdersProtectionPlanBanner from './UserOrdersProtectionPlan';
import UserOrdersSubmitReviewPhotoBanner from './UserOrdersSubmitReviewPhoto';
import FabricFavoritedBanner from './FabricFavoritedBanner';
import notificationBannerHelper, { useForceUpdate } from './utils';

const sidebarModalRootElement = !isServer()
  ? document.getElementById('SidebarModal-root')
  : null;

const SlideOut = ({ children, ...props }) => (
  <Transition {...props}>{children}</Transition>
);

SlideOut.propTypes = {
  children: PropTypes.node,
  modifier: PropTypes.string,
};

const NotificationBanner = () => {
  notificationBannerHelper.setUpdater(useForceUpdate());
  const { type, props } = notificationBannerHelper.getWelcomeMsgState();

  if (isServer()) {
    return null;
  }

  return createPortal(
    <>
      <SlideOut show={type === 'login'}>
        <Login {...props} />
      </SlideOut>

      <SlideOut show={type === 'signup'}>
        <SignUp {...props} />
      </SlideOut>

      <SlideOut show={type === 'forgotPassword'}>
        <ForgotPassword {...props} />
      </SlideOut>

      <SlideOut show={type === 'BagSaved'}>
        <BagSaved {...props} />
      </SlideOut>

      <SlideOut show={type === 'GiftCard'}>
        <GiftCard {...props} />
      </SlideOut>

      <SlideOut show={type === 'PasswordReset'}>
        <PasswordReset {...props} />
      </SlideOut>

      <SlideOut show={type === 'TradeQuotesBanner'}>
        <TradeQuotesBanner {...props} />
      </SlideOut>

      <SlideOut show={type === 'UserOrdersProtectionPlan'}>
        <UserOrdersProtectionPlanBanner {...props} />
      </SlideOut>

      <SlideOut show={type === 'UserOrdersSubmitReviewPhoto'}>
        <UserOrdersSubmitReviewPhotoBanner {...props} />
      </SlideOut>

      <SlideOut show={type === 'LivestreamNotification'}>
        <LivestreamNotification {...props} />
      </SlideOut>

      <SlideOut show={type === 'FabricFavoritedBanner'} modifier="top">
        <FabricFavoritedBanner {...props} />
      </SlideOut>
    </>,
    sidebarModalRootElement
  );
};

export default withBrowserOnly(NotificationBanner);
