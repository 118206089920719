import React from 'react';
import PropTypes from 'prop-types';

import FullWidthContainer from 'components/consumer/FullWidthContainer';
import LogoLink from 'components/consumer/LogoLink';
import HeaderToolbar from 'components/nav/shared/HeaderToolbar';
import DesktopNavCategories from '../DesktopNavCategories';

const DesktopPrimaryNav = ({ selectedCategoryId, setIsLinkHovered }) => (
  <FullWidthContainer
    className="items-center flex justify-between"
    data-xc="DesktopPrimaryNav"
    hasPadding
  >
    <LogoLink />

    <DesktopNavCategories
      selectedCategoryId={selectedCategoryId}
      setIsLinkHovered={setIsLinkHovered}
    />

    <HeaderToolbar />
  </FullWidthContainer>
);

DesktopPrimaryNav.propTypes = {
  selectedCategoryId: PropTypes.string,
  setIsLinkHovered: PropTypes.func,
};

export default DesktopPrimaryNav;
