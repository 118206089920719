/* eslint-disable no-unused-expressions */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import usePostHogFeatureFlag from 'hooks/usePostHogFeatureFlag';
import SSRSafeElement from 'commons/SSRSafeElement';
import Link from 'components/consumer/Link';
import useKeyboardControlForDropdown from 'hooks/useKeyboardControlForDropdown';
import IconInspirationUnderline from 'components/icons/IconInspirationUnderline';

const DesktopNavCategoryItem = ({
  containerRef,
  color = 'content',
  id,
  label,
  isOpen,
  setIsLinkHovered,
  url,
}) => {
  const toggleRef = useRef(null);
  const hideSaleDropDown = usePostHogFeatureFlag(
    'show-sale-dropdown-menu-item'
  );
  const handleOptionOpenHoverAndClick = () => {
    if (id === 'sale') {
      if (hideSaleDropDown) {
        setIsLinkHovered(id, true);
      }
    } else {
      setIsLinkHovered(id, true);
    }
  };

  useKeyboardControlForDropdown({
    isOpen,
    // Since the popup container is in a separate component, while not ideal it
    // is simplest to access this DOM element using vanilla JS
    isParentPopup: true,
    getPopupContainerElement: () =>
      document.getElementById(`DesktopNavCategory--${id}`),
    toggleContainerRef: containerRef,
    toggleRef,
    open: () => {
      handleOptionOpenHoverAndClick();
    },
    close: () => {
      setIsLinkHovered(id, false);
    },
  });

  const isColorRed = color === 'red';

  return (
    <li key={id} className="list-none">
      <Link
        to={url}
        className={classNames(
          'group/DesktopNavCategoryItem-link text-sm font-bold inline-block min-h-[55px] px-2 py-0 relative no-underline',
          'xl:text-base xl:font-bold',
          'focus-visible:outline-offset-[-2px]'
        )}
        onMouseEnter={() => {
          handleOptionOpenHoverAndClick();
        }}
        onMouseLeave={() => {
          setIsLinkHovered(id, false);
        }}
        data-xc={id}
        ref={toggleRef}
      >
        <span
          className={classNames(
            'items-center [border-bottom:1px_solid_#fff] inline-flex min-h-[55px] text-center',
            {
              // Color === content
              'text-gray': !isColorRed,
              '[border-bottom:1px_solid_#107c8c]': isOpen && !isColorRed,
              'group-focus-visible/DesktopNavCategoryItem-link:[border-bottom:1px_solid_#107c8c] group-focus-visible/DesktopNavCategoryItem-link:text-brand': !isColorRed,
              'group-hover/DesktopNavCategoryItem-link:[border-bottom:1px_solid_#107c8c] group-hover/DesktopNavCategoryItem-link:text-brand': !isColorRed,
              /// Color === red
              'text-red': isColorRed,
              '[border-bottom:1px_solid_#b85455]': isOpen && isColorRed,
              'group-focus-visible/DesktopNavCategoryItem-link:[border-bottom:1px_solid_#b85455] group-focus-visible/DesktopNavCategoryItem-link:text-red': isColorRed,
              'group-hover/DesktopNavCategoryItem-link:[border-bottom:1px_solid_#b85455] group-hover/DesktopNavCategoryItem-link:text-red': isColorRed,
            }
          )}
        >
          {label}
        </span>

        {id === 'inspiration' && (
          <IconInspirationUnderline className="left-[7px] absolute top-10 w-[72px]" />
        )}
      </Link>
    </li>
  );
};

DesktopNavCategoryItem.propTypes = {
  color: PropTypes.oneOf(['content', 'red']),
  containerRef: PropTypes.shape({
    current: PropTypes.instanceOf(SSRSafeElement),
  }),
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  url: PropTypes.string,
  setIsLinkHovered: PropTypes.func,
};

export default DesktopNavCategoryItem;
