import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import StatusLabel from 'components/consumer/StatusLabel';

const ProductCardLabelUI = ({ label, color = 'gray', variant }) => {
  if (!label) {
    return null;
  }

  return (
    <div
      className={classNames('mt-3 lg:m-0 group-hover/product-card:invisible', {
        '!mt-0': variant === 'order-info',
      })}
    >
      <StatusLabel
        className={classNames(
          'left-2 m-0 lg:pointer-events-none absolute top-2',
          {
            '!right-5 !left-[unset]': variant === 'order-info',
            '!text-xs !text-gray-dark !bg-yellow-light':
              variant === 'build-updates-done',
          }
        )}
        color={color}
      >
        {label}
      </StatusLabel>
    </div>
  );
};

ProductCardLabelUI.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['build-updates-done', 'order-info']),
};

export default ProductCardLabelUI;
