import { useEffect, useState } from 'react';

import { normalize } from 'lib/utils';
import useProductBasicDetails from 'data-hooks/useProductBasicDetails';

const useProductDimensions = (productId, slug, skip = false) => {
  const [dimensions, setDimensions] = useState(null);
  const { data } = useProductBasicDetails(
    productId,
    slug,
    {},
    {
      skip,
    }
  );
  const { attributes: attributesArray } = data ?? {};

  useEffect(() => {
    if (skip) {
      return;
    }

    const attributes = normalize({
      data: attributesArray,
      key: 'code',
    }).data;

    const dimensionsArray = [];

    if (attributes?.width?.value) {
      dimensionsArray.push(`${attributes.width.value}"w`);
    }

    if (attributes?.depth?.value) {
      dimensionsArray.push(` x ${attributes.depth.value}"d`);
    }

    if (attributes?.height?.value) {
      dimensionsArray.push(` x ${attributes.height.value}"h`);
    }

    const updatedDimensions = dimensionsArray.join('  ');

    setDimensions(updatedDimensions);
  }, [attributesArray, skip]);

  return dimensions;
};

export default useProductDimensions;
