const mobileStaticNavItems = [
  {
    id: 'categories',
    links: [
      {
        label: 'Living Room',
        id: 'living-room',
        links: [
          {
            label: 'All Living Room',
            id: 'all-living-room',
            to: '/living-room-furniture/',
          },
          {
            label: 'Sofas & Sectionals',
            id: 'sofas-and-sectionals',
            to: '/sofas-and-sectionals/',
          },
          {
            label: 'Chairs',
            id: 'chairs',
            to: '/chairs/',
          },
          {
            label: 'Tables',
            id: 'tables',
            to: '/all-tables/',
          },
          {
            label: 'Storage',
            id: 'storage',
            to: '/all-storage/',
          },
          {
            label: 'New Arrivals',
            id: 'new-arrivals',
            to: '/living-room-furniture/?sortBy=newest',
          },
        ],
      },
      {
        label: 'Dining Room',
        id: 'dining-room',
        links: [
          {
            label: 'All Dining Room',
            id: 'all-dining-room',
            to: '/dining-room-furniture/',
          },
          {
            label: 'Dining Tables',
            id: 'dining-tables',
            to: '/dining-tables/',
          },
          {
            label: 'Dining Chairs',
            id: 'dining-chairs',
            to: '/dining-chairs/',
          },
          {
            label: 'Bar Carts & Cabinets',
            id: 'bar-carts-and-cabinets',
            to: '/bar-carts-cabinets/',
          },
          {
            label: 'New Arrivals',
            id: 'new-arrivals',
            to: '/dining-room-furniture/?sortBy=newest',
          },
        ],
      },
      {
        label: 'Bedroom',
        id: 'bedroom',
        links: [
          {
            label: 'All Bedroom',
            id: 'all-bedroom',
            to: '/bedroom/',
          },
          {
            label: 'Beds',
            id: 'beds',
            to: '/beds/',
          },
          {
            label: 'Nightstands',
            id: 'nightstands',
            to: '/nightstands/',
          },
          {
            label: 'Dressers & Chests',
            id: 'dressers-chests',
            to: '/dressers-chests/',
          },
          {
            label: 'New Arrivals',
            id: 'new-arrivals',
            to: '/bedroom/?sortBy=newest',
          },
        ],
      },
      {
        label: 'Home Office',
        id: 'home-office',
        links: [
          {
            label: 'All Home Office',
            id: 'all-home-office',
            to: '/home-office-furniture/',
          },
          {
            label: 'Desks',
            id: 'desks',
            to: '/desks/',
          },
          {
            label: 'Bookcases',
            id: 'bookcases',
            to: '/bookcases-wall-units/',
          },
          {
            label: 'Office Chairs',
            id: 'office-chairs',
            to: '/office-chairs/',
          },
          {
            label: 'New Arrivals',
            id: 'new-arrivals',
            to: '/home-office-furniture/?sortBy=newest',
          },
        ],
      },
      {
        label: 'Outdoor',
        id: 'outdoor',
        links: [
          {
            label: 'All Outdoor',
            id: 'all-outdoor',
            to: '/outdoor-furniture/',
          },
          {
            label: 'Outdoor Sofas',
            id: 'outdoor-sofas',
            to: '/outdoor-sofas/',
          },
          {
            label: 'Outdoor Chairs',
            id: 'outdoor-chairs',
            to: '/outdoor-chairs/',
          },
          {
            label: 'Outdoor Tables',
            id: 'outdoor-tables',
            to: '/outdoor-tables/',
          },
          {
            label: 'Bar Carts',
            id: 'bar-carts',
            to: '/bar-carts-cabinets/',
          },
          {
            label: 'Outdoor Rugs',
            id: 'outdoor-rugs',
            to: '/rugs/?category=outdoor%20rugs',
          },
          {
            label: 'Outdoor Lookbook',
            id: 'outdoor-lookbook',
            to: '/lookbooks/outdoor-2024',
          },
          {
            label: 'New Arrivals',
            id: 'new-arrivals',
            to: '/outdoor-furniture/?sortBy=newest',
          },
        ],
      },
      {
        label: 'Decor',
        id: 'decor',
        links: [
          {
            label: 'All Decor',
            id: 'all-decor',
            to: '/decoration-accessories/',
          },
          {
            label: 'Rugs',
            id: 'rugs',
            to: '/rugs/',
          },
          {
            label: 'Pillows',
            id: 'pillows',
            to: '/pillows/',
          },
          {
            label: 'Poufs & Cubes',
            id: 'poufs-and-cubes',
            to: '/poufs/',
          },
          {
            label: 'Throws',
            id: 'throws',
            to: '/throws/',
          },
          {
            label: 'Pet Beds',
            id: 'pet-beds',
            to: '/pet-beds/',
          },
          {
            label: 'Lighting',
            id: 'lighting',
            to: '/all-lighting/',
          },
          {
            label: 'Wall Decor',
            id: 'wall-decor',
            to: '/wall-decor/',
          },
          {
            label: 'Wallpaper',
            id: 'wallpaper',
            to: '/wallpaper/',
          },
          {
            label: 'Planters',
            id: 'plants-and-planters',
            to: '/plants-and-planters/',
          },
          {
            label: 'Mirrors',
            id: 'mirrors',
            to: '/mirrors/',
          },
          {
            label: 'Tableware',
            id: 'tableware',
            to: '/tableware/',
          },
          {
            label: 'New Arrivals',
            id: 'new-arrivals',
            to: '/decoration-accessories/?sortBy=newest',
          },
        ],
      },
      {
        color: 'red',
        label: 'Sale',
        id: 'sale',
        links: [],
      },
      {
        label: 'New Arrivals',
        id: 'new-arrivals',
        to: '/new-arrivals/?sortBy=newest',
      },
      {
        label: 'Ready to Ship',
        id: 'quick-ship',
        to: '/instock/',
      },
    ],
  },
  {
    id: 'design-services-group',
    links: [
      {
        label: 'Customer Photos',
        id: 'customer-photos',
        to: '/customer-photos/',
        subLabel: 'Photos from real Joybird customers!',
        images: [
          {
            name: 'Home Photo 1',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-photo-1.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Photo 2',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-photo-2.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Photo 3',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-photo-3.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Photo 4',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-photo-4.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Photo 5',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-photo-5.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
        ],
      },
      {
        label: 'Materials & Swatches',
        id: 'virtual-swatch-kit',
        to: '/swatches/',
        subLabel:
          'Colors, textures and materials - the possibilities are endless.',
        images: [
          {
            name: 'Home Swatch 1',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-swatch-1.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Swatch 2',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-swatch-2.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Swatch 3',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-swatch-3.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Swatch 4',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-swatch-4.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Swatch 5',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-swatch-5.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
          {
            name: 'Home Swatch 6',
            src:
              'https://joybird2.imgix.net/static-v1/consumer-header/home-swatch-6.png?auto=format%2Ccompress&q=50&fit=crop&crop=entropy&cs=srgb',
          },
        ],
      },
      {
        label: 'Free Design Services',
        id: 'free-design-services',
        to: '/free-design-services/',
        subLabel: 'Free design tools, inspiration, and more!',
        backgroundImage: {
          name: 'Free design services image',
          src:
            'https://joybird2.imgix.net/user-uploads-staging/design-services-mobile-1581625113428.jpeg',
          imgProps: {
            height: '100',
            width: '260',
            fit: 'crop',
            crop: 'focalpoint',
            fpX: '0.5',
            fpY: '0.5',
            fpZoom: '1',
          },
        },
      },
      {
        label: 'Small Spaces',
        id: 'small-spaces',
        to: '/small-spaces/',
      },
      {
        label: 'Lookbooks',
        id: 'lookbooks',
        to: '/lookbooks/',
      },
      {
        label: 'Fabric of the Month',
        id: 'fabric-of-the-month',
        to: '/fabric-of-the-month/',
      },
      {
        label: 'Partnerships & Collaborations',
        id: 'collaborations',
        links: [
          {
            label: 'Barbie™x Joybird',
            id: 'barbie-joybird',
            to: '/collaborations/barbie/',
          },
          {
            label: 'Bari J',
            id: 'bari-j',
            to: '/collaborations/barij/',
          },
          {
            label: 'Bon Femmes',
            id: 'bon-femmes',
            to: '/bon-femmes/',
          },
          {
            label: 'All Collaborations',
            id: 'all-collaborations',
            to: '/collaborations/',
          },
        ],
      },
      {
        label: 'Showrooms',
        id: 'showrooms',
        links: [
          {
            label: 'Virtual Showroom',
            id: 'virtual-showroom',
            to: '/showrooms/virtual/',
          },
          {
            label: 'Austin, TX',
            id: 'austin-tx',
            to: '/showrooms/austin/',
          },
          {
            label: 'Brooklyn, NY',
            id: 'brooklyn-ny',
            to: '/showrooms/brooklyn/',
          },
          {
            label: 'Chicago, IL',
            id: 'chicago-il',
            to: '/showrooms/chicago/',
          },
          {
            label: 'Denver, Co',
            id: 'denver-co',
            to: '/showrooms/denver/',
          },
          {
            label: 'Los Angeles, CA',
            id: 'los-angeles-ca',
            to: '/showrooms/dtla/',
          },
          {
            label: 'West Hollywood, CA',
            id: 'west-hollywood-ca',
            to: '/showrooms/los-angeles/',
          },
          {
            label: 'Manhattan, NY',
            id: 'manhattan-ny',
            to: '/showrooms/manhattan/',
          },
          {
            label: 'Philadelphia, PA',
            id: 'philadelphia-pa',
            to: '/showrooms/philadelphia/',
          },
          {
            label: 'Portland, OR',
            id: 'portland-or',
            to: '/showrooms/portland/',
          },
          {
            label: 'San Francisco, CA',
            id: 'san-francisco-ca',
            to: '/showrooms/san-francisco/',
          },
          {
            label: 'Seattle, WA',
            id: 'seattle-wa',
            to: '/showrooms/seattle/',
          },
          {
            label: 'Washington DC',
            id: 'washington-dc',
            to: '/showrooms/dc/',
          },
          {
            label: 'All Showrooms',
            id: 'all-showrooms',
            to: '/showrooms/',
          },
        ],
      },
      {
        label: 'Blog',
        id: 'blog',
        to: '/blog/',
      },
      {
        label: 'Collections',
        id: 'collections',
        to: '/collections/',
      },
    ],
  },
  {
    id: 'group-2',
    links: [
      {
        label: 'Resources',
        id: 'resources',
        links: [
          {
            label: 'Warranty',
            id: 'warranty',
            to: '/warranty/',
          },
          {
            label: 'Shipping',
            id: 'shipping',
            to: '/shipping-and-delivery/',
          },
          {
            label: 'Returns',
            id: 'returns',
            to: '/returns/',
          },
          {
            label: 'Product Care',
            id: 'product-care',
            to: '/product-care/',
          },
          {
            label: 'Fit Guide',
            id: 'fit-guide',
            to: '/fit-guide/',
          },
          {
            label: 'FAQ',
            id: 'faq',
            to: 'https://help.joybird.com/',
          },
          {
            label: 'Financing',
            id: 'financing',
            to: '/product-financing/',
          },
          {
            label: 'Product Safety',
            id: 'product-safety',
            to: '/product-safety/',
          },
          {
            label: 'Free Fabric Samples',
            id: 'free-fabric-samples',
            to: '/samples/',
          },
        ],
      },
      {
        label: 'About',
        id: 'about',
        links: [
          {
            label: 'Story',
            id: 'story',
            to: '/about-us/',
          },
          {
            label: 'Workshop',
            id: 'workshop',
            to: '/the-factory/',
          },
          {
            label: 'Reviews',
            id: 'reviews',
            to: '/reviews/',
          },
          {
            label: 'Sustainability',
            id: 'Sustainability',
            to: '/sustainability/',
          },
          {
            label: 'Press',
            id: 'press',
            to: '/press/',
          },
          {
            label: 'Careers',
            id: 'careers',
            to: '/careers/',
          },
        ],
      },
      {
        label: 'Support',
        id: 'support',
        links: [
          {
            label: 'Track Your Order',
            id: 'track-your-order',
            to: '/customer/orders/',
          },
          {
            label: 'Contact Us',
            id: 'contact-us',
            to: 'https://joybird.com/contact-us/',
          },
          {
            label: 'Trade & Business',
            id: 'trade-and-business',
            to: '/business/',
          },
        ],
      },
    ],
  },
  {
    id: 'login',
    links: [
      {
        label: 'Login',
        id: 'login',
        to: '/customer/dashboard/',
      },
    ],
  },
];

export default mobileStaticNavItems;
