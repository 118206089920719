import React from 'react';

import ImpersonateHeader from 'components/nav/shared/ImpersonateHeader';
import DesktopHeader from 'components/nav/DesktopHeader';
import CommonModals from 'components/nav/shared/CommonModals';
import DesktopOnly from 'components/consumer/DesktopOnly';
import MobileAndTabletOnly from 'components/consumer/MobileAndTabletOnly';
import SuspenseWithLoader from 'components/consumer/SuspenseWithLoader';
import ConsumerHeaderMobileWrapper from './ConsumerHeaderMobileWrapper';

const ConsumerHeader = () => (
  <>
    <ImpersonateHeader />

    <SuspenseWithLoader>
      <CommonModals />
    </SuspenseWithLoader>

    <DesktopOnly
      fallback={
        <>
          <div className="DesktopHeader__top-section-filler bg-white relative z-[1003] max-[1023px]:hidden DesktopHeader__section--top-section" />
          <div className="DesktopHeader__primary-nav-section-filler bg-white z-[1002] max-[1023px]:hidden sticky top-0" />
        </>
      }
    >
      <DesktopHeader />
    </DesktopOnly>

    <MobileAndTabletOnly
      fallback={
        <>
          <div className="ConsumerHeader__filler" />
          <div className="ConsumerHeader__skip-to-filler" />
        </>
      }
    >
      <ConsumerHeaderMobileWrapper />
    </MobileAndTabletOnly>
  </>
);

export default ConsumerHeader;
