import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import {
  setItemInStorage,
  getItemFromStorage,
  removeItemInStorage,
} from 'commons/localStorage';
import { LOGOUT } from 'mocks/mutations';
import useUserData from 'data-hooks/useUserData';
import useLogoutClient from 'data-hooks/useUserData/useLogoutClient';

const IMPERSONATE_SESSION_COUNTER = 'impersonate-session-counter';

const ImpersonateHeader = () => {
  const [logout] = useMutation(LOGOUT);
  const logoutClient = useLogoutClient();
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    let time = getItemFromStorage(IMPERSONATE_SESSION_COUNTER);
    if (!time) {
      time = Date.now();
      setItemInStorage(IMPERSONATE_SESSION_COUNTER, time);
    }

    const intervalId = setInterval(async () => {
      // subtract 10 * 60 to countdown
      const diff = time ? 600 - (Date.now() - time) / 1000 : 0;
      if (diff <= 0) {
        await logout();
        logoutClient();
        removeItemInStorage(IMPERSONATE_SESSION_COUNTER);
        window.location.href = '/sign-in';
        return;
      }

      const secs = Math.floor(diff % 60);
      const mins = Math.floor((diff / 60) % 60);
      setTimeLeft(
        `${mins > 0 ? `${mins} min${mins !== 1 ? 's' : ''}` : ''} ${secs} secs`
      );
    }, 1000);

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  });

  return (
    <div className="w-full py-2 px-0 flex justify-center items-center text-base font-bold text-white bg-red">
      Impersonate Session ends in {timeLeft}
    </div>
  );
};

const ImpersonateHeaderContainer = () => {
  const { isImpersonatingUser } = useUserData();
  return isImpersonatingUser && <ImpersonateHeader />;
};

export default ImpersonateHeaderContainer;
