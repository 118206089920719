import { useSetAtom } from 'jotai';

import zipCodeFromUserErrorAtom from './zipCodeFromUserErrorAtom';

const useSetZipCodeFromUserError = () => {
  const setZipCodeFromUserError = useSetAtom(zipCodeFromUserErrorAtom);

  return setZipCodeFromUserError;
};

export default useSetZipCodeFromUserError;
