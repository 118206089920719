import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const RunHook = ({
  hook: useThisHook = () => {},
  hookArguments = [],
  isResultReady = () => true,
  reject = () => {},
  resolve = () => {},
  timeout = 15000,
}) => {
  const [isResultComplete, setIsResultComplete] = useState(false);
  const result = useThisHook(...hookArguments);

  useEffect(() => {
    setIsResultComplete(prevIsResultComplete => {
      if (!prevIsResultComplete && isResultReady?.(result)) {
        resolve(result);
        return true;
      }

      return prevIsResultComplete;
    });
  }, [isResultReady, resolve, result]);

  useEffect(() => {
    const hookTimeout = setTimeout(() => {
      if (!isResultComplete) {
        reject(result);
        setIsResultComplete(true);
      }
    }, timeout);

    return () => {
      clearTimeout(hookTimeout);
    };
    // eslint-disable-next-line
  }, []);

  return null;
};

RunHook.propTypes = {
  hook: PropTypes.func,
  timeout: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  hookArguments: PropTypes.arrayOf(PropTypes.any),
  resolve: PropTypes.func,
  reject: PropTypes.func,
  isResultReady: PropTypes.func,
};

export default RunHook;
