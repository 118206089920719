import React from 'react';
import classNames from 'classnames';

import FavoriteViewModal from 'components/consumer/FavoriteViewModal';
import InstantSearch from 'components/consumer/consumer-search/instant-search';
import TradeUserSidebar from 'components/consumer/TradeUserSidebar/TradeUserSidebar';
import LoginSidebar from 'components/consumer/LoginSidebar/LoginSidebar';
import NotificationBanner from 'components/consumer/NotificationBanner';
import { noEmptySearchClient } from 'components/essentials/AlgoliaSearchClient';
import useScreen from 'hooks/useScreen';
import ChatUnavailableModal from 'components/consumer/ChatUnavailableModal';
import QuickViewModal from 'components/consumer/QuickViewModal';
import HookRunner from 'components/consumer/HookRunner';
import useIsSearchOpen from 'global-state/isSearchOpen/useIsSearchOpen';
import useToggleIsLoginSidebarOpen from 'global-state/isLoginSidebarOpen/useToggleIsLoginSidebarOpen';
import useIsLoginSidebarOpen from 'global-state/isLoginSidebarOpen/useIsLoginSidebarOpen';
import useFavoriteModalState from 'global-state/favoriteModalState/useFavoriteModalState';
import useResetFavoriteModalState from 'global-state/favoriteModalState/useResetFavoriteModalState';
import withBrowserOnly from 'components/hoc/withBrowserOnly';
import useRefreshUserLocation from 'hooks/useRefreshUserLocation';
import useChatUnavailableModalState from './useChatUnavailableModalState';
import useQuickViewModalState from './useQuickViewModalState';

// The CommonModals component is used to render modals that need to be globally
// available on all pages.

// Since it is rendered on all pages (and only rendered once), it is also very
// useful to include any global hooks that need to be run on all pages. Ideally
// in future we should extract this to a separate component, named GlobalHooks.

const CommonModals = () => {
  // This chat modal hook also makes the chat open & close functions globally
  // available on the window object so that chat can be opened and close the
  // root App component in Consumer
  const {
    isOpen: isChatUnavailableOpen,
    close: closeChatUnavailableModal,
  } = useChatUnavailableModalState();

  const {
    isOpen: isQuickViewModalOpen,
    onClose: onCloseQuickViewModal,
    path: quickViewModalPath,
    slug: quickViewModalSlug,
    productVariation: quickViewProductVariation,
  } = useQuickViewModalState();

  const { isDesktop } = useScreen();

  const isSearchOpen = useIsSearchOpen();

  const isLoginSidebarOpen = useIsLoginSidebarOpen();
  const toggleIsLoginSidebarOpen = useToggleIsLoginSidebarOpen();

  const favoriteModalState = useFavoriteModalState();
  const resetFavoriteModalState = useResetFavoriteModalState();

  // Render any additional hooks that need to be run on all pages
  useRefreshUserLocation();

  const showQuickViewModal = !!isQuickViewModalOpen && !!quickViewModalSlug;

  return (
    <>
      {showQuickViewModal && (
        <QuickViewModal
          isOpen
          onClose={onCloseQuickViewModal}
          path={quickViewModalPath}
          slug={quickViewModalSlug}
          variantSku={quickViewProductVariation}
        />
      )}

      {/* The HookRunner component is used to allow data to be loaded outside of React, which is necessary to support loading of product data within the QuickViewModal component */}
      <HookRunner />

      {/* top-search */}
      {!isDesktop ? (
        <div
          className={classNames(
            'top-[-95px] fixed left-0 z-[1002] w-full lg:h-[95px] lg:bg-gray-light8 lg:[border-bottom:1px_solid_#f1f1f1] lg:[border-top:1px_solid_#f1f1f1] lg:[transition:top_.25s_ease-in-out] max-[1023px]:absolute max-[1023px]:z-[21] max-[1023px]:top-0',
            '[&_.infinite-scroll-component]:!overflow-hidden',
            '[&_.ais-SearchBox-input]:lg:h-20',
            {
              'lg:top-[-95px] lg:z-[1001]': !isSearchOpen,
              'lg:!top-0 lg:!z-[9999]': isSearchOpen,
            }
          )}
        >
          <InstantSearch
            autoFocus={false}
            isActive={isSearchOpen}
            isTopSearch
            searchClient={noEmptySearchClient}
          />
        </div>
      ) : (
        <div
          className={classNames(
            'top-[-95px] fixed left-0 z-[1002] w-full lg:h-[95px] lg:bg-gray-light8 lg:[border-bottom:1px_solid_#f1f1f1] lg:[border-top:1px_solid_#f1f1f1] lg:[transition:top_.25s_ease-in-out] max-[1023px]:absolute max-[1023px]:z-[21] max-[1023px]:top-0',
            '[&_.infinite-scroll-component]:!overflow-hidden',
            '[&_.ais-SearchBox-input]:lg:h-20',
            {
              'lg:top-[-95px] lg:z-[1001]': !isSearchOpen,
              'lg:top-0 lg:z-[9999]': isSearchOpen,
            }
          )}
        >
          {isSearchOpen && (
            <InstantSearch
              autoFocus
              isActive={isSearchOpen}
              isTopSearch
              searchClient={noEmptySearchClient}
              showOverlay
            />
          )}
        </div>
      )}

      <TradeUserSidebar />

      <FavoriteViewModal
        isSideBarVisible={favoriteModalState.isOpen}
        productId={favoriteModalState.productId}
        onClose={resetFavoriteModalState}
      />

      <LoginSidebar
        show={isLoginSidebarOpen}
        onClose={toggleIsLoginSidebarOpen}
      />

      <NotificationBanner />

      <ChatUnavailableModal
        isOpen={isChatUnavailableOpen}
        onClose={closeChatUnavailableModal}
      />
    </>
  );
};

export default withBrowserOnly(CommonModals);
