import { useAtomValue } from 'jotai';

import favoriteModalStateAtom from './favoriteModalStateAtom';

const useFavoriteModalState = () => {
  const favoriteModalStateValue = useAtomValue(favoriteModalStateAtom);
  return favoriteModalStateValue;
};

export default useFavoriteModalState;
