import loadable from '@loadable/component';

import isNewConsumerApp from 'commons/isNewConsumerApp';

// In new Consumer app, we are using a newer version of Nuka Carousel, which
// uses a named export rather than a default export.
const NukaCarousel = isNewConsumerApp
  ? loadable(() => import('nuka-carousel').then(mod => mod.Carousel))
  : loadable(() => import('nuka-carousel'));

export default NukaCarousel;
