import React from 'react';
import PropTypes from 'prop-types';

import useIsTitleOrSectionHovered from 'hooks/useIsTitleOrSectionHovered';
import useDesktopNavItems from 'components/nav/shared/data/useDesktopNavItems';
import DesktopNavCategorySectionUI from './DesktopNavCategorySectionUI';

const DesktopNavCategorySection = ({ categoryId, isOpen }) => {
  const categoryNavItems = useDesktopNavItems();
  const selectedCategory =
    categoryNavItems.find(item => item.id === categoryId) || null;

  const {
    selectedItem: selectedSubcategory,
    setIsTitleHovered,
    setIsSectionHovered,
  } = useIsTitleOrSectionHovered();

  if (selectedCategory) {
    return (
      <DesktopNavCategorySectionUI
        id={`DesktopNavCategory--${selectedCategory.id}`}
        items={selectedCategory.items}
        secondaryItems={selectedCategory.secondaryItems}
        ctaLabel={selectedCategory.ctaLabel}
        ctaUrl={selectedCategory.ctaUrl || selectedCategory.url}
        image={selectedCategory.image}
        isOpen={isOpen}
        selectedSubcategory={selectedSubcategory}
        setIsSectionHovered={setIsSectionHovered}
        setIsTitleHovered={setIsTitleHovered}
        customContent={selectedCategory.customContent}
      />
    );
  }

  return null;
};

DesktopNavCategorySection.propTypes = {
  categoryId: PropTypes.string,
  isOpen: PropTypes.bool,
};

export default DesktopNavCategorySection;
