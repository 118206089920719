import { useSetAtom } from 'jotai';

import warehouseIdForUserAtom from './warehouseIdForUserAtom';

const useSetWarehouseIdForUser = () => {
  const setWarehouseIdForUser = useSetAtom(warehouseIdForUserAtom);

  return setWarehouseIdForUser;
};

export default useSetWarehouseIdForUser;
