import React, { Component } from 'react';
import { isEqual } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import JBImage from 'components/essentials/JBImage';
import {
  productCardImageSize,
  WOOD_PRODUCT_OPTION_ID,
  FRAME_PRODUCT_OPTION_ID,
} from 'commons/constants';

class ProductCardImage extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  getImageWidthFromSize() {
    if (!this.props.imageSize) {
      const layoutSizes = {
        minimal: 265,
        thumbnail: 38,
      };

      return layoutSizes[this.props.layout] ?? 368;
    }
    const productCardSizes = {
      [productCardImageSize.small]: 265,
      [productCardImageSize.medium]: 368,
      [productCardImageSize.large]: 600,
      [productCardImageSize.modular]: 100, // Special case for modular sofas display card
      [productCardImageSize.thumb]: 36,
    };

    return productCardSizes[this.props.imageSize] ?? 368;
  }

  render() {
    const {
      altText,
      customImg,
      displayOptionImages = false,
      firstPopularImage = '',
      hasOrientationRight = false,
      image,
      imageClassName = '',
      imageLoaded,
      imageSize = '',
      layout,
      listName,
      productCategory,
      productImageOptionTypeId,
      productImages,
      setImageLoaded = () => {},
      setLazy = false,
      trimCustomImage = false,
      isModularSofasPage = false,
      isFlipped = false,
    } = this.props;

    const finalExcludeList =
      layout === 'clearance'
        ? [FRAME_PRODUCT_OPTION_ID]
        : [WOOD_PRODUCT_OPTION_ID, FRAME_PRODUCT_OPTION_ID];

    let src =
      productImages?.transparent_config_image ||
      productImages?.transparent_hero_image_ts
        ? ((!!productImageOptionTypeId &&
            !finalExcludeList.includes(productImageOptionTypeId)) ||
            productCategory === 'fabrics') &&
          !displayOptionImages
          ? productImages.transparent_config_image
          : productImages.transparent_hero_image_ts
        : firstPopularImage || image;

    const widthSize = this.getImageWidthFromSize();

    const commonImageProps = {
      className: classNames('object-contain', {
        'm-5': !isModularSofasPage,
        'opacity-100': imageLoaded,
        'flipped-right': hasOrientationRight,
        'max-w-[85%] max-h-[47.5%]': layout !== 'minimal' || !!listName,
        'max-h-full max-w-full': layout === 'minimal' && !listName,
      }),
      trim: 'color',
      width: widthSize,
      onLoad: () => {
        setImageLoaded(true, src);
      },
      trimPad: 20,
      lazy:
        (imageSize && !setLazy) || ['thumb', 'modular'].includes(imageSize)
          ? false
          : setLazy
          ? true
          : undefined,
      alt: altText,
    };

    if (customImg) {
      const isImgix = customImg.includes('imgix');

      const commonProps = {
        className: classNames(`object-contain ${imageClassName}`, {
          'm-5': !isModularSofasPage,
          'opacity-100': imageLoaded,
          'max-w-[85%] max-h-[47.5%]': layout !== 'minimal' || !!listName,
          'max-h-full max-w-full': layout === 'minimal' && !listName,
          'scale-x-[-1] ': isFlipped,
        }),
        onLoad: () => setImageLoaded(true),
        width: this.getImageWidthFromSize(),
        src: customImg,
      };

      const imgixProps = {
        trim: trimCustomImage ? 'color' : undefined,
      };

      const finalProps = isImgix
        ? { ...commonProps, ...imgixProps }
        : commonProps;

      const CustomImgComponent = isImgix
        ? JBImage
        : props => <img {...props} alt="Custom" />;

      return <CustomImgComponent {...finalProps} />;
    }

    if (this.props.forceFallback) {
      src = src || image;
    }

    if (!src) {
      return null;
    }

    return (
      <JBImage
        {...commonImageProps}
        src={src}
        loader={false}
        fit="clip"
        width={widthSize}
        height={widthSize}
      />
    );
  }
}

ProductCardImage.propTypes = {
  altText: PropTypes.string,
  customImg: PropTypes.string,
  displayOptionImages: PropTypes.bool,
  firstPopularImage: PropTypes.string,
  forceFallback: PropTypes.bool,
  hasOrientationRight: PropTypes.bool,
  image: PropTypes.string,
  imageClassName: PropTypes.string,
  imageLoaded: PropTypes.bool,
  imageSize: PropTypes.string,
  isModularSofasPage: PropTypes.bool,
  layout: PropTypes.string,
  listName: PropTypes.string,
  productCategory: PropTypes.string,
  productImageOptionTypeId: PropTypes.number,
  productImages: PropTypes.shape({
    transparent_config_image: PropTypes.string,
    transparent_hero_image_ts: PropTypes.string,
  }),
  setImageLoaded: PropTypes.func,
  setLazy: PropTypes.bool,
  trimCustomImage: PropTypes.bool,
  isFlipped: PropTypes.bool,
};

export default ProductCardImage;
