/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import MobileSlideOutModal from 'components/consumer/MobileSlideOutModal';
import MobileNavbarExpandableCategory from 'components/nav/MobileNavbarModal/components/MobileNavbarExpandableCategory';
import MobileNavbarSection from 'components/nav/MobileNavbarModal/components/MobileNavbarSection';
import MobileNavbarLink from 'components/nav/MobileNavbarModal/components/MobileNavbarLink';
import useMobileNavItems from 'components/nav/shared/data/useMobileNavItems';
import MobileNavbarFooter from 'components/nav/MobileNavbarModal/components/MobileNavbarFooter';
import requestIdleCallback from 'commons/requestIdleCallback';
import isEventWithinLink from 'commons/isEventWithinLink';
import InstantSearchMobile from 'components/consumer/consumer-search/instant-search/InstantSearchMobile';
import isNewConsumerApp from 'commons/isNewConsumerApp';

const MobileNavbarModalUI = ({ header, isOpen, onClose }) => {
  const mobileNavItems = useMobileNavItems();
  const [openCategoryId, setOpenCategoryId] = useState(null);

  useEffect(() => {
    setOpenCategoryId(null);
  }, [isOpen]);

  // Prefetch the lazy-loaded search functionality used in the header
  // Note: this may not be necessary if Mobile Navbar 2.0 is successful in the
  // experiment and the InstantSearchMobile component is rendered directly
  // instead of via the header prop (see below)
  useEffect(() => {
    setTimeout(() => {
      requestIdleCallback(() => {
        if (!isNewConsumerApp) {
          InstantSearchMobile.preload();
        }
      });
    }, 3000);
  }, []);

  return (
    <MobileSlideOutModal isOpen={isOpen} onClose={onClose} noPadding>
      <div
        className="mb-5 pt-5 pb-5 [&_.ais-SearchBox-reset]:flex-col [&_.ais-SearchBox-reset]:items-center [&_.ais-SearchBox-reset]:justify-center [&_.ais-SearchBox-reset]:w-10"
        onClick={event => {
          // If the user clicks on a link within, we want to close the navbar
          if (isEventWithinLink(event)) {
            onClose();
          }
        }}
      >
        {// Note: If the the Mobile Navbar 2.0 experiment is successful, the
        // search functionality should be rendered here directly instead of
        // being passed in via a header prop
        !!header && <div className="mt-0 mx-5 mb-5">{header}</div>}

        {mobileNavItems.map(({ id, links }) => (
          <MobileNavbarSection key={id}>
            {links.map(
              ({
                id: itemId,
                color,
                label,
                subLabel,
                images,
                backgroundImage,
                links: categoryLinks,
                to,
              }) =>
                categoryLinks ? (
                  <MobileNavbarExpandableCategory
                    categoryId={itemId}
                    categoryLabel={label}
                    color={color}
                    key={itemId}
                    items={categoryLinks}
                    openCategoryId={openCategoryId}
                    setOpenCategoryId={setOpenCategoryId}
                  />
                ) : (
                  <MobileNavbarLink
                    key={itemId}
                    id={itemId}
                    to={to}
                    subLabel={subLabel}
                    images={images}
                    backgroundImage={backgroundImage}
                  >
                    {label}
                  </MobileNavbarLink>
                )
            )}
          </MobileNavbarSection>
        ))}

        <MobileNavbarSection>
          <MobileNavbarFooter />
        </MobileNavbarSection>
      </div>
    </MobileSlideOutModal>
  );
};

MobileNavbarModalUI.propTypes = {
  header: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MobileNavbarModalUI;
