const newDesktopStaticNavItems = [
  {
    id: 'sofas-and-sectionals',
    label: 'Sofas & Sectionals',
    longLabel: 'Sofas & Sectionals',
    url: '/sofas-and-sectionals/?category=sofas%2Csectionals%2Cloveseats',
    image: 'https://joybird2.imgix.net/user-uploads-staging/living1.jpg',
    items: [
      {
        label: 'Sofas & Loveseats',
        id: 'sofas-and-loveseats',
        url: '/sofas-and-sectionals/?category=sofas%2Cloveseats',
        categoryPageLabel: 'Sofas & Loveseats',
      },
      {
        label: 'Sectionals',
        id: 'sectionals',
        url: '/sofas-and-sectionals/?category=sectionals',
        categoryPageLabel: 'Sectionals',
      },
      {
        label: 'Sleeper Sofas',
        id: 'sleeper-sofas',
        url: '/sofas-and-sectionals/?category=sleeper%20sofas',
        categoryPageLabel: 'Sleeper Sofas',
      },
      {
        label: 'Storage Sofas',
        id: 'storage-sofas',
        url: '/sofas-and-sectionals/?category=sofas&features=storage',
        categoryPageLabel: 'Storage Sofas',
      },
      {
        label: 'Storage Sectionals',
        id: 'storage-sectionals',
        url: '/sofas-and-sectionals/?category=sectionals&features=storage',
        categoryPageLabel: 'Storage Sectionals',
      },
      {
        label: 'Modular Sofas',
        id: 'modular-sofas',
        url: '/modular-sofas',
        categoryPageLabel: 'Modular Sofas',
      },
      {
        label: 'Grand Sofas',
        id: 'grand-sofas',
        url: '/sofas-and-sectionals/?category=grand%20sofas',
        categoryPageLabel: 'Grand Sofas',
      },
      {
        label: 'Apartment Sofas',
        id: 'apartment-sofas',
        url: '/sofas-and-sectionals/?category=sofas&features=small%20space',
        categoryPageLabel: 'Apartment Sofas',
      },
      {
        label: 'Outdoor Sofas',
        id: 'outdoor-sofas',
        url: '/outdoor-furniture/?category=outdoor%20sofas',
        categoryPageLabel: 'Outdoor Sofas',
      },
      {
        label: 'Daybeds & Chaises',
        id: 'daybeds-and-chaises',
        url: '/sofas-and-sectionals/?category=chaises%20%26%20daybeds',
        categoryPageLabel: 'Daybeds & Chaises',
      },
      {
        label: 'Ottomans, Poufs and Benches',
        id: 'ottomans-poufs-and-benches',
        url: '/ottomans?category=ottomans%2Cbenches',
        categoryPageLabel: 'Ottomans, Poufs and Benches',
      },
    ],
    ctaLabel: 'Shop All Living Room',
  },
  {
    id: 'chairs',
    label: 'Chairs',
    url: '/chairs',
    image:
      'https://joybird2.imgix.net/configurations/pid_6653/-CF033-W03/6653-CF033-W03-soto-concave-arm-chair-bentley-daisey-t1-2_t.png',
    items: [
      {
        label: 'Accent Chairs',
        id: 'accent-chairs',
        url: '/chairs/?category=accent%20chairs',
        categoryPageLabel: 'Accent Chairs',
      },
      {
        label: 'Apartment Chairs',
        id: 'apartment-chairs',
        url: '/chairs/?features=small%20space',
        categoryPageLabel: 'Apartment Chairs',
      },
      {
        label: 'Swivel Chairs',
        id: 'swivel-chairs',
        url: '/chairs/?category=swivel%20chairs',
        categoryPageLabel: 'Swivel Chairs',
      },
      {
        label: 'Rocking Chairs & Recliners',
        id: 'rocking-chairs-and-recliners',
        url: '/chairs?category=rocking%20chairs%2Crecliner%20chairs',
        categoryPageLabel: 'Rocking Chairs & Recliners',
      },
      {
        label: 'Dining Chairs',
        id: 'dining-chairs',
        url: '/chairs?category=dining%20chairs',
        categoryPageLabel: 'Dining Chairs',
      },
      {
        label: 'Bar & Counter Stools',
        id: 'bar-and-counter-stools',
        url: '/chairs/?category=counter%20stools',
        categoryPageLabel: 'Bar & Counter Stools',
      },
      {
        label: 'Office Chairs',
        id: 'office-chairs',
        url: '/chairs/?category=office%20chairs',
        categoryPageLabel: 'Office Chairs',
      },
      {
        label: 'Outdoor Chairs',
        id: 'outdoor-chairs',
        url: '/chairs/?category=outdoor%20chairs',
        categoryPageLabel: 'Outdoor Chairs',
      },
      {
        label: 'Ottomans, Poufs and Benches',
        id: 'ottomans-poufs-and-benches',
        url: '/ottomans?category=ottomans%2Cbenches',
        categoryPageLabel: 'Ottomans, Poufs and Benches',
      },
    ],
    ctaLabel: 'Shop All Chairs',
  },
  {
    id: 'tables',
    label: 'Tables',
    url: '/all-tables',
    image:
      'https://joybird2.imgix.net/product_hero/9360/9360-hudson-coffee-table-t1-1_ts.png',
    items: [
      {
        label: 'Dining Tables',
        id: 'dining-tables',
        url: '/all-tables/?category=dining%20tables',
        categoryPageLabel: 'Dining Tables',
      },
      {
        label: 'Coffee Tables',
        id: 'coffee-tables',
        url: '/all-tables/?category=coffee%20tables',
        categoryPageLabel: 'Coffee Tables',
      },
      {
        label: 'Side, End, & Accent Tables',
        id: 'side-end-and-accent-tables',
        url: '/all-tables/?category=end%20%26%20side%20tables',
        categoryPageLabel: 'Side, End, & Accent Tables',
      },
      {
        label: 'Entry & Console Tables',
        id: 'entry-and-console-tables',
        url: '/all-tables/?category=console%20%26%20entry%20tables',
        categoryPageLabel: 'Entry & Console Tables',
      },
      {
        label: 'Expandable Tables',
        id: 'expandable-tables',
        url: '/',
        categoryPageLabel: 'Expandable Tables',
      },
      {
        label: 'Desks',
        id: 'desks',
        url: '/all-tables/?category=desks',
        categoryPageLabel: 'Desks',
      },
      {
        label: 'Outdoor Tables',
        id: 'outdoor-tables',
        url:
          '/outdoor-furniture/?category=outdoor%20tables/all-tables/?category=outdoor%20tables',
        categoryPageLabel: 'Outdoor Tables',
      },
    ],
    ctaLabel: 'Shop All Tables',
  },
  {
    id: 'outdoor',
    label: 'Outdoor',
    url: '/outdoor-furniture',
    image: 'https://joybird2.imgix.net/user-uploads-staging/outdoor.jpg',
    items: [
      {
        label: 'Outdoor Sofas',
        id: 'outdoor-sofas',
        url: '/outdoor-sofas/',
      },
      {
        label: 'Outdoor Chairs',
        id: 'outdoor-chairs',
        url: '/outdoor-chairs/',
      },
      {
        label: 'Outdoor Tables',
        id: 'outdoor-tables',
        url: '/outdoor-tables/',
      },
    ],
    ctaLabel: 'Shop All Outdoor',
  },
  {
    id: 'strorage',
    label: 'Storage',
    url: '/all-storage',
    image:
      'https://joybird2.imgix.net/product_hero/895/-W03/895-W03-simic-media-console-t1-1_ts.png',
    items: [
      {
        label: 'TV Stands & Media Consoles',
        id: 'tv-stands-and-media-consoles',
        url:
          '/all-storage/?category=tv%C2%A0stands%C2%A0%26%C2%A0media%C2%A0consoles',
        categoryPageLabel: 'TV Stands & Media Consoles',
      },
      {
        label: 'Bookcases & Shelving',
        id: 'bookcases-and-shelving',
        url: '/all-storage/?category=bookcases%C2%A0%26%C2%A0shelving',
        categoryPageLabel: 'Bookcases & Shelving',
      },
      {
        label: 'Bar Carts & Storage Cabinets',
        id: 'bar-carts-and-storage-cabinets',
        url: '/bar-carts-cabinets',
        categoryPageLabel: 'Bar Carts & Storage Cabinets',
      },
      {
        label: 'Storage Sofas & Ottomans',
        id: 'storage-sofas-and-ottomans',
        url:
          '/sofas-and-sectionals/?features=storage/ottomans/?category=ottomans&features=storage',
        categoryPageLabel: 'Storage Sofas & Ottomans',
      },
    ],
    ctaLabel: 'Shop All Storage',
  },
  {
    id: 'decor',
    label: 'Decor',
    longLabel: 'Decor + Accessories',
    url: '/decoration-accessories',
    image:
      'https://joybird2.imgix.net/user-uploads-staging/Notch-Modular-6pc-Sectional-Merit-Dove-567.jpg',
    items: [
      {
        label: 'Rugs',
        id: 'rugs',
        url: '/decoration-accessories/?category=rugs',
        categoryPageLabel: 'Rugs',
      },
      {
        label: 'Pillows',
        id: 'pillows',
        url: '/decoration-accessories/?category=pillows',
        categoryPageLabel: 'Pillows',
      },
      {
        label: 'Throws',
        id: 'throws',
        url: '/decoration-accessories/?category=throws',
        categoryPageLabel: 'Throws',
      },
      {
        label: 'Poufs & Cubes',
        id: 'poufs-and-cubes',
        url: '/decoration-accessories/?category=poufs%20%26%20cubes',
        categoryPageLabel: 'Poufs & Cubes',
      },
      {
        label: 'Lighting',
        id: 'lighting',
        url: '/decoration-accessories/?category=lighting',
        categoryPageLabel: 'Lighting',
      },
      {
        label: 'Wall Decor',
        id: 'wall-decor',
        url: '/decoration-accessories/?category=wall%20decor',
        categoryPageLabel: 'Wall Decor',
      },
      {
        label: 'Wallpaper',
        id: 'wallpaper',
        url: '/decoration-accessories/?category=wallpaper',
        categoryPageLabel: 'Wallpaper',
      },
      {
        label: 'Planters',
        id: 'planters',
        url: '/decoration-accessories/?category=planters',
        categoryPageLabel: 'Planters',
      },
      {
        label: 'Mirrors',
        id: 'mirrors',
        url: '/decoration-accessories/?category=mirrors',
        categoryPageLabel: 'Mirrors',
      },
      {
        label: 'Tableware',
        id: 'tableware',
        url: '/decoration-accessories/?category=tableware',
        categoryPageLabel: 'Tableware',
      },
      {
        label: 'Pet Beds',
        id: 'pet-beds',
        url: '/decoration-accessories/?category=pet%20beds',
        categoryPageLabel: 'Pet Beds',
      },
    ],
    ctaLabel: 'Shop All Decor',
  },
  {
    id: 'featured',
    label: 'Featured',
    url: '/featured',
    image:
      'https://joybird2.imgix.net/user-uploads-staging/JB-LP-Showroom-SFO-Hero-01-1658782323220.jpeg',
    items: [
      {
        label: 'New Arrivals',
        id: 'new-arrivals',
        url: '/new-arrivals/?sortBy=newest',
      },
      {
        label: 'Virtual Showroom',
        id: 'virtual-showroom',
        url: '/virtual-showroom/',
      },
      {
        label: 'Ready to Ship',
        id: 'quick-ship',
        url: '/instock/',
      },
    ],
    ctaLabel: 'Shop All Sale',
  },
  {
    id: 'sale',
    label: 'Sale',
    url: '/sale',
    color: 'red',
    image:
      'https://jbimages.imgix.net/Category-Thumb2-Sale-396e35b6-986c-48a3-abf2-a92d6884fc94.jpg',
    items: [
      {
        label: 'Clearance',
        id: 'clearance',
        url: '/clearance',
      },
    ],
    ctaLabel: 'Shop All Sale',
  },
  {
    id: 'rooms',
    label: 'Rooms',
    url: '/shop',
    image: 'https://joybird2.imgix.net/user-uploads-staging/living1.jpg',
    items: [
      {
        label: 'Living Room',
        id: 'living-room',
        url: '/living-room-furniture',
        categoryPageLabel: 'Living Room',
      },
      {
        label: 'Dining Room',
        id: 'dining-room',
        url: '/dining-room-furniture',
        categoryPageLabel: 'Dining Room',
      },
      {
        label: 'Bedroom',
        id: 'bedroom',
        url: '/bedroom',
        categoryPageLabel: 'Bedroom',
      },
      {
        label: 'Home Office',
        id: 'home-office',
        url: '/home-office-furniture',
        categoryPageLabel: 'Home Office',
      },
      {
        label: 'Outdoor',
        id: 'outdoor',
        url: '/outdoor-furniture',
        categoryPageLabel: 'Outdoor',
      },
    ],
    ctaLabel: 'Shop All Rooms',
  },
  {
    id: 'inspiration',
    label: 'Inspiration',
    url: '/customer-photos/',
    image: 'https://joybird2.imgix.net/user-uploads-staging/inspiration.jpeg',
    items: [
      {
        label: 'Customer Photos',
        id: 'customer-photos',
        url: '/customer-photos/',
        description: 'Room inspo, courtesy of real Joybird customers',
        image:
          'https://inspire3.imgix.net/rackspace/customer_photos/9511/1a787a10-2850-11eb-8c0d-a955d1f79aa0.jpeg',
      },
      {
        label: 'Fabric Swatches',
        id: 'fabric-swatches',
        url: '/swatches/',
        description: 'Make your piece your own with our rainbow of fabrics',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/two-squares_colors@2x-1585688761058.jpeg',
      },
      {
        label: 'Collections',
        id: 'collections',
        url: '/collections/',
        description:
          'Discover pieces from furniture families and find your favorites',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/Simic-Media-Console-Walnut-T2-024.jpg',
      },
      {
        label: 'Lookbooks',
        id: 'lookbooks',
        url: '/lookbooks/',
        description: 'Get inspired by our latest shoppable catalog',
        descriptionTextColor: 'light',
        image:
          'https://joybird2.imgix.net/product_hero/11261/GOLDIE-SOFA-ROYALE-HACIENDA-210923-T4-0610.jpg',
      },
      {
        label: 'Joybird Live',
        id: 'joybird-live',
        url: '/live/',
        description: 'Tune into our one-of-a-kind shopping experience!',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/Preston-sofa-marin-sunflower-T2-20211021-Scene3-opt2.jpg',
      },
      {
        label: 'Blog',
        id: 'blog',
        url: '/blog/',
        description: 'Design inspo, tips & tricks, and inspiring spaces',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/inspirationblog.jpeg',
      },
      // "Joybird Picks" item is commented out until updates to Custom List
      // Configuration type are complete:
      // https://stitchindustries.atlassian.net/browse/CON-3615
      // {
      //   label: 'Joybird Picks',
      //   id: 'joybird-picks',
      //   url: '/joybirds-picks/',
      //   image:
      //     'https://joybird2.imgix.net/product_hero/9167/5-Ways-to-Create-a-Cozy-Bedroom-Shelby-Bed-T3-Uncropped-161-flipped1.jpg',
      //   items: [
      //     {
      //       label: 'Light & Lively',
      //       id: 'light-and-lively',
      //       url: '/joybirds-picks/light-and-lively/',
      //     },
      //     {
      //       label: 'Warm & Inviting',
      //       id: 'warm-and-inviting',
      //       url: '/joybirds-picks/warm-and-inviting/',
      //     },
      //     {
      //       label: 'Seasonal Selections',
      //       id: 'seasonal-selections',
      //       url: '/joybirds-picks/memorial-day-picks/',
      //     },
      //   ],
      // },
    ],
    ctaLabel: 'Shop All Inspiration',
  },
  {
    id: 'free-design-services',
    label: 'Free Design Services',
    url: '/free-design-services/',
    image:
      'https://joybird2.imgix.net/static-v1/design-services-page/Jungalow-set-build-liam-sofa-royale-evergreen-170807-lastudio-0006-layered-square.gif',
    items: [
      {
        label: '3D Space Planner',
        id: 'three-d-space-planner',
        url: 'https://design.joybird.com/',
        description: 'Bring your perfect space to life - virtually',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/webimage-4D34154D-D96F-4D38-806BD38F08A5D610.jpg',
      },
      {
        label: '3D Sofa Builder',
        id: 'three-d-sofa-builder',
        url: '/modular-sofas/',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/webimage-3047B78E-2BEA-4566-8BB3430B74E4FCB3.jpg',
      },
      {
        label: 'Room Visualizer',
        id: 'room-refresh',
        url: 'https://roomrefresh.joybird.com/',
        description: 'Compare products with this swap-out tool',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/webimage-3047B78E-2BEA-4566-8BB3430B74E4FCB3.jpg',
      },
    ],
    ctaLabel: 'Joybird Design House',
  },
];

export default newDesktopStaticNavItems;
