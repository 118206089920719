import React from 'react';
import PropTypes from 'prop-types';
import NotificationBannerSkeleton from './NotificationBannerSkeleton';

const BagSaved = ({ result, onClose, email }) => (
  <NotificationBannerSkeleton
    title={
      result === 'success' ? 'Your bag has been saved!' : 'Error sending email'
    }
    body={
      <span>
        {result === 'success' ? (
          <>
            We sent a link to <span className="text-brand">{email}</span> where
            you can access it any time.
          </>
        ) : (
          <>Something went wrong, email could not be sent.</>
        )}
      </span>
    }
    onClose={onClose}
  />
);

BagSaved.propTypes = {
  result: PropTypes.string,
  onClose: PropTypes.func,
  email: PropTypes.string,
};

export default BagSaved;
