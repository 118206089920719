import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';

import IconBirdLogo from 'components/icons/iconBirdLogo';
import closeIcon from 'assets/icons/ico-close.svg';
import JBImage from 'components/essentials/JBImage';
import notificationBannerHelper from './utils';

const NotificationBannerSkeleton = ({
  title,
  icon,
  body,
  onClose,
  modifier,
  keepOpenOnMouseEnter = false,
}) => {
  const iconHTML = icon || (
    <IconBirdLogo
      color="#27919C"
      style={{
        transform: 'scale3d(calc(26/16), calc(19/11), 1)',
        margin: 0,
      }}
    />
  );
  return (
    <Transition.Child
      className={classNames(
        'w-full md:w-[457px] cursor-auto border border-solid [border-color:#dbf0f0] rounded bg-[#f4f9fa] fixed flex items-start z-[1009] px-5 py-[1.375rem]'
      )}
      onMouseEnter={() => {
        if (keepOpenOnMouseEnter) {
          notificationBannerHelper.removeTimeout();
        }
      }}
      onMouseLeave={() => {
        if (keepOpenOnMouseEnter) {
          notificationBannerHelper.hideMsgWithTimeout();
        }
      }}
      appear
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo={classNames(
        'opacity-100',
        'top-auto left-0 right-0',
        'md:left-auto md:bottom-auto md:right-[4.25rem]',
        {
          'md:top-[7.125rem] bottom-[5px]': modifier !== 'second',
          'md:top-[16.125rem] bottom-40': modifier === 'second',
        }
      )}
      leave={classNames(
        'transition-all duration-500',
        'top-auto left-0 right-0',
        'md:left-auto md:bottom-auto',
        {
          'md:top-[7.125rem] bottom-[5px]': modifier !== 'second',
          'md:top-[16.125rem] bottom-40': modifier === 'second',
        }
      )}
      leaveFrom={classNames('opacity-100', 'md:right-[4.25rem]', {
        'bottom-[5px]': modifier !== 'second',
        'bottom-40': modifier === 'second',
      })}
      leaveTo="opacity-0 md:right-[-457px] bottom-[-500px]"
    >
      <div className="[&_span]:![border-bottom:none]">{iconHTML}</div>

      <div className="!text-gray ml-4">
        {title && <div className="font-bold text-base text-gray">{title}</div>}

        {body && (
          <div className="text-base text-gray items-center flex [&_a]:text-base [&_a]:text-brand [&_a]:no-underline">
            {body}
          </div>
        )}
      </div>

      <button
        type="button"
        className="[background:none] border-0 absolute right-4 top-[1.125rem] w-[12.25px] cursor-pointer [&_img]:m-0 [&_img]:text-gray"
        onClick={(...args) => {
          notificationBannerHelper.hideMsg();
          if (onClose) {
            onClose(...args);
          }
        }}
      >
        <JBImage src={closeIcon} width={18} />
      </button>
    </Transition.Child>
  );
};

NotificationBannerSkeleton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  body: PropTypes.node,
  onClose: PropTypes.func,
  modifier: PropTypes.oneOf(['second', 'top']),
  keepOpenOnMouseEnter: PropTypes.bool,
};

export default NotificationBannerSkeleton;
