import { useEffect, useRef } from 'react';

import {
  DEFAULT_WAREHOUSE_ID,
  ZIPCODE_FROM_USER_COOKIE_KEY,
} from 'commons/constants';
import useSetUserLocation from 'global-state/userLocation/useSetUserLocation';
import useSetUserLocationLoading from 'global-state/userLocationLoading/useSetUserLocationLoading';
import useSetWarehouseIdForUser from 'global-state/warehouseIdForUser/useSetWarehouseIdForUser';
import { getUserLocationCookie } from 'commons/localStorage';
import useSetZipCodeFromUser from 'global-state/zipCodeFromUser/useSetZipCodeFromUser';
import getCookies from 'commons/getCookies';
import useIsBrowser from 'hooks/useIsBrowser';
import useLocationFromBrowser from './useLocationFromBrowser';
import useLocationFromCloudFront from './useLocationFromCloudFront';
import useLocationForUserZipCode from './useLocationForUserZipCode';

const EMPTY_LOCATION = {};

// This hook gets the user's location and updates the relevant global state
// variables for the current location, the current error message for the
// zip code specified by the user (if any), and the current warehouse ID.

// High-level overview of the priority order for finding the user's location:
//  1. If the user has entered a zip code, use that zip code to find the
//     location data for that zip code.
//  2. If the user has not entered a zip code, or the zip code is invalid, use
//     CloudFront to find the location from the user's IP address.
//  3. If neither the location from user-entered zip code nor the location from
//     CloudFront are available, get the location using the browser's
//     geolocation API instead.

const useRefreshUserLocation = () => {
  const setZipCodeFromUser = useSetZipCodeFromUser();
  const setUserLocation = useSetUserLocation();

  // Restore global zipCodeFromUser and userLocation state from cookies in the
  // browser, until we switch to server-side cookies in the new Consumer app
  useEffect(() => {
    const zipCodeFromUserCookie = getCookies()?.get(
      ZIPCODE_FROM_USER_COOKIE_KEY
    );

    if (zipCodeFromUserCookie) {
      setZipCodeFromUser(zipCodeFromUserCookie);
    }
  }, [setZipCodeFromUser]);

  useEffect(() => {
    const locationFromCookie = getUserLocationCookie();

    if (locationFromCookie?.zip) {
      setUserLocation(locationFromCookie);
    }
  }, [setUserLocation]);

  const {
    isLoading: isUserZipCodeLocationLoading,
    location: locationForUserZipCode,
    shouldUseLocation: shouldUseLocationForUserZipCode,
    warehouseId: warehouseIdForUserZipCode,
  } = useLocationForUserZipCode();

  const {
    isLoading: isLocationFromCloudFrontLoading,
    location: locationFromCloudFront,
    shouldUseLocation: shouldUseLocationFromCloudFront,
    warehouseId: warehouseIdForCloudfrontLocation,
  } = useLocationFromCloudFront({
    skip: isUserZipCodeLocationLoading || shouldUseLocationForUserZipCode,
  });

  // We track if the location from CloudFront has started loading so that we
  // can skip the useLocationFromBrowser hook if the CloudFront location has
  // not started loading yet - we should only ask for location in the browser
  // if the CloudFront location has been requested but failed.
  const hasCloudFrontStartedLoading = useRef(false);

  hasCloudFrontStartedLoading.current =
    hasCloudFrontStartedLoading.current || isLocationFromCloudFrontLoading;

  const isBrowser = useIsBrowser();

  const {
    isLoading: isLocationFromBrowserLoading,
    location: locationFromBrowser,
    warehouseId: warehouseIdForBrowserLocation,
  } = useLocationFromBrowser({
    skip:
      !isBrowser ||
      !hasCloudFrontStartedLoading.current ||
      isUserZipCodeLocationLoading ||
      isLocationFromCloudFrontLoading ||
      shouldUseLocationForUserZipCode ||
      shouldUseLocationFromCloudFront,
  });

  // Update userLocationLoading global state variable
  const setUserLocationLoading = useSetUserLocationLoading();

  const isLoading =
    isUserZipCodeLocationLoading ||
    isLocationFromCloudFrontLoading ||
    isLocationFromBrowserLoading;

  useEffect(() => {
    setUserLocationLoading(isLoading);
  }, [setUserLocationLoading, isLoading]);

  const userLocation =
    locationForUserZipCode ??
    locationFromCloudFront ??
    locationFromBrowser ??
    EMPTY_LOCATION;

  // Update userLocation global state variable
  useEffect(() => {
    setUserLocation(userLocation);
  }, [setUserLocation, userLocation]);

  // Update warehouseIdForUser global state variable
  const setWarehouseIdForUser = useSetWarehouseIdForUser();

  const warehouseId =
    warehouseIdForUserZipCode ??
    warehouseIdForCloudfrontLocation ??
    warehouseIdForBrowserLocation ??
    DEFAULT_WAREHOUSE_ID;

  useEffect(() => {
    setWarehouseIdForUser(warehouseId);
  }, [setWarehouseIdForUser, warehouseId]);
};

export default useRefreshUserLocation;
