// This function is useful to detect if the user clicks on a link or button
// nested within an ephemeral modal, e.g. the desktop navbar, because we would
// then we want to close the modal
const isEventWithinLinkOrButton = event => {
  const element = event?.target;
  const domElementName = element?.tagName.toLowerCase();
  const isLinkOrButton = domElementName === 'a' || domElementName === 'button';
  const isWithinLinkOrButton = !!element?.closest?.('a,button');
  const isEventWithin = isLinkOrButton || isWithinLinkOrButton;

  return isEventWithin;
};

export default isEventWithinLinkOrButton;
