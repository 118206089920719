import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/consumer/Link';

const ToolbarLinkOrButton = ({ to, ...otherProps }) => {
  if (to) {
    return <Link to={to} {...otherProps} />;
  }

  return <button {...otherProps} type="button" />;
};

ToolbarLinkOrButton.propTypes = {
  to: PropTypes.string,
};

export default ToolbarLinkOrButton;
