import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import CtaLink from 'components/essentials/Cta/CtaLink';

import DesktopNavLinkGroupItem from './DesktopNavLinkGroupItem';

const DesktopNavLinkGroup = ({
  categoryId = '',
  items = [],
  ctaLabel,
  ctaUrl,
  secondaryItems = [],
  selectedItem,
  setIsTitleHovered,
}) => {
  const containerRef = useRef(null);

  return (
    <div className="DesktopNavLinkGroup">
      <ul className="m-0 p-0 max-h-[337px] overflow-x-auto" ref={containerRef}>
        {items.map(
          ({ customContent, id, isNew, items: nestedItems, label, url }) => (
            <DesktopNavLinkGroupItem
              categoryId={categoryId}
              containerRef={containerRef}
              hasNestedContent={!!nestedItems?.length || !!customContent}
              id={id}
              isNew={isNew}
              key={id}
              label={label}
              selectedItem={selectedItem}
              setIsTitleHovered={setIsTitleHovered}
              url={url}
            />
          )
        )}
      </ul>

      {!!ctaLabel && !!ctaUrl && (
        <div className="mb-5 mt-5 ml-3.5">
          <CtaLink
            to={ctaUrl}
            styling="solid-button"
            color="brand"
            size="sm"
            spacing="xs"
          >
            {ctaLabel}
          </CtaLink>
        </div>
      )}

      <ul className="m-0 p-0" ref={containerRef}>
        {secondaryItems.map(
          ({ customContent, id, isNew, items: nestedItems, label, url }) => (
            <DesktopNavLinkGroupItem
              categoryId={categoryId}
              containerRef={containerRef}
              hasNestedContent={!!nestedItems?.length || !!customContent}
              id={id}
              isNew={isNew}
              key={id}
              label={label}
              selectedItem={selectedItem}
              setIsTitleHovered={setIsTitleHovered}
              url={url}
            />
          )
        )}
      </ul>
    </div>
  );
};

DesktopNavLinkGroup.propTypes = {
  categoryId: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      isNew: PropTypes.bool,
      label: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  secondaryItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      isNew: PropTypes.bool,
      label: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  selectedItem: PropTypes.string,
  setIsTitleHovered: PropTypes.func,
};

export default DesktopNavLinkGroup;
