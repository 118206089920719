import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { Transition } from '@headlessui/react';
import { RemoveScroll } from 'react-remove-scroll';
import classNames from 'classnames';
import Div100vh from 'react-div-100vh';

import useOnEsc from 'hooks/useOnEsc';
import useTrapFocus from 'hooks/useTrapFocus';
import useRandomId from 'hooks/useRandomId';
import getOrCreateElementById from 'commons/getOrCreateElementById';
import VisuallyHidden from 'components/consumer/VisuallyHidden';
import useFocusOnOpen from 'hooks/useFocusOnOpen';
import MobileNavbarHiddenCloseButton from 'components/nav/MobileNavbarModal/components/MobileNavbarHiddenCloseButton';
import withBrowserOnly from 'components/hoc/withBrowserOnly';

const MobileSlideOutModalRootElement = getOrCreateElementById(
  'MobileSlideOutModal-root'
);

const MobileSlideOutModal = ({
  children,
  footer,
  header,
  isOpen = false,
  noPadding = false,
  onClose,
  title,
}) => {
  const modalRef = useFocusOnOpen(isOpen);
  const modalTitleId = useRandomId('MobileSlideOutModal-title');

  useOnEsc(isOpen, onClose);
  useTrapFocus(isOpen, modalRef);

  return createPortal(
    <Transition show={isOpen}>
      <div>
        <Transition.Child
          aria-hidden
          className="fixed bottom-0 top-0 left-0 right-0 [background:rgba(0,0,0,.6)] hover:cursor-pointer"
          onClick={onClose}
          tabIndex="-1"
          enter="transition-[opacity,transform] ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-[opacity,transform] ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        />

        <Transition.Child
          className="bg-gray-light9 cursor-default h-full fixed left-0 top-0"
          enter="transition-[opacity,transform] ease-in-out duration-300"
          enterFrom="opacity-0 translate-x-[-100vw]"
          enterTo="opacity-100 translate-x-0"
          leave="transition-[opacity,transform] ease-in-out duration-300"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-[-120vw]"
        >
          <RemoveScroll
            className={classNames(
              'h-full overflow-x-hidden overflow-y-auto w-[80vw]',
              {
                'p-4': !noPadding,
                'pb-0': !!footer,
                'pt-0': !!header,
                'p-0': !!noPadding,
              }
            )}
            removeScrollBar={false}
            enabled={isOpen}
          >
            <Div100vh
              ref={modalRef}
              role="dialog"
              aria-labelledby={modalTitleId}
              className="flex flex-col"
            >
              <VisuallyHidden>Dialog content starts</VisuallyHidden>
              <div className="items-center flex justify-end p-2 pointer-events-none fixed right-0 top-0 w-[20vw]">
                <MobileNavbarHiddenCloseButton onClick={onClose} />
              </div>
              {!!header && (
                <div className="bg-gray-light9 pb-3 pt-3 mb-3 sticky top-0 z-[1] after:[border-bottom:1px_solid_#f1f1f1] after:[content:''] after:-left-4 after:absolute after:-right-4 after:bottom-0">
                  {header}
                </div>
              )}
              {!!title && (
                <div className="text-xl font-bold text-gray m-0 pt-0 px-0 pb-2">
                  {title}
                </div>
              )}
              <div className="flex-1">{children}</div>
              {!!footer && (
                <div className="bg-gray-light9 bottom-0 mt-3 pb-3 pt-3 sticky z-[1] before:[border-top:1px_solid_#f1f1f1] before:[content:''] before:-left-4 before:absolute before:-right-4 before:top-0">
                  {footer}
                </div>
              )}
              <VisuallyHidden>Dialog content ends</VisuallyHidden>
            </Div100vh>
          </RemoveScroll>
        </Transition.Child>
      </div>
    </Transition>,
    MobileSlideOutModalRootElement
  );
};

MobileSlideOutModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  noPadding: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.node,
  footer: PropTypes.node,
  header: PropTypes.node,
};

export default withBrowserOnly(MobileSlideOutModal);
