import React from 'react';
import PropTypes from 'prop-types';
import { colorGray } from 'styles/layout/colors';

const ΙconSearch = ({ color = colorGray, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="3 1 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.361 1.25a8.111 8.111 0 015.631 13.95l4.77 5.963-1.172.937-4.768-5.963a8.112 8.112 0 11-4.46-14.887zm0 1.5a6.611 6.611 0 100 13.223 6.611 6.611 0 000-13.223z"
      fill={color}
    />
  </svg>
);

ΙconSearch.propTypes = {
  color: PropTypes.string,
};

export default ΙconSearch;
