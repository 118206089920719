import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import NotificationBannerSkeleton from './NotificationBannerSkeleton';

const LivestreamNotification = ({ onClose, title }) => (
  <NotificationBannerSkeleton
    title="Video streaming now"
    body={
      <span className="[&_button]:p-0 [&_button]:ml-[5px]">
        <Link to="/videos">{title}</Link>
      </span>
    }
    onClose={onClose}
  />
);

LivestreamNotification.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default LivestreamNotification;
