import React, { useState } from 'react';
import { InstantSearch, Index, Configure } from 'react-instantsearch-dom';
import { useQueryParam, StringParam } from 'use-query-params';
import PropTypes from 'prop-types';
import avo from 'analytics/avo';
import classNames from 'classnames';

import { noEmptySearchClient } from 'components/essentials/AlgoliaSearchClient';
import { ALGOLIA_INDEX_NAME } from 'commons/constants';
import useUserCurrentPageType from 'hooks/useUserCurrentPageType';
import useToggleIsSearchOpen from 'global-state/isSearchOpen/useToggleIsSearchOpen';
import useCloseSearch from 'global-state/isSearchOpen/useCloseSearch';
import DesktopOnly from 'components/consumer/DesktopOnly';

import {
  ResultsByCategory,
  SearchResults,
  StaticPageHits,
  CategoryHits,
  JBSearchBox,
  PagesResults,
} from '../commons/helpers';

import StaticProductResults from './components/StaticProductResults';

const ConsumerInstantSearch = props => {
  const [instantSearchState, setSearchState] = useState({
    lastQuery: '',
    lastQueryTopThreeRes: [],
    inactive: false,
  });
  const [displayPagesResults, setDisplayPagesResults] = useState(false);

  const currentPage = useUserCurrentPageType();
  const [searchQuery] = useQueryParam('q', StringParam);

  const toggleIsSearchOpen = useToggleIsSearchOpen();
  const closeSearch = useCloseSearch();

  const toggleSearchOpen = () => {
    if (
      searchQuery &&
      searchQuery.length > 1 &&
      instantSearchState.inactive === false
    ) {
      avo.productsSearched({
        query: searchQuery,
        location: currentPage,
        searchTarget: avo.SearchTarget.EXIT_SEARCH,
      });
    }

    toggleIsSearchOpen();
  };

  const setInactive = val => {
    closeSearch();
    setSearchState({ ...instantSearchState, inactive: val });
  };

  const displayPagesResultComponent = displayState => {
    if (displayPagesResultComponent === displayState) {
      return;
    }
    setDisplayPagesResults(displayState);
  };

  const { autoFocus, isTopSearch, showOverlay } = props;

  return (
    <>
      {showOverlay && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className={classNames(
            'lg:[background:rgba(0,0,0,.75)] lg:bottom-0 lg:[content:""] lg:left-0 lg:opacity-0 lg:pointer-events-none lg:fixed lg:right-0 lg:top-0 lg:[transition:opacity_.8s_ease-out] lg:z-[1001]',
            {
              'lg:!opacity-100 lg:!pointer-events-auto lg:![transition:opacity_.3s_ease-out] [&_.ais-SearchBox-input]:lg:placeholder:text-center [&_.ais-SearchBox-input]:lg:placeholder:pl-[195px] [&_.ais-SearchBox-input.hasValue]:lg:indent-0':
                props.isActive && isTopSearch,
            }
          )}
          onClick={toggleSearchOpen}
        />
      )}

      <div
        className={classNames(
          'bg-white absolute inset-x-0 top-0 max-[1023px]:relative max-[1023px]:z-10',
          {
            'lg:!z-[1002]': isTopSearch,
          }
        )}
      >
        <DesktopOnly>
          <div className="max-[1023px]:hidden">
            <InstantSearch
              searchClient={noEmptySearchClient}
              indexName={ALGOLIA_INDEX_NAME.PRODUCTS}
            >
              <Configure
                hitsPerPage={3}
                facets={['*']}
                numericFilters={[
                  'item_availability<2',
                  'visibility>0',
                  'visibility<4',
                ]}
              />
              <JBSearchBox
                autoFocus={autoFocus}
                isActive={props.isActive}
                setInactive={setInactive}
                toggleSearchOpen={toggleSearchOpen}
                variant="ConsumerInstantSearch"
              />
              <SearchResults>
                <div className="bg-white [border-bottom:1px_solid_#f1f1f1]">
                  <div
                    className={classNames(
                      'text-[.8em] flex w-full mx-[3.333vw] my-0',
                      { 'w-auto': props?.isTopSearch }
                    )}
                  >
                    <StaticProductResults
                      // force re-render on query change
                      key={`instant-search-results-${searchQuery}`}
                      isInstantSearch
                      layout="minimal"
                      toggleSearchOpen={toggleSearchOpen}
                      displayPagesResultComponent={displayPagesResultComponent}
                    />
                    <Index indexName="categories">
                      <Configure
                        hitsPerPage={5}
                        numericFilters={['fk_store_id=1']}
                        facetFilters={['is_searchable:1']}
                      />
                      <CategoryHits toggleSearchOpen={toggleSearchOpen} />
                    </Index>

                    {displayPagesResults ? (
                      <Index indexName={ALGOLIA_INDEX_NAME.STATIC_PAGES}>
                        <Configure
                          hitsPerPage={3}
                          numericFilters={[
                            'is_active=1',
                            'show_in_search=1',
                            'fk_store_id=1',
                          ]}
                        />
                        <PagesResults
                          toggleSearchOpen={toggleSearchOpen}
                          isInstantSearch
                        />
                      </Index>
                    ) : null}

                    <div className="min-w-[20%] flex-[0_1_20%] pl-[30px] h-full instant-search-form__each-results-container-non-padded">
                      {!displayPagesResults ? (
                        <Index indexName={ALGOLIA_INDEX_NAME.STATIC_PAGES}>
                          <Configure
                            hitsPerPage={3}
                            numericFilters={[
                              'is_active=1',
                              'show_in_search=1',
                              'fk_store_id=1',
                            ]}
                          />
                          <StaticPageHits toggleSearchOpen={toggleSearchOpen} />
                        </Index>
                      ) : null}
                      <Index indexName="reviews">
                        <Configure
                          facets={['*']}
                          numericFilters=""
                          facetFilters=""
                        />
                      </Index>
                      <Index indexName="customer_photos">
                        <Configure facets={['*']} numericFilters="" />
                      </Index>
                      <div className="[border-right:1px_solid_#f1f1f1] pt-5 pr-8 pb-10 min-h-[264px]">
                        <ResultsByCategory
                          toggleSearchOpen={toggleSearchOpen}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </SearchResults>
            </InstantSearch>
          </div>
        </DesktopOnly>
      </div>
    </>
  );
};

ConsumerInstantSearch.propTypes = {
  autoFocus: PropTypes.bool,
  isActive: PropTypes.bool,
  isTopSearch: PropTypes.bool,
  showOverlay: PropTypes.bool,
};

export default ConsumerInstantSearch;
