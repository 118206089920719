import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import Link from 'components/consumer/Link';
import JBLogo from 'components/consumer/jb-logo';
import useStoreDetails from 'hooks/useStoreDetails';
import getIsClient from 'commons/isClient';

const getIsPrideMonth = () => {
  const currentDate = new Date();
  // The month is 0-based, so we need to add 1 to get the correct month number
  const currentMonthNumber = currentDate.getMonth() + 1;
  // Pride Month is June, so if the month number is 6, we're in Pride Month
  return currentMonthNumber === 6;
};

const IconJoybirdLogoPride = loadable(() =>
  import('components/icons/IconJoybirdLogoPride')
);

const LogoLink = ({ className = '', to = '/' }) => {
  const storeDetails = useStoreDetails();
  const storeName = storeDetails?.name;
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    const isRunningOnClient = getIsClient();
    setIsClient(isRunningOnClient);
  }, []);

  const isPrideMonth = useMemo(() => getIsPrideMonth(), []);

  return (
    <Link
      className={`items-center rounded-sm border-2 border-solid border-transparent inline-flex justify-center p-2 absolute no-underline left-[60px] min-h-12 lg:relative lg:left-[-6px] ${className}`}
      to={to}
      data-xc="joybird_logo"
    >
      <Fragment key={isClient ? 'client' : 'server'}>
        {isPrideMonth ? (
          <IconJoybirdLogoPride className="relative top-0.5 lg:static lg:top-0" />
        ) : (
          <JBLogo />
        )}
      </Fragment>

      {!!storeName && (
        <span className="text-xs ml-2 pt-2 color-gray font-script">
          {storeName}
        </span>
      )}
    </Link>
  );
};

LogoLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
};

export default LogoLink;
