const getSelectedOptionName = (
  allOptions = [],
  optionTypeId,
  selectedOptionId
) => {
  // Find options for this specific option type, e.g. Fabric or Orientation
  const options =
    allOptions.find(option => option.id === optionTypeId)?.values || [];

  const selectedOption = options.find(opt => opt.id === selectedOptionId);

  return selectedOption?.value;
};

export default getSelectedOptionName;
