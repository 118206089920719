/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import isExternalUrl from 'commons/isExternalUrl';
import normalizeInternalLink from 'commons/normalizeInternalLink';

const CtaWrapper = React.forwardRef(
  ({ as: CustomElement = 'button', to, ...otherProps }, ref) => {
    if (!to) {
      // If there is no "to" prop, render a custom element - a button by default
      return <CustomElement ref={ref} {...otherProps} />;
    }

    if (!isExternalUrl(to)) {
      // If the "to" prop is an internal URL, render a Reach Router Link
      // element to avoid a full page reload when navigating
      return <Link to={normalizeInternalLink(to)} ref={ref} {...otherProps} />;
    }

    // If the "to" prop is an external URL, render a standard anchor tag
    return (
      <a
        href={to}
        rel="noopener noreferrer"
        target="_blank"
        ref={ref}
        {...otherProps}
      />
    );
  }
);

CtaWrapper.propTypes = {
  as: PropTypes.string,
  to: PropTypes.string,
};

export default CtaWrapper;
