import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FB_LOGIN_FAVORITES } from 'commons/constants';
import { logError } from 'commons/logger';
import LoginSidebar from 'components/consumer/LoginSidebar/LoginSidebar';
import { setItemInStorage, removeItemInStorage } from 'commons/localStorage';
import browserStorage from '../browser-storage';

const FavoriteViewModal = ({
  optionValueId = null,
  onClose,
  productId,
  productName,
  swatchOption,
  isSideBarVisible = false,
}) => {
  useEffect(() => {
    if (isSideBarVisible && !optionValueId) {
      setItemInStorage(FB_LOGIN_FAVORITES, {
        productID: productId,
      });
    }
  }, [isSideBarVisible, optionValueId, productId, productName, swatchOption]);

  let loginSidebarProps = {
    show: isSideBarVisible,
    wishListProduct: productName,
    onClose,
    onLogin: async () => {
      try {
        onClose();
        browserStorage().removeItem('favoriteClickedProduct');
        removeItemInStorage(FB_LOGIN_FAVORITES);
      } catch (e) {
        logError(e);
      }
    },
  };
  if (optionValueId) {
    loginSidebarProps = {
      show: isSideBarVisible,
      onClose,
    };
  }

  return <LoginSidebar {...loginSidebarProps} />;
};

FavoriteViewModal.propTypes = {
  optionValueId: PropTypes.number,
  onClose: PropTypes.func,
  productId: PropTypes.number,
  productName: PropTypes.string,
  swatchOption: PropTypes.object,
  isSideBarVisible: PropTypes.bool,
};

export default FavoriteViewModal;
