import React from 'react';
import PropTypes from 'prop-types';

import { colorGray } from 'styles/layout/colors';

const IconBag = ({
  color = colorGray,
  height = 19,
  title = 'Cart',
  width = 18,
}) => (
  <svg
    aria-hidden="true"
    className="relative top-[-1px]"
    height={height}
    title={title}
    viewBox="0 0 18 19"
    width={width}
  >
    <g fill="none" fillRule="evenodd">
      <title>{title}</title>
      <g stroke={color} strokeWidth="1.5" transform="translate(-3 -2)">
        <g transform="translate(3 3)">
          <g>
            <path d="M13.5,4.42285714 C13.5,1.98018059 11.4852814,0 9,0 C6.51471863,0 4.5,1.98018059 4.5,4.42285714" />
            <rect height="13.125" rx="2" width="16.5" x="0.75" y="4.125" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconBag.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

export default IconBag;
