import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import requestIdleCallback from 'commons/requestIdleCallback';
import ProductCardGridUI from 'components/consumer/ProductCardGridUI';
import LoadMoreButton from 'components/consumer/LoadMoreButton';
import VisuallyHidden from 'components/consumer/VisuallyHidden';
import useProductListingProductsOnDisplay from 'hooks/useProductListingData/useProductListingProductsOnDisplay';

import ProductsGridLoading from './components/ProductsGridLoading';
import ProductsGrid from './components/ProductsGrid';

const ProductsGridStatic = ({
  className,
  columnsOnDesktop = '4',
  columnsOnMobile = '2',
  hasMoreProducts,
  layout = '',
  listName,
  loading,
  loadingMore,
  loadMore,
  maxCardsCount,
  productsData,
}) => {
  const itemContainerRef = useRef();

  const { productsOnDisplay, slug } = useProductListingProductsOnDisplay();

  const productsToShowWithShowroomSlug = useMemo(
    () =>
      productsData.map(product => ({
        ...product,
        onDisplayInShowroomSlug: productsOnDisplay.includes(product.id)
          ? slug
          : undefined,
      })),
    [productsData, productsOnDisplay, slug]
  );

  useEffect(() => {
    if (!(loading || !loadMore || !hasMoreProducts)) {
      setTimeout(() => {
        requestIdleCallback(() => {
          if (hasMoreProducts) {
            loadMore?.();
          }
        });
      }, 5000);
    }
  }, [loading, loadMore, hasMoreProducts]);

  return (
    <div
      data-xc="products-grid"
      className={classNames('md:mt-2', {
        '!w-full max-[767px]:!mt-2.5': layout === 'favorite',
        '!mt-4 md:!mt-2': listName === 'SideBar',
        [className]: !!className,
      })}
    >
      <div className="mx-auto my-0">
        {loading ? (
          <ProductsGridLoading
            cardsCount={maxCardsCount}
            columnsOnDesktop={columnsOnDesktop}
            columnsOnMobile={columnsOnMobile}
            itemContainerRef={itemContainerRef}
          />
        ) : (
          <>
            <ProductCardGridUI
              columnsOnDesktop={columnsOnDesktop ?? 4}
              columnsOnMobile={columnsOnMobile ?? 2}
              limitWidth={false}
              containerRef={itemContainerRef}
            >
              <ProductsGrid
                productsData={productsToShowWithShowroomSlug}
                loading={loadingMore}
                maxCardsCount={maxCardsCount}
                layout={layout}
              />
            </ProductCardGridUI>

            {hasMoreProducts && !!loadMore && (
              <>
                <VisuallyHidden>
                  On activating the Load more products button, the content above
                  will be updated
                </VisuallyHidden>

                <LoadMoreButton
                  onClick={() => {
                    const indexToFocus = productsData.length;
                    loadMore();

                    setTimeout(() => {
                      const elementToFocus =
                        itemContainerRef.current?.children[indexToFocus];
                      if (elementToFocus) {
                        elementToFocus.focus();
                      }
                    }, 10);
                  }}
                  aria-label="Load more products"
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

ProductsGridStatic.propTypes = {
  className: PropTypes.string,
  columnsOnDesktop: PropTypes.oneOf(['2', '3', '4']),
  columnsOnMobile: PropTypes.oneOf(['1', '2']),
  hasMoreProducts: PropTypes.bool,
  layout: PropTypes.string,
  listName: PropTypes.string,
  loading: PropTypes.bool,
  loadMore: PropTypes.func,
  loadingMore: PropTypes.bool,
  maxCardsCount: PropTypes.number,
  productsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
};

export default ProductsGridStatic;
