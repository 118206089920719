import React from 'react';
import PropTypes from 'prop-types';
import NotificationBannerSkeleton from './NotificationBannerSkeleton';

const UserOrdersSubmitReviewPhotoBanner = ({ submitType, productName }) => (
  <NotificationBannerSkeleton
    title="Congrats! Submission successful."
    body={
      <>
        Your {submitType} has been submitted for {productName}.
      </>
    }
  />
);

UserOrdersSubmitReviewPhotoBanner.propTypes = {
  submitType: PropTypes.string,
  productName: PropTypes.string,
};

export default UserOrdersSubmitReviewPhotoBanner;
