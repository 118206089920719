import { useSetAtom } from 'jotai';

import { useCallback } from 'react';
import { getUserLocationWithShortKeys } from 'commons/location';
import userLocationAtom from './userLocationAtom';

const DEFAULT_LOCATION = {};

const useSetUserLocation = () => {
  const setUserLocationAtom = useSetAtom(userLocationAtom);

  // This hook converts the incoming userLocation object to an object with
  // short keys, stringifies it, then stores it in the Jotai atom. The atom
  // updates the cookie value every time the state is updated.
  const setUserLocation = useCallback(
    longLocation => {
      const shortLocation = longLocation
        ? getUserLocationWithShortKeys(longLocation)
        : DEFAULT_LOCATION;

      const shortLocationStringified = JSON.stringify(shortLocation);
      setUserLocationAtom(shortLocationStringified);
    },
    [setUserLocationAtom]
  );

  return setUserLocation;
};

export default useSetUserLocation;
