import getPath from 'commons/getPath';

// This function is used to get a product slug from the product url, e.g.
// running getSlugFromProductUrl("https://joybird.com/sofas/lewis-sofa/") will
// return "lewis-sofa"
const getSlugFromProductUrl = productUrl => {
  if (!productUrl) {
    return null;
  }

  const path = getPath(productUrl);
  const pathArray = path.split('/').filter(Boolean);
  const slug = pathArray.pop() ?? null;

  return slug;
};

export default getSlugFromProductUrl;
