import { DEFAULT_WAREHOUSE_ID } from 'commons/constants';
import useSuspenseQuery from 'commons/useSuspenseQuery';
import useIsBrowser from 'hooks/useIsBrowser';
import { GET_WAREHOUSE_ID_FOR_ZIPCODE } from 'mocks/queries';

const DEFAULT_VALUE = {
  error: undefined,
  loading: false,
  refetch: () => {},
  warehouseId: DEFAULT_WAREHOUSE_ID,
};

const useWarehouseIdForZipCode = zipcode => {
  const isBrowser = useIsBrowser();

  const { data, error, loading, refetch } = useSuspenseQuery(
    GET_WAREHOUSE_ID_FOR_ZIPCODE,
    {
      variables: {
        zipcode,
      },
      skip: !zipcode,
    }
  );

  if (
    !zipcode ||
    !isBrowser || // Return default on server
    !data?.getWarehouseIdFromZip?.warehouseId ||
    data.getWarehouseIdFromZip.warehouseId === 'Not Found'
  ) {
    return DEFAULT_VALUE;
  }

  return {
    error,
    loading: loading && !data,
    refetch,
    warehouseId:
      data?.getWarehouseIdFromZip?.warehouseId ?? DEFAULT_WAREHOUSE_ID,
  };
};

export default useWarehouseIdForZipCode;
