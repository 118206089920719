import React from 'react';
import PropTypes from 'prop-types';

import IconHeart2 from 'components/icons/iconHeart2';
import IconBag from 'components/icons/IconBag';
import IconSearch from 'components/icons/IconSearch';
import IconUserAccount from 'components/icons/iconUserAccount';
import ToolbarIconButton from 'components/consumer/ToolbarIconButton';
import MobileAndTabletOnly from 'components/consumer/MobileAndTabletOnly';
import DesktopOnly from 'components/consumer/DesktopOnly';

const HeaderToolbarUI = ({
  bagCount,
  favoriteCount,
  accountTo,
  onBagClick = () => {},
  onSearchClick = () => {},
  onWishlistClick = () => {},
}) => (
  <div className="flex relative right-[-10px] [&>*]:ml-[2px] [&>*:first-child]:ml-0">
    <MobileAndTabletOnly>
      <ToolbarIconButton
        data-xc="account"
        icon={IconUserAccount}
        to={accountTo}
        title="Account"
        className="lg:hidden"
      />
    </MobileAndTabletOnly>

    <DesktopOnly>
      <ToolbarIconButton
        data-xc="search"
        icon={IconSearch}
        onClick={onSearchClick}
        title="Search"
        className="max-[1023px]:hidden"
      />
    </DesktopOnly>

    <ToolbarIconButton
      badgeColor="red"
      count={favoriteCount}
      data-xc="wishlist"
      icon={IconHeart2}
      onClick={onWishlistClick}
      title="Wishlist"
      to="/wishlist/"
    />
    <ToolbarIconButton
      badgeColor="brand"
      count={bagCount}
      data-xc="cart"
      icon={IconBag}
      onClick={onBagClick}
      title="Bag"
    />
  </div>
);

HeaderToolbarUI.propTypes = {
  bagCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  favoriteCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  accountTo: PropTypes.string,
  onBagClick: PropTypes.func,
  onSearchClick: PropTypes.func,
  onWishlistClick: PropTypes.func,
};

export default HeaderToolbarUI;
