import React from 'react';
import PropTypes from 'prop-types';

import useToggleCart from 'global-state/isCartOpen/useToggleCart';
import useBagCount from 'hooks/useBagCount';
import useFavoriteCount from 'hooks/useFavoriteCount';
import useToggleIsSearchOpen from 'global-state/isSearchOpen/useToggleIsSearchOpen';
import HeaderToolbarUI from './HeaderToolbarUI';

const HeaderToolbar = ({ accountTo }) => {
  const toggleCart = useToggleCart();

  const bagCount = useBagCount();
  const favoriteCount = useFavoriteCount();
  const toggleIsSearchOpen = useToggleIsSearchOpen();

  return (
    <HeaderToolbarUI
      bagCount={bagCount}
      favoriteCount={favoriteCount}
      accountTo={accountTo}
      onBagClick={toggleCart}
      onSearchClick={toggleIsSearchOpen}
    />
  );
};

HeaderToolbar.propTypes = {
  accountTo: PropTypes.string,
};

export default HeaderToolbar;
