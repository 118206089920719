import React from 'react';
import PropTypes from 'prop-types';

import SidebarModal from 'components/consumer/SidebarModal';
import withBrowserOnly from 'components/hoc/withBrowserOnly';
import LoginContainer from './login-container';

const LoginSidebar = ({
  show = false,
  onLogin = () => undefined,
  onClose = () => undefined,
  wishListProduct = null,
  fullHeightOnDesktop = false,
}) => (
  <SidebarModal isOpen={show} onClose={onClose} mobileIsFullHeight>
    <LoginContainer
      noPadding
      onLogin={onLogin}
      onClose={onClose}
      wishListProduct={wishListProduct}
      isSidebarOpen={show}
      fullHeightOnDesktop={fullHeightOnDesktop}
    />
  </SidebarModal>
);

LoginSidebar.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onLogin: PropTypes.func,
  wishListProduct: PropTypes.string,
  fullHeightOnDesktop: PropTypes.bool,
};

export default withBrowserOnly(LoginSidebar);
