import React from 'react';
import PropTypes from 'prop-types';
import ProductCardGridUI from 'components/consumer/ProductCardGridUI';
import GridLoading from './GridLoading';

const ProductsGridLoading = ({
  cardsCount,
  columnsOnDesktop = '4',
  columnsOnMobile = '2',
  itemContainerRef,
}) => (
  <ProductCardGridUI
    columnsOnDesktop={columnsOnDesktop}
    columnsOnMobile={columnsOnMobile}
    limitWidth={false}
    containerRef={itemContainerRef}
  >
    <GridLoading cardsCount={cardsCount} />
  </ProductCardGridUI>
);

ProductsGridLoading.propTypes = {
  cardsCount: PropTypes.number,
  columnsOnDesktop: PropTypes.oneOf(['1', '2', '3', '4', 'auto']),
  columnsOnMobile: PropTypes.oneOf(['1', '2']),
  itemContainerRef: PropTypes.object,
};

export default ProductsGridLoading;
