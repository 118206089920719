import { useAtomValue } from 'jotai';

import isTradeUserSidebarOpenAtom from './isTradeUserSidebarOpenAtom';

const useIsTradeUserSidebarOpen = () => {
  const isTradeUserSidebarOpenValue = useAtomValue(isTradeUserSidebarOpenAtom);
  return isTradeUserSidebarOpenValue;
};

export default useIsTradeUserSidebarOpen;
