import React from 'react';
import cx from 'classnames';
import rafThrottle from 'commons/rafThrottle';

import isPDP from 'commons/isPDP';
import getScrollPositionOfWindowOrBody from 'commons/getScrollPositionOfWindowOrBody';

import CartModal from 'components/nav/shared/CartModal';
import ConsumerHeaderMobile from './ConsumerHeaderMobile';
import MobileAndTabletOnly from '../MobileAndTabletOnly';

class ConsumerHeaderMobileWrapper extends React.Component {
  headerRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      lastScrollPos: 0,
      hideStickyHeader: false,
      isOnPDP: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.throttledHandleScroll, {
      passive: true,
    });
    document.body.addEventListener('scroll', this.throttledHandleScroll, {
      passive: true,
    });

    // Due to progressive page loading, the PDP elements may not exist in DOM
    // immediately but we want to know as soon as possible
    const checkIfPDP = () => {
      const isOnPDP = isPDP();
      this.setState({ isOnPDP });
    };

    checkIfPDP();
    setTimeout(checkIfPDP, 100);
    setTimeout(checkIfPDP, 200);
    setTimeout(checkIfPDP, 500);
    setTimeout(checkIfPDP, 1000);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttledHandleScroll, {
      passive: true,
    });
    document.body.removeEventListener('scroll', this.throttledHandleScroll, {
      passive: true,
    });
  }

  handleScroll = () => {
    const scrollPos = getScrollPositionOfWindowOrBody();
    const { lastScrollPos } = this.state;
    const isScrollingDown = scrollPos > lastScrollPos;

    this.setState({
      lastScrollPos: scrollPos,
      hideStickyHeader: isScrollingDown && scrollPos > 80,
    });
  };

  // eslint-disable-next-line react/sort-comp
  throttledHandleScroll = rafThrottle(this.handleScroll);

  render() {
    const isStaticHeaderVariant = this.state.isOnPDP;
    const { hideStickyHeader } = this.state;

    return (
      <>
        <div
          className={cx(
            'lg:hidden',
            'mt-0 transition-[transform_.2s_ease-in-out] z-[1001] lg:z-[1001] [&_header]:transition-[background-color_.1s_ease-in] [&_header]:w-full [&_header]:z-[3] [&_header]:lg:bg-white [&_header:hover]:lg:[border-bottom:none]',
            {
              'sticky top-0': !isStaticHeaderVariant,
              relative: isStaticHeaderVariant,
              'transform-[translateY(-100%)]': hideStickyHeader,
              '!transform-[translateY(0)]':
                isStaticHeaderVariant && hideStickyHeader,
            }
          )}
        >
          <header ref={this.headerRef}>
            <MobileAndTabletOnly>
              <ConsumerHeaderMobile />
            </MobileAndTabletOnly>
          </header>
        </div>

        <CartModal />

        <div className="h-0 w-0" id="skip-to-content" />
      </>
    );
  }
}

export default ConsumerHeaderMobileWrapper;
