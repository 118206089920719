import { useEffect, useState } from 'react';

const useChatUnavailableModalState = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openChatUnavailableModal = () => {
    setIsOpen(true);
  };

  const closeChatUnavailableModal = () => {
    setIsOpen(false);
    // eslint-disable-next-line no-unused-expressions
    window?.closeChatUnavailableCallback?.();
  };

  useEffect(() => {
    // Make these setters globally available on the window object so that they
    // can be easily called from within the Consumer code
    window.openChatUnavailableModal = openChatUnavailableModal;
    window.closeChatUnavailableModal = closeChatUnavailableModal;
  }, []);

  return {
    isOpen,
    open: openChatUnavailableModal,
    close: closeChatUnavailableModal,
  };
};

export default useChatUnavailableModalState;
