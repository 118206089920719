import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import usePostHogFeatureFlag from 'hooks/usePostHogFeatureFlag';
import lodash from 'lodash';
import FixedWidthContainer from 'components/consumer/FixedWidthContainer';
import DesktopNavLinkGroup from 'components/nav/DesktopHeader/components/DesktopNavLinkGroup';
import DesktopNavCategorySectionContent from '../DesktopNavCategorySectionContent';

const DesktopNavCategorySectionUI = ({
  items = [],
  secondaryItems = [],
  ctaLabel,
  ctaUrl,
  customContent: categoryCustomContent,
  id,
  image: categoryImage,
  imageLabel: categoryImageLabel,
  isOpen = false,
  selectedSubcategory: selectedSubcategoryId,
  setIsTitleHovered,
  setIsSectionHovered,
}) => {
  const categoryNavTest = usePostHogFeatureFlag('navbar-categories-vs-rooms');
  const selectedSubcategory = selectedSubcategoryId
    ? items.find(item => item.id === selectedSubcategoryId) ||
      secondaryItems.find(item => item.id === selectedSubcategoryId) ||
      null
    : null;
  const selectedSubcategoryItems = selectedSubcategory?.items;
  const customContent = selectedSubcategory
    ? selectedSubcategory.customContent
    : categoryCustomContent;
  const contentId = `${id}-${selectedSubcategoryId || ''}`;
  // If there are more than 6 items, split into chunks of 6 and render a
  // column of links using DesktopNavLinkGroup for each chunk
  const chunkedItems = items.length > 6 ? lodash.chunk(items, 6) : items;

  return (
    <div
      className={classNames(
        'bg-white left-0 pointer-events-none absolute right-0 top-0',
        {
          'opacity-0': !isOpen,
          'pointer-events-auto': isOpen,
        }
      )}
      hidden={!isOpen}
    >
      <FixedWidthContainer className="relative lg:!max-w-[980px]">
        <div
          className={classNames(
            'bg-white flex justify-between m-0 min-h-[508px] pt-[2.625rem]',
            {
              'pt-0': categoryNavTest === 'test',
            }
          )}
          data-xc="DesktopNavCategorySection"
          id={id}
        >
          <div className="flex-1">
            <div
              className={classNames('flex [&_>_*]:flex-1', {
                'flex-col': categoryNavTest === 'test',
              })}
            >
              {categoryNavTest !== 'test' && items.length > 6 ? (
                chunkedItems.map((chunkOfItems, index) => (
                  <div key={chunkOfItems?.[0]?.id}>
                    <DesktopNavLinkGroup
                      categoryId={id}
                      ctaLabel={index === 0 ? ctaLabel : null} // To show the button in only first column
                      ctaUrl={index === 0 ? ctaUrl : null}
                      items={chunkOfItems}
                      secondaryItems={secondaryItems}
                      selectedItem={selectedSubcategoryId}
                      setIsSectionHovered={setIsSectionHovered}
                      setIsTitleHovered={
                        contentId.includes('decor')
                          ? () => {}
                          : setIsTitleHovered
                      }
                    />
                  </div>
                ))
              ) : (
                <div>
                  <DesktopNavLinkGroup
                    categoryId={id}
                    ctaLabel={ctaLabel}
                    ctaUrl={ctaUrl}
                    items={items}
                    secondaryItems={secondaryItems}
                    selectedItem={selectedSubcategoryId}
                    setIsSectionHovered={setIsSectionHovered}
                    setIsTitleHovered={setIsTitleHovered}
                  />
                </div>
              )}
            </div>
          </div>

          <DesktopNavCategorySectionContent
            categoryImage={categoryImage}
            categoryImageLabel={categoryImageLabel}
            categoryUrl={ctaUrl}
            contentId={contentId}
            customContent={customContent}
            selectedSubcategory={selectedSubcategory}
            selectedSubcategoryItems={selectedSubcategoryItems}
            selectedSubcategoryId={selectedSubcategoryId}
            onMouseEnter={() => {
              setIsSectionHovered(true);
            }}
            onMouseLeave={() => {
              setIsSectionHovered(false);
            }}
          />
        </div>
      </FixedWidthContainer>
    </div>
  );
};

DesktopNavCategorySectionUI.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      isNew: PropTypes.bool,
      label: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  secondaryItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      isNew: PropTypes.bool,
      label: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  customContent: PropTypes.node,
  id: PropTypes.string,
  image: PropTypes.string,
  imageLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  selectedSubcategory: PropTypes.string,
  setIsTitleHovered: PropTypes.func,
  setIsSectionHovered: PropTypes.func,
};

export default DesktopNavCategorySectionUI;
