import React from 'react';
import loadable from '@loadable/component';

import BrowserOnly from 'components/consumer/BrowserOnly';

const CartModalUI = loadable(() => import('./CartModalUI'));

const CartModal = () => (
  <BrowserOnly>
    <CartModalUI />
  </BrowserOnly>
);

export default CartModal;
