import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DesktopNavShopByListContainer = ({
  children,
  className = '',
  isImageButtonColumn = false,
}) => (
  <div
    className={classNames(
      `h-full flex-[1_1] mr-0 min-[1400px]:min-w-[calc(355px_+_1rem)] min-[1400px]:pr-4 min-[1400px]:flex-[unset] ${className}`,
      {
        '!w-[35%] min-w-[unset]': isImageButtonColumn,
      }
    )}
  >
    {children}
  </div>
);

DesktopNavShopByListContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isImageButtonColumn: PropTypes.bool,
};

export default DesktopNavShopByListContainer;
