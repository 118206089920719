import { useMemo } from 'react';

import useBrowserGeolocation from 'hooks/useBrowserGeolocation';
import useUserLocationFromCoords from 'hooks/useUserLocationFromCoords';
import useWarehouseIdForZipCode from 'hooks/useWarehouseIdForZipCode';
import { DEFAULT_WAREHOUSE_ID } from 'commons/constants';

const EMPTY_LOCATION = {};

// This hook is used to get the user's location using the browser geolocation
// API. The values are returned in a standard format to allow the
// useRefreshUserLocation hook to return the best available location for the
// user.
const useLocationFromBrowser = ({ skip = false }) => {
  const {
    error: coordsFromBrowserError,
    latitude: latitudeFromBrowser,
    longitude: longitudeFromBrowser,
    isLoading: isCoordsFromBrowserLoading,
  } = useBrowserGeolocation(!skip);

  // If needed, get zip code & address from location returned by browser geolocation API
  const enableLocationRequestForBrowser =
    !skip &&
    latitudeFromBrowser &&
    longitudeFromBrowser &&
    !coordsFromBrowserError;

  const latAndLongForLocationRequest = enableLocationRequestForBrowser
    ? {
        latitude: latitudeFromBrowser,
        longitude: longitudeFromBrowser,
      }
    : undefined;

  const {
    error: locationFromBrowserError,
    isLoading: isLocationLoading,
    location: locationFromBrowser,
  } = useUserLocationFromCoords(latAndLongForLocationRequest);

  // If locationFromCloudfront includes a country name, and it is not the
  // United States, this as an international user so we should assign them to
  // the default warehouse ID (12) and set an empty location object.
  const isInternationalUser =
    !skip &&
    !!locationFromBrowser?.country &&
    locationFromBrowser?.country !== 'United States';

  // If needed, get warehouse ID for browser location
  const enableWarehouseIdForBrowserLocation =
    enableLocationRequestForBrowser &&
    !!locationFromBrowser?.zip &&
    !locationFromBrowserError &&
    !isInternationalUser;

  const {
    error: warehouseIdForBrowserLocationError,
    loading: isWarehouseIdForBrowserLocationLoading,
    warehouseId: warehouseIdForBrowserLocation,
  } = useWarehouseIdForZipCode(
    enableWarehouseIdForBrowserLocation ? locationFromBrowser?.zip : undefined
  );

  const shouldUseLocation =
    !skip &&
    enableWarehouseIdForBrowserLocation &&
    !warehouseIdForBrowserLocationError;

  const location = useMemo(() => {
    if (!shouldUseLocation) {
      return undefined;
    }

    if (isInternationalUser) {
      return EMPTY_LOCATION;
    }

    const { city, country, state, stateShortName, zip } = locationFromBrowser;

    return {
      city,
      country,
      state,
      stateShortName,
      zip,
    };
  }, [isInternationalUser, locationFromBrowser, shouldUseLocation]);

  const warehouseId = useMemo(() => {
    if (!shouldUseLocation) {
      return undefined;
    }

    if (isInternationalUser) {
      return DEFAULT_WAREHOUSE_ID;
    }

    return warehouseIdForBrowserLocation;
  }, [isInternationalUser, shouldUseLocation, warehouseIdForBrowserLocation]);

  return {
    isLoading: !skip
      ? isCoordsFromBrowserLoading ||
        isLocationLoading ||
        isWarehouseIdForBrowserLocationLoading
      : false,
    location,
    shouldUseLocation,
    warehouseId,
  };
};

export default useLocationFromBrowser;
