import React from 'react';
import PropTypes from 'prop-types';
import IconQuality from 'components/icons/iconQuality';
import NotificationBannerSkeleton from './NotificationBannerSkeleton';

const ForgotPassword = ({ email, onClose, isSuccess }) =>
  isSuccess ? (
    <NotificationBannerSkeleton
      title="Your password is reset successfully."
      body="Please login again to proceed."
      icon={<IconQuality color="#27919C" style={{ width: 24, height: 24 }} />}
      onClose={onClose}
    />
  ) : (
    <NotificationBannerSkeleton
      title={`A link to reset your password has been sent to ${email}.`}
      body="This link will expire in the next 48 hours."
      icon={<IconQuality color="#27919C" style={{ width: 24, height: 24 }} />}
      onClose={onClose}
    />
  );

ForgotPassword.propTypes = {
  email: PropTypes.string,
  onClose: PropTypes.func,
  isSuccess: PropTypes.bool,
};

export default ForgotPassword;
