import get from 'lodash/get';

const getOptionType = (optionData, optionTypePath) => {
  const optionTypeId = get(optionData, optionTypePath) ?? null;
  const options = optionData?.options || [];

  const optionType = options.find(
    option => optionTypeId && option.id === optionTypeId
  );

  return {
    optionTypeId,
    optionTypeName: optionType?.name || null,
  };
};

export default getOptionType;
