import React from 'react';
import PropTypes from 'prop-types';
import NotificationBannerSkeleton from './NotificationBannerSkeleton';

const PasswordReset = ({ onClose }) => (
  <NotificationBannerSkeleton
    title="Success!"
    body={<span>Your password has been been changed.</span>}
    onClose={onClose}
  />
);

PasswordReset.propTypes = {
  onClose: PropTypes.func,
};

export default PasswordReset;
