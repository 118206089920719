import useCartAndToken from 'hooks/useCartAndToken';

const useBagCount = () => {
  const { cart } = useCartAndToken();

  const bagCount =
    cart && cart.length
      ? cart.reduce((acc, { quantity = 0 }) => acc + quantity, 0)
      : 0;

  return bagCount;
};

export default useBagCount;
