import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';

import Link from 'components/consumer/Link';
import MenuButton from 'components/consumer/menu-button';
import IconHeart2 from 'components/icons/iconHeart2';
import IconUserAccount from 'components/icons/iconUserAccount';
import LogoLink from 'components/consumer/LogoLink';
import avo from 'analytics/avo';
import MobileNavbarModalUI from 'components/nav/MobileNavbarModal/MobileNavbarModalUI';
import useFavoriteCount from 'hooks/useFavoriteCount';
import useUserData from 'data-hooks/useUserData';
import IcCart from './ic-cart';
import InstantSearchMobile from '../consumer-search/instant-search/InstantSearchMobile';
import BrowserOnly from '../BrowserOnly';

const ConsumerHeaderMobile = () => {
  const targetElement = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const favoriteCount = useFavoriteCount();
  const { isLoggedIn } = useUserData();

  const setSearchEventParams = useCallback(
    ({ searchQuery: updatedSearchQuery, currentPage: updatedCurrentPage }) => {
      setSearchQuery(updatedSearchQuery);
      setCurrentPage(updatedCurrentPage);
    },
    []
  );

  const logSearchToAvo = useCallback(() => {
    if (isMenuOpen === true && searchQuery?.length > 1 && currentPage) {
      avo.productsSearched({
        query: searchQuery,
        location: currentPage,
        searchTarget: avo.SearchTarget.EXIT_SEARCH,
      });
    }
  }, [currentPage, isMenuOpen, searchQuery]);

  const toggleMenu = useCallback(() => {
    logSearchToAvo();
    setIsMenuOpen(prevMenu => !prevMenu);
  }, [logSearchToAvo]);

  const closeMenu = useCallback(() => {
    logSearchToAvo();

    setIsMenuOpen(false);
    setSearchQuery(null);
    setCurrentPage(null);
  }, [logSearchToAvo]);

  const heartColor = favoriteCount > 0 ? '#d86262' : undefined;

  return (
    <div
      className={classNames(
        'h-[3.75rem] w-full hidden z-[998] [-webkit-tap-highlight-color:transparent] max-[1023px]:[transition:transform_1s_ease-in] max-[1023px]:block',
        '[&_a]:no-underline [&_a]:text-gray',
        {
          expanded: isMenuOpen,
        }
      )}
      ref={targetElement}
    >
      <div className="relative items-center justify-center h-[3.75rem] w-full px-[3.333vw] py-0 z-[999] max-[1023px]:bg-white max-[1023px]:flex max-[1023px]:[transition:transform_.25s_ease-in-out,background-color_.25s_ease-in-out] max-[1023px]:px-5 max-[1023px]:py-0 hover:max-[1023px]:[border-bottom:none]">
        <div className="items-center flex h-full justify-center left-0 absolute top-0 w-[60px] rounded-[2rem] z-[1] hover:bg-gray-light7 active:bg-gray-light7 focus-visible:bg-gray-light7">
          <MenuButton onClick={toggleMenu} open={isMenuOpen} />
        </div>

        <LogoLink className="hover:bg-gray-light7" />

        <div className="absolute right-5 flex items-center justify-end">
          <div className="cursor-pointer bg-white rounded-[2rem] box-border w-12 h-12 flex justify-center items-center relative hover:bg-gray-light7 active:bg-gray-light7 focus-visible:bg-gray-light7 mr-0 [&_a]:flex">
            <Link
              to={isLoggedIn ? '/customer/dashboard/' : '/sign-in/'}
              className="[background:none] border-0 focus-visible:outline-offset-4"
            >
              <IconUserAccount />
            </Link>
          </div>

          <div className="cursor-pointer bg-white rounded-[2rem] box-border w-12 h-12 flex justify-center items-center relative hover:bg-gray-light7 active:bg-gray-light7 focus-visible:bg-gray-light7 mr-0 [&_a]:flex">
            <Link
              to="/wishlist/"
              className="[background:none] border-0 focus-visible:outline-offset-4"
            >
              <IconHeart2
                fill={heartColor || undefined}
                color={heartColor || undefined}
              />
            </Link>
          </div>

          <IcCart isMobile isFixedTopNav />
        </div>
      </div>

      <BrowserOnly>
        <MobileNavbarModalUI
          isOpen={isMenuOpen}
          onClose={closeMenu}
          header={
            <InstantSearchMobile
              placeholder="Search Joybird"
              toggleMenu={closeMenu}
              setSearchEventParams={setSearchEventParams}
              variant="consumer-header-mobile"
            />
          }
        />
      </BrowserOnly>
    </div>
  );
};

export default ConsumerHeaderMobile;
