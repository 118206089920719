import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import useScreen from 'hooks/useScreen';

const QuickViewModalDesktop = loadable(() =>
  import('./components/QuickViewModalDesktop')
);
const QuickViewModalMobile = loadable(() =>
  import('./components/QuickViewModalMobile')
);

const QuickViewModalUI = ({
  description,
  dimensions,
  isOpen,
  name,
  onClose,
  onMoreMaterialsClick,
  path,
  slug,
  isOutOfStock,
}) => {
  const { isMobile } = useScreen();

  return isMobile ? (
    <QuickViewModalMobile
      dimensions={dimensions}
      isOpen={isOpen}
      name={name}
      onClose={onClose}
      onMoreMaterialsClick={onMoreMaterialsClick}
      path={path}
      slug={slug}
      isOutOfStock={isOutOfStock}
    />
  ) : (
    <QuickViewModalDesktop
      description={description}
      dimensions={dimensions}
      isOpen={isOpen}
      name={name}
      onClose={onClose}
      onMoreMaterialsClick={onMoreMaterialsClick}
      path={path}
      slug={slug}
      isOutOfStock={isOutOfStock}
    />
  );
};

QuickViewModalUI.propTypes = {
  description: PropTypes.string,
  dimensions: PropTypes.string,
  isOpen: PropTypes.bool,
  name: PropTypes.string,
  onClose: PropTypes.func,
  onMoreMaterialsClick: PropTypes.func,
  path: PropTypes.string,
  slug: PropTypes.string,
  isOutOfStock: PropTypes.bool,
};

export default QuickViewModalUI;
