import { useAtomValue } from 'jotai';

import isSearchOpenAtom from './isSearchOpenAtom';

const useIsSearchOpen = () => {
  const isSearchOpenValue = useAtomValue(isSearchOpenAtom);
  return isSearchOpenValue;
};

export default useIsSearchOpen;
