import React from 'react';
import PropTypes from 'prop-types';

import { colorGray } from 'styles/layout/colors';

const IconInfoNew = ({
  'aria-hidden': ariaHidden = 'false',
  className = '',
  color = colorGray,
  height = 32,
  width = 32,
}) => (
  <svg
    aria-hidden={ariaHidden}
    className={className}
    fill="none"
    height={height}
    viewBox="7 7 18 18"
    width={width}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 16C25 20.9696 20.9696 25 16 25C11.0295 25 7 20.9701 7 16C7 11.029 11.029 7 16 7C20.9701 7 25 11.0295 25 16ZM23 16C23 12.1341 19.8655 9 16 9C12.1336 9 9 12.1336 9 16C9 19.8655 12.1341 23 16 23C19.865 23 23 19.865 23 16ZM17.0535 20.7429H14.9455V15.4576H17.0535V20.7429ZM17.0535 13.1763H14.9455V11.2576H17.0535V13.1763Z"
      fill={color}
    />
  </svg>
);

IconInfoNew.propTypes = {
  'aria-hidden': PropTypes.oneOf(['true', 'false']),
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default IconInfoNew;
