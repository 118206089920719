import React from 'react';
import PropTypes from 'prop-types';

import usePostHogFeatureFlag from 'hooks/usePostHogFeatureFlag';
import SubcategoryCardGrid from 'components/nav/shared/SubcategoryCardGrid';
import SubcategoryCard from 'components/nav/shared/SubcategoryCard';
import DesktopNavCategorySectionImage from 'components/nav/DesktopHeader/components/DesktopNavCategorySectionImage';

const DesktopNavCategorySectionContent = ({
  categoryDescription,
  categoryImage,
  categoryImageLabel,
  categoryUrl,
  contentId,
  customContent,
  onMouseEnter,
  onMouseLeave,
  selectedSubcategory,
  selectedSubcategoryItems,
}) => {
  const imageToDisplay = selectedSubcategory?.image || categoryImage;
  const imageToDisplayLabel = selectedSubcategory?.label || categoryImageLabel;
  const imageToDisplayLink = selectedSubcategory?.url || categoryUrl;
  const imageDescription =
    selectedSubcategory?.description || categoryDescription;
  const imageDescriptionTextColor = selectedSubcategory?.descriptionTextColor;
  const categoryNavTest = usePostHogFeatureFlag('navbar-categories-vs-rooms');

  const wrapperProps = {
    // If we have decor then we display two option column that's why have to reduce image size to show bigger column width
    className: `w-[635px] ${
      categoryNavTest === 'test' ? 'flex flex-col justify-center' : ''
    } ${contentId.includes('decor') ? '!w-[540px]' : ''}`,
    'data-nested-popup': 'true',
    id: contentId,
    onMouseEnter,
    onMouseLeave,
  };

  if (selectedSubcategoryItems) {
    return (
      <div {...wrapperProps}>
        <SubcategoryCardGrid>
          {selectedSubcategoryItems.map(
            ({ id: subcategoryId, image: subcategoryImage, label, url }) => (
              <SubcategoryCard
                key={subcategoryId}
                image={subcategoryImage}
                label={label}
                to={url}
              />
            )
          )}
        </SubcategoryCardGrid>
      </div>
    );
  }

  if (customContent) {
    return <div {...wrapperProps}>{customContent}</div>;
  }

  if (imageToDisplay) {
    return (
      <div {...wrapperProps}>
        <DesktopNavCategorySectionImage
          description={imageDescription}
          descriptionTextColor={imageDescriptionTextColor}
          image={imageToDisplay}
          label={imageToDisplayLabel}
          to={imageToDisplayLink}
        />
      </div>
    );
  }

  return null;
};

DesktopNavCategorySectionContent.propTypes = {
  categoryDescription: PropTypes.string,
  categoryImage: PropTypes.string,
  categoryImageLabel: PropTypes.string,
  categoryUrl: PropTypes.string,
  contentId: PropTypes.string,
  customContent: PropTypes.node,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  selectedSubcategory: PropTypes.shape({
    description: PropTypes.string,
    descriptionTextColor: PropTypes.string,
    image: PropTypes.string,
    label: PropTypes.string,
    url: PropTypes.string,
  }),
  selectedSubcategoryItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      image: PropTypes.string,
      label: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

export default DesktopNavCategorySectionContent;
