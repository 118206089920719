import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { Transition } from '@headlessui/react';

import useRandomId from 'hooks/useRandomId';
import getOrCreateElementById from 'commons/getOrCreateElementById';
import MobileModal from 'components/consumer/MobileModal';
import MoveItButton from 'components/consumer/MoveItButton';
import VisuallyHidden from 'components/consumer/VisuallyHidden';
import useFocusOnOpen from 'hooks/useFocusOnOpen';
import IconJoybirdSymbol from 'components/icons/iconJoybirdSymbol';
import { colorBrand } from 'styles/layout/colors';
import withBrowserOnly from 'components/hoc/withBrowserOnly';
import useScreen from 'hooks/useScreen';

const floatingModalRootElement = getOrCreateElementById('FloatingModal-root');

const FloatingModal = ({ children, isOpen = false, onClose, title }) => {
  const { isMobile } = useScreen();

  const modalRef = useFocusOnOpen(isOpen);
  const modalTitleId = useRandomId('FloatingModal-title');

  const onContentClick = event => {
    if (
      event?.target?.tagName.toLowerCase() === 'a' &&
      event?.target?.getAttribute('target') !== '_blank'
    ) {
      // If an internal link is clicked inside the modal, call onClose()
      onClose();
    }
  };

  const innerContent = (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className="FloatingModal__inner-content" onClick={onContentClick}>
      <div className="mb-4">
        <IconJoybirdSymbol color={colorBrand} />
      </div>

      {title && (
        <h2
          className="text-xl font-bold text-gray mt-0 mx-0 mb-4"
          id={modalTitleId}
        >
          {title}
        </h2>
      )}
      <div className="text-sm text-gray-light1 [&_a]:text-brand">
        {children}
      </div>
    </div>
  );

  if (isMobile) {
    return (
      <MobileModal
        {...{
          children: innerContent,
          isFullHeight: true,
          isOpen,
          onClose,
        }}
      />
    );
  }

  return createPortal(
    <Transition
      show={isOpen}
      appear
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="bottom-8 absolute right-8 w-[300px] min-h-[450px] max-h-[650px] overflow-x-auto rounded-lg shadow-[0_2px_10px_0#ccc] bg-white pt-[4.5rem] px-8 pb-8">
        <div
          aria-hidden
          className="FloatingModal__overlay"
          onClick={onClose}
          tabIndex="-1"
        />

        <div className="FloatingModal__content">
          <div ref={modalRef} role="dialog" aria-labelledby={modalTitleId}>
            <VisuallyHidden>Dialog content starts</VisuallyHidden>

            <MoveItButton
              className="absolute right-4 top-4"
              onClick={onClose}
            />

            {innerContent}

            <VisuallyHidden>Dialog content ends</VisuallyHidden>
          </div>
        </div>
      </div>
    </Transition>,
    floatingModalRootElement
  );
};

FloatingModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default withBrowserOnly(FloatingModal);
