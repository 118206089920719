import React from 'react';
import PropTypes from 'prop-types';

import ProductCardStatic from 'components/consumer/ProductCardStatic';

const ProductGridItem = ({
  idx,
  layout,
  productItem,
}) => {
  const key = productItem?.id.toString ?? `product-item-${idx}`;

  return (
    <ProductCardStatic
      layout={layout}
      id={productItem.id}
      name={productItem.name}
      variantsToShow={productItem.variantsToShow ?? []}
      variants={productItem.variants ?? []}
      secondaryImage={productItem.secondaryImage}
      productLabel={productItem.productLabel}
      isFavorited={productItem.isFavorited}
      position={idx + 1}
      key={key}
    />
  );
};

ProductGridItem.propTypes = {
  idx: PropTypes.number,
  layout: PropTypes.oneOf([
    'full',
    'mini',
    'minimal',
    'minimal-with-attributes',
    'minimal-with-showroom',
  ]),
  productItem: PropTypes.shape({
    campaign: PropTypes.string,
    enableMobileCarousel: PropTypes.bool,
    filterStateKey: PropTypes.string,
    hidden: PropTypes.bool,
    id: PropTypes.number.isRequired,
    initialOptionId: PropTypes.string,
    isClearance: PropTypes.bool,
    isFavorited: PropTypes.bool,
    layout: PropTypes.oneOf([
      'full',
      'mini',
      'minimal',
      'minimal-with-attributes',
      'minimal-with-showroom',
    ]),
    moreVariantsCount: PropTypes.number,
    name: PropTypes.string,
    onDisplayInShowroomSlug: PropTypes.string,
    path: PropTypes.string,
    position: PropTypes.number,
    productFamily: PropTypes.string,
    productLabel: PropTypes.string,
    productType: PropTypes.string,
    quickship: PropTypes.bool,
    secondaryImage: PropTypes.string,
    secondaryImageCropData: PropTypes.object,
    slug: PropTypes.string,
    variantsToShow: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        image: PropTypes.string,
        isOutOfStock: PropTypes.bool,
        label: PropTypes.string,
        minPrice: PropTypes.number,
        originalPrice: PropTypes.number,
        path: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        quickship: PropTypes.bool,
        sku: PropTypes.string,
      })
    ),
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        image: PropTypes.string,
        isOutOfStock: PropTypes.bool,
        label: PropTypes.string,
        minPrice: PropTypes.number,
        originalPrice: PropTypes.number,
        path: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        quickship: PropTypes.bool,
        isFavorited: PropTypes.bool,
        sku: PropTypes.string,
      })
    ),
    variantsTotal: PropTypes.number,
  }),
};

export default ProductGridItem;
