
// Throttle a function by requestAnimationFrame
// Docs for original version at https://github.com/wuct/raf-throttle
const rafThrottle = funcToThrottle => {
  let requestId = null;
  let lastArgs;

  const later = thisContext => () => {
    requestId = null;
    funcToThrottle.apply(thisContext, lastArgs);
  };

  const throttled = function throttled(...args) {
    lastArgs = args;

    if (requestId === null) {
      requestId = requestAnimationFrame(later(this));
    }
  };

  throttled.cancel = () => {
    cancelAnimationFrame(requestId);
    requestId = null;
  };

  return throttled;
};

export default rafThrottle;
