import usePDPOptions from 'components/consumer/PDP/state/context/usePDPOptions';
import getOptionType from './helpers/getOptionType';
import getParamForOption from './helpers/getParamForOption';
import getSelectedOptionId from './helpers/getSelectedOptionId';
import getSelectedOptionName from './helpers/getSelectedOptionName';

const UPHOLSTERY_OPTION_PATH = 'upholsteryOptionId';
const ORIENTATION_OPTION_PATH = 'hasOrientation.optionId';

const usePDPUrl = ({ path }) => {
  const { data: optionData } = usePDPOptions();

  const optionTypePaths = [UPHOLSTERY_OPTION_PATH, ORIENTATION_OPTION_PATH];

  const productUrlQuery = optionTypePaths.reduce((queryAcc, optionTypePath) => {
    const { optionTypeId, optionTypeName } = getOptionType(
      optionData,
      optionTypePath
    );

    const selectedOptionId = getSelectedOptionId(optionData, optionTypeId);

    const selectedOptionName = getSelectedOptionName(
      optionData?.options || [],
      optionTypeId,
      selectedOptionId
    );

    const newParam = getParamForOption(optionTypeName, selectedOptionName);

    if (queryAcc && newParam) {
      return `${queryAcc}&${newParam}`;
    }

    if (newParam) {
      return newParam;
    }

    return queryAcc;
  }, '');

  if (productUrlQuery) {
    return `${path}?${productUrlQuery}`;
  }

  return path;
};

export default usePDPUrl;
