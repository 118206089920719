import React from 'react';
import PropTypes from 'prop-types';
import NotificationBannerSkeleton from './NotificationBannerSkeleton';

const TradeQuotesBanner = ({ type, quote_id = '' }) => {
  let title;
  let body;

  switch (type) {
    case 'CREATE_QUOTE':
      title = 'Success!';
      body = 'Your quote has been created successfully';
      break;
    case 'ARCHIVE_QUOTE':
      title = 'Success!';
      body = `Quote ${quote_id} has been archived successfully`;
      break;
    case 'REFRESH_QUOTE':
      title = 'Success!';
      body = `Quote ${quote_id} has been refreshed successfully`;
      break;
    default:
      break;
  }

  return <NotificationBannerSkeleton title={title} body={body} />;
};

TradeQuotesBanner.propTypes = {
  type: PropTypes.oneOf(['CREATE_QUOTE', 'ARCHIVE_QUOTE', 'REFRESH_QUOTE']),
  quote_id: PropTypes.string,
};

export default TradeQuotesBanner;
