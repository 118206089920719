import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import IconClose from 'components/icons/iconClose';

const MenuButton = ({ onClick, open }) => (
  <button
    className="bg-transparent border-0 flex items-center justify-center h-full w-full focus-visible:rounded-[3px]"
    onClick={onClick}
    type="button"
  >
    <span
      className={classNames(
        'cursor-pointer max-w-[16px] relative w-4 flex flex-col',
        {
          open,
        }
      )}
    >
      <span
        className={classNames('bg-gray h-0.5 [transition:all_.2s_ease-in]', {
          'opacity-0 scale-0': open,
        })}
      />
      <span
        className={classNames(
          'bg-gray h-0.5 [transition:all_.2s_ease-in] mx-0 my-1',
          {
            'opacity-0 scale-0': open,
          }
        )}
      />
      <span
        className={classNames('bg-gray h-0.5 [transition:all_.2s_ease-in]', {
          'opacity-0 scale-0': open,
        })}
      />
      <span
        className={classNames(
          'absolute left-0 top-0 [transition:all_.2s_ease-in] ml-0.5 [&_path]:fill-gray',
          {
            'opacity-0 scale-0': !open,
            'opacity-100 scale-100': open,
          }
        )}
      >
        <IconClose />
      </span>
    </span>
  </button>
);

MenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default MenuButton;
