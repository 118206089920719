import useSuspenseQuery from 'commons/useSuspenseQuery';
import { GET_CART } from 'mocks/queries';
import getZipcode from 'commons/getZipcode';

const useCartAndToken = () => {
  const { data, refetch } = useSuspenseQuery(GET_CART, {
    fetchPolicy: 'cache-and-network',
    variables: { zipCode: getZipcode() },
  });

  const cart = data?.getCart?.cart;
  const token = data?.getCart?.cart_token;

  return { cart, refetch, token };
};

export default useCartAndToken;
