import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import avo from 'analytics/avo';
import Link from 'components/consumer/Link';
import IconUserAccount from 'components/icons/iconUserAccount';
import usePostHogFeatureFlag from 'hooks/usePostHogFeatureFlag';
import useUserCurrentPageType from 'hooks/useUserCurrentPageType';
import JBImage from 'components/essentials/JBImage';
import FixedWidthScroller from 'components/consumer/FixedWidthScroller';

const MobileNavbarLink = ({
  children,
  id,
  isNested = false,
  to,
  currentPage,
  subLabel = '',
  images = [],
  backgroundImage,
}) => {
  const locationFromRoute = useUserCurrentPageType();
  const location = currentPage ?? locationFromRoute;
  const postHogFlag = usePostHogFeatureFlag('mobileNavbarThumbnails');

  return (
    <Link
      to={to}
      // remove when room refresh/visualizer AB test is done
      onClick={
        children === 'Room Visualizer' || children === 'Room Refresh'
          ? () => {
              avo.ctaClicked({
                text: children,
                targetUrl: to,
                cta: 'Room Visualizer',
                location,
                component: 'Navbar',
              });
            }
          : undefined
      }
      className={classNames(
        'text-lg !text-gray flex justify-start px-5 py-3 no-underline hover:underline focus-visible:rounded-[3px] focus-visible:outline-offset-[-4px] flex-col items-start max-w-full',
        {
          'pt-2 px-[42px] pb-[9px]': isNested,
          'w-full pr-0': postHogFlag === 'test' && !!backgroundImage,
        }
      )}
      id={to}
    >
      {id === 'login' && (
        <IconUserAccount className="mr-4 relative top-[-2px]" />
      )}

      {postHogFlag === 'test' && !!backgroundImage && (
        <div className="relative w-full">
          <JBImage
            className="rounded z-0 object-cover w-full"
            height={backgroundImage.imgProps.height}
            width={backgroundImage.imgProps.width}
            fit={backgroundImage.imgProps.fit}
            crop={backgroundImage.imgProps.crop}
            fpX={backgroundImage.imgProps.fpX}
            fpY={backgroundImage.imgProps.fpY}
            fpZoom={backgroundImage.imgProps.fpZoom}
            src={backgroundImage.src}
            alt={backgroundImage.name}
          />
          <section className="absolute z-10 top-0 flex flex-column p-2 max-w-[180px]">
            <span className="MobileNavbarLink__text pb-1">{children}</span>

            {!!subLabel && (
              <span className="MobileNavbarLink__subText text-xs">
                {subLabel}
              </span>
            )}
          </section>
        </div>
      )}

      {postHogFlag === 'test' && !backgroundImage && (
        <>
          <span className="MobileNavbarLink__text pb-1">{children}</span>
          {!!subLabel && (
            <span
              className={classNames('text-xs', {
                'pb-2': images.length !== 0,
              })}
            >
              {subLabel}
            </span>
          )}

          {!!images && (
            <FixedWidthScroller scrollBarHidden className="pb-0">
              {images.map(image => (
                <JBImage
                  className="rounded mr-0.5 last:m-0"
                  height={40}
                  width={70}
                  src={image.src}
                  alt={image.name}
                />
              ))}
            </FixedWidthScroller>
          )}
        </>
      )}

      {postHogFlag !== 'test' && (
        <span className="MobileNavbarLink__text">{children}</span>
      )}
    </Link>
  );
};

MobileNavbarLink.propTypes = {
  children: PropTypes.string,
  id: PropTypes.string,
  isNested: PropTypes.bool,
  to: PropTypes.string,
  currentPage: PropTypes.objectOf(PropTypes.object),
  subLabel: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
  backgroundImage: PropTypes.shape({
    name: PropTypes.string,
    src: PropTypes.string,
    imgProps: PropTypes.shape({
      height: PropTypes.string,
      width: PropTypes.string,
      fit: PropTypes.string,
      crop: PropTypes.string,
      fpX: PropTypes.string,
      fpY: PropTypes.string,
      fpZoom: PropTypes.string,
    }),
  }),
};

export default MobileNavbarLink;
