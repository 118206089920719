import React from 'react';
import PropTypes from 'prop-types';

import Cta from './components/Cta';

const CtaButton = React.forwardRef(
  (
    { children, styling = 'solid-button', type = 'submit', ...otherProps },
    ref
  ) => (
    <Cta {...otherProps} ref={ref} styling={styling} type={type}>
      {children}
    </Cta>
  )
);

CtaButton.propTypes = {
  children: PropTypes.node.isRequired,
  styling: PropTypes.string,
  type: PropTypes.string,
};

export default CtaButton;
