import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ProgressLoaderDots from 'components/essentials/ProgressLoaderDots/ProgressLoaderDots';

const CtaContent = ({
  children,
  className = '',
  color = 'black',
  hasArrow,
  hasBackArrow,
  hasUnderline = false,
  isHovered,
  isLoading = false,
  style = {},
  styling,
}) => (
  <span
    data-xc="Cta-content"
    className={classNames(`capitalize ${className}`, {
      // Button-style
      'flex items-center justify-center w-full': styling !== 'link',
      // Link-style
      inline: styling === 'link',
      // Rainbow button
      'relative z-[2]': styling === 'rainbow-button',
      // Link-style text colors
      'text-white hover:text-white group-hover/cta:text-white':
        styling === 'link' && color === 'white',
      'text-gray-dark': styling === 'link' && color === 'black' && !isHovered,
      'text-brand':
        (styling === 'link' && color === 'brand') ||
        (styling === 'link' && color === 'black' && isHovered),
      'hover:text-brand group-hover/cta:text-brand':
        styling === 'link' && color === 'black',
      'hover:text-gray-dark group-hover/cta:text-gray-dark':
        styling === 'link' && ['brand', 'brand-light'].includes(color),
      // Button-style text colors
      'hover:text-white group-hover/cta:text-white':
        styling === 'solid-button' &&
        ['black', 'brand', 'hotpink'].includes(color),
      // hasArrow
      "after:![content:'→'] after:absolute after:top-1/2 after:-translate-y-1/2 after:transition-[margin] after:duration-100 after:ease-in-out group-hover/cta:after:ml-2":
        styling === 'link' && hasArrow,
      'after:ml-[5px]': styling === 'link' && hasArrow && !isHovered,
      'after:ml-2': styling === 'link' && hasArrow && isHovered,
      // hasBackArrow
      "pl-5 before:![content:'←'] before:absolute before:top-1/2 before:-translate-y-1/2 before:transition-[margin] before:duration-100 before:ease-in-out group-hover/cta:before:-ml-6":
        styling === 'link' && hasBackArrow,
      'before:-ml-5': styling === 'link' && hasBackArrow && !isHovered,
      'before:-ml-6': styling === 'link' && hasBackArrow && isHovered,
      // hasUnderline
      'pb-[3px] border-b [border-bottom-style:solid]': hasUnderline,
    })}
    style={style}
  >
    <span
      className={classNames('inline-flex items-center', {
        invisible: isLoading,
      })}
    >
      {children}
    </span>

    {isLoading && (
      <div className="absolute inset-0 flex flex-row">
        <ProgressLoaderDots />
      </div>
    )}
  </span>
);

CtaContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['brand', 'black', 'white', 'brand-light', 'hotpink']),
  hasArrow: PropTypes.bool,
  hasBackArrow: PropTypes.bool,
  hasUnderline: PropTypes.bool,
  isHovered: PropTypes.bool,
  isLoading: PropTypes.bool,
  style: PropTypes.object,
  styling: PropTypes.oneOf([
    'link',
    'outline-button',
    'rainbow-button',
    'simple-img-button',
    'solid-button',
  ]),
};

export default CtaContent;
