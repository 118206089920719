import { useState, useEffect, useCallback } from 'react';

const DEFAULT_POSITION = {};

// This hook is used to get the user's current location (as latitude and
// longitude) using the browser's geolocation API. Requesting the location
// requires the user's permission, so this hook is disabled by default, and
// must be enabled by passing true as the first argument.
const useBrowserGeolocation = (enabled = false, options = {}) => {
  const {
    enableHighAccuracy = true,
    maximumAge = 60000, // 1 minute
    timeout = 5000, // 5 seconds
  } = options;

  const [position, setPosition] = useState(DEFAULT_POSITION);
  const [error, setError] = useState(null);

  const onChange = useCallback(({ coords, timestamp }) => {
    setPosition({
      accuracy: coords.accuracy,
      heading: coords.heading,
      latitude: coords.latitude,
      longitude: coords.longitude,
      speed: coords.speed,
      timestamp,
    });

    setError(null);
  }, []);

  const onError = useCallback(updatedError => {
    setError(updatedError?.message);
  }, []);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (!navigator || !navigator.geolocation) {
      setError('Geolocation is not supported');
      return;
    }

    navigator.geolocation.getCurrentPosition(onChange, onError, {
      enableHighAccuracy,
      timeout,
      maximumAge,
    });
  }, [enabled, enableHighAccuracy, maximumAge, onChange, onError, timeout]);

  return { ...position, error, isLoading: enabled && !position?.latitude };
};

export default useBrowserGeolocation;
