import React from 'react';
import PropTypes from 'prop-types';
import NotificationBannerSkeleton from './NotificationBannerSkeleton';

const UserOrdersProtectionPlanBanner = ({ planName, productName }) => (
  <NotificationBannerSkeleton
    title={` Congrats! Your Protection Plan has been confirmed.`}
    body={
      <>
        {planName} has been applied to your {productName}.
      </>
    }
  />
);

UserOrdersProtectionPlanBanner.propTypes = {
  planName: PropTypes.string,
  productName: PropTypes.string,
};

export default UserOrdersProtectionPlanBanner;
