import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VisuallyHidden from 'components/consumer/VisuallyHidden';
import ToolbarLinkOrButton from './ToolbarLinkOrButton';

const ToolbarIconButton = ({
  badgeColor = 'brand',
  className = '',
  count,
  'data-xc': dataXc,
  icon: Icon,
  iconHeight = 22,
  iconWidth = 24,
  onClick,
  to,
  title,
}) => (
  <ToolbarLinkOrButton
    className={`items-center bg-transparent rounded-full border-2 border-solid border-transparent text-gray flex h-[38px] justify-center relative w-[38px] hover:text-brand focus:text-brand ${className}`}
    data-xc={dataXc}
    onClick={onClick}
    to={to}
  >
    <VisuallyHidden>{title}</VisuallyHidden>

    {!!Icon && (
      <Icon
        title={title}
        color="currentColor"
        width={iconWidth}
        height={iconHeight}
      />
    )}

    {!!count && (
      <div
        className={classNames(
          'text-xs rounded-sm bottom-[5px] text-white inline-block font-bold h-[15px] absolute right-1 text-center w-[15px]',
          {
            'bg-brand': badgeColor === 'brand',
            'bg-red': badgeColor === 'red',
          }
        )}
        aria-hidden
      >
        {count}
      </div>
    )}
  </ToolbarLinkOrButton>
);

ToolbarIconButton.propTypes = {
  badgeColor: PropTypes.oneOf(['brand', 'red']),
  className: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  'data-xc': PropTypes.string,
  icon: PropTypes.elementType,
  iconHeight: PropTypes.number,
  iconWidth: PropTypes.number,
  onClick: PropTypes.func,
  to: PropTypes.string,
  title: PropTypes.string,
};

export default ToolbarIconButton;
