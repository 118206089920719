import { useSetAtom } from 'jotai';

import isZipCodeFromUserServiceableAtom from './isZipCodeFromUserServiceableAtom';

const useSetIsZipCodeFromUserServiceable = () => {
  const setIsZipCodeFromUserServiceable = useSetAtom(
    isZipCodeFromUserServiceableAtom
  );

  return setIsZipCodeFromUserServiceable;
};

export default useSetIsZipCodeFromUserServiceable;
